<template>
    <div class="answer-response-view correct-answer">
        <h3>{{ 'CORRECT_ANSWER_TITLE' | translate }}</h3>

        <div class="bg-img img-responsive"></div>

        <p>{{ 'CORRECT_ANSWER_DESCRIPTION' | translate }}</p>

        <p v-if="lastAnswerData.points > 0" class="points">
            +{{ $t("POINTS_GAINED", { points : lastAnswerData.points }) }}
        </p>

        <button @click="next" class="btn secondary-btn">
            {{ 'ANOTHER_QUESTION_BUTTON' | translate }}
        </button>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "lastAnswerData" ]),

        methods : {
            next() { this.$emit("next", { action : "nextItem" }); }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>