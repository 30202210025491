var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-answer choice-answer" }, [
    _c(
      "div",
      { staticClass: "form-group" },
      _vm._l(_vm.choices, function (choice, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "test-checkbox",
            class: { checked: _vm.isChecked(choice.id) },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.selectChoice(choice.id)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-xs-3 col-sm-2 col-md-1 checkbox-col" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.answer,
                        expression: "answer",
                      },
                    ],
                    staticClass: "regular-checkbox big-checkbox",
                    attrs: { type: "checkbox", id: "checkbox-" + choice.id },
                    domProps: {
                      value: choice.id,
                      checked: Array.isArray(_vm.answer)
                        ? _vm._i(_vm.answer, choice.id) > -1
                        : _vm.answer,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.answer,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = choice.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.answer = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.answer = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.answer = $$c
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "checkbox-" + choice.id } }, [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isChecked(choice.id),
                          expression: "isChecked(choice.id)",
                        },
                      ],
                      staticClass: "fa fa-check",
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xs-9 col-sm-10 col-md-11 tag-col" },
                [
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(_vm._s(choice.text)),
                  ]),
                ]
              ),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-success center-block",
        attrs: { disabled: _vm.answer.length === 0 },
        on: {
          click: function ($event) {
            return _vm.$emit("answer", _vm.answer)
          },
        },
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ANSWER")) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }