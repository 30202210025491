const apiDefinition = {
    "codes" : {
        "ok"                    : 200,
        "created"               : 201,
        "noContent"             : 204,
        "partiallyOk"           : 206,
        "badRequest"            : 400,
        "unauthorized"          : 401,
        "forbidden"             : 403,
        "notFound"              : 404,
        "conflict"              : 409,
        "gone"                  : 410,
        "preconditionFailed"    : 412,
        "unprocessableEntity"   : 422,
        "failedDependency"      : 424,
        "internalServerError"   : 500
    },
    "login"                     : {
        "base"                  : "login/",
        "token"                 : "token/"
    },
    "logout"                    : "logout/",
    "register"                  : "register/",
    "activate"                  : "activate/",
    "forgotten"                 : "forgotten/",
    "resetPassword"             : "reset-password/",

    "categories" : {
        "base"                  : "categories/",
        "focus"                 : "{id}/focus",
    },

    "items"  : {
        "base"                  : "items/",
        "postpone"              : "{id}/postpone",
        "answer"                : "{id}/answer",
        "draw"                  : "draw",
    },

    "areas"                     : "areas/",
    "user"                      : {
        "base"                  : "user/",
        "preferredArea"         : "preferred-area"
    },

    "achievements"              : "achievements/",
    "teamUsersLeaderboard"      : "leaderboard/",
    "areaTeamsLeaderboard"      : "area-leaderboard/",
    "allUsersLeaderboard"       : "users-leaderboard/",
    "areaBattlesLeaderboard"    : "area-battles-leaderboard/",
    "teamBattlesLeaderboard"    : "team-battles-leaderboard/",
    "notifications"             : "notifications/",
    // "categories"                : "areas/{id}/categories",
    "battles"                   :  {
        "base"                  : "battles/",
        "opponents"             : "opponents",
        "accept"                : "{id}/accept",
        "decline"               : "{id}/decline",
        "answer"                : "{id}/answer"
    },
    "tests"                   :  {
        "base"                  : "tests/",
        "startAttempt"          : "start-attempt/{testId}/{id}",
        "finishAttempt"         : "attempt/{id}/finish",
        "attempt"               : "attempt/{id}",
        "syncTime"              : "attempt/{id}/sync",
        "answer"                : "answer",
        "videoWatched"          : "attempt/{id}/video-watched"
    },
};

export default apiDefinition;
