var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "focus" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("user-progress", {
            attrs: {
              stats: _vm.currentProgress,
              showProgressbar: "",
              progressbarValue: _vm.currentAreaLevelProgress,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v(_vm._s(_vm.currentAreaDetails.focus_main_title))]),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.currentAreaDetails.focus_main_subtitle))]),
        _vm._v(" "),
        _c("h3", [_vm._v(_vm._s(_vm.currentAreaDetails.focus_main_text))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row categories-row" },
          [
            _vm.knowledgeTestsEnabled ? _c("knowledge-test-list") : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.currentCategories, function (category) {
              return _c(
                "div",
                { key: category.id, staticClass: "col-sm-6 col-md-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "category-box",
                      class: { "focused-category": _vm.isFocused(category.id) },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { "data-key": "sameHeightCategories" } },
                        [
                          _c("img", {
                            staticClass: "img img-responsive",
                            attrs: {
                              src: category.image_path,
                              alt: _vm._f("translate")("ALT_IMAGE_CATEGORY"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-wrapper" }, [
                            _c(
                              "h3",
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(category.name) +
                                    "\n                                    "
                                ),
                                category.highlighted
                                  ? [
                                      category.highlight_tag
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "label label-important",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(category.highlight_tag)
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "label label-important",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("translate")(
                                                    "FOCUS_HIGHLIGHTED_CATEGORY"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "category-description" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(category.description) +
                                  "\n                                "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn secondary-btn",
                          attrs: { disabled: _vm.isFocused(category.id) },
                          on: {
                            click: function ($event) {
                              return _vm.focus(category.id)
                            },
                          },
                        },
                        [
                          _vm.isFocused(category.id)
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")("FOCUS_CHOSEN_CATEGORY")
                                  )
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")("FOCUS_CHOOSE_CATEGORY")
                                  )
                                ),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _vm.focusNoneTileVisible
              ? _c("div", { staticClass: "col-sm-6 col-md-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "category-box no-focus",
                      class: { "focused-category": _vm.isFocused(0) },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { "data-key": "sameHeightCategories" } },
                        [
                          _c("div", {
                            staticClass: "bg-img responsive-bg-img",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-wrapper" }, [
                            _c("h3", [
                              _vm._v(
                                _vm._s(_vm._f("translate")("FOCUS_NONE_TITLE"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "category-description" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.currentAreaDetails.focus_none_text
                                  ) +
                                  "\n                                "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn secondary-btn",
                          attrs: { disabled: _vm.isFocused(0) },
                          on: {
                            click: function ($event) {
                              return _vm.focus(0)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("translate")("FOCUS_CHOOSE_CATEGORY")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }