import has from "lodash/has";
import isString from "lodash/isString";

import store from "src/store/store";
import router from "src/router/router";
import { apiResource } from "./apiService";
import apiDefinition from "src/config/apiDefinition";
import { sendToSentry } from "src/services/sentryWrapper";
import log from "src/services/logger";

const logTag = "httpInterceptors";

// this will keep error codes for which the polling error was already sent to Sentry; to prevent spam
// e.g. [401, 503, 0] - where 0 occurs in weird cases where no status code is present in response
let pollingErrorsSent = {
    "notifications" : [],
    "sync"          : [],
};

// this is for filtering events before they are sent to Sentry
const sendErrorToSentry = (title, url, status, message) => {
    // get only the end part of URL, like "scenario" etc
    const urlApiPart = extractApiUrl(url);

    // 401 http errors are common - it just means the user is not logged in
    if (status === 401) {
        return;

    // in case of periodic notifications or KT time sync polling, we send only the first error and ignore
    // the others, otherwise Sentry would get cluttered
    } else if (urlApiPart.indexOf("notifications") !== -1) {
        const statusCode = status || 0;
        if (pollingErrorsSent["notifications"].indexOf(statusCode) !== -1) {
            return;
        }
        // remember this status code so that we limit the errors sent to Sentry to 1
        pollingErrorsSent["notifications"].push(statusCode);

    } else if (urlApiPart.indexOf("sync") !== -1) {
        const statusCode = status || 0;
        if (pollingErrorsSent["sync"].indexOf(statusCode) !== -1) {
            return;
        }
        // remember this status code so that we limit the errors sent to Sentry to 1
        pollingErrorsSent["sync"].push(statusCode);
    }

    sendToSentry(`[${logTag}] ${title}: /${urlApiPart}`, "response_data", { url, status, message });
};

function extractApiUrl(fullUrl) {
    let apiUrl = fullUrl.substring(fullUrl.indexOf("/api/") + 5);
    return apiUrl || fullUrl;
}

function extractErrorMessage(response) {
    if (has(response, "data.error") && isString(response.data.error)) {
        return response.data.error;
    } else if (has(response, "data.message") && isString(response.data.message)) {
        return response.data.message;
    }

    return "";
}

// Request interceptor
// Use custom "doNotToggleLoader" config option to disable showing of loader on request
apiResource.interceptors.request.use((config) => {
    if (!config.doNotToggleLoader) {
        store.dispatch("toggleLoader", true);
    }
    return config;
}, (error) => {
    store.dispatch("toggleLoader", false);
    console.log("RequestError: ", error);
    // Do something with request error
    return Promise.reject(error);
});

// Response interceptor
apiResource.interceptors.response.use(
    (response) => {
        log.log(logTag, response.config.url + " response received:", true);
        log.log(logTag, response, true);

        if (!response.config.doNotToggleLoader) {
            store.dispatch("toggleLoader", false);
        }

        // @note: can this happen?
        if (!has(response, "status")) {
            log.log(logTag, "Status code missing");
            return Promise.reject({ status : 500 });
        }

        if (response.status === apiDefinition.codes.ok
            || response.status === apiDefinition.codes.created
            || response.status === apiDefinition.codes.noContent
            || response.status === apiDefinition.codes.partiallyOk
        ) {
            return response;
        } else {
            log.log(logTag, "Status code invalid: " + response.status);
            sendErrorToSentry(
                `Invalid response status code (${response.status})`,
                response.config.url,
                response.status,
                extractErrorMessage(response)
            );
            return Promise.reject(response);
        }
    },

    (error) => {
        log.log(logTag, error.config.url + " error caught, response follows: ");
        log.log(logTag, error);
        log.log(logTag, error.response || "(no response)");

        // if we received 401 unauthorized, logout the user and redirect to login page
        if (error.response && error.response.status === apiDefinition.codes.unauthorized) {
            store.dispatch("doLocalLogout").then(() => router.push("/login").catch(()=>{}));
        }

        // report to Sentry
        if (error.response) {
            sendErrorToSentry(
                `Response ${error.response.status}`,
                error.config.url,
                error.response.status,
                extractErrorMessage(error.response)
            );
        } else {
            sendErrorToSentry(`Error without response - network error? 500?`, error.config.url);
        }

        store.dispatch("toggleLoader", false);
        return Promise.reject(error.response || { status : 500 });
    }
);
