<template>
    <div id="knowledge-test" class="page">
        <div class="part-1">
            <div class="container">
                <h2>{{ currentTest.title || $t('KNOWLEDGE_TEST_MAIN_TITLE') }}</h2>

                <div v-show="loadingFinished">
                    <template v-if="!showResults">
                        <template v-if="showVideoPage">
                            <test-attachment v-if="isTestAttachmentActive"
                                             @go-to-items="goToItems"
                            ></test-attachment>
                            <test-video v-else
                                        @go-to-items="goToItems"
                            ></test-video>
                        </template>

                        <test-items v-else
                                    @go-to-video="goToVideo"
                                    @finish-attempt="finishTestAttempt"
                                    @timeout="showTimeout"
                        ></test-items>
                    </template>

                    <test-result v-else
                                 :test-id="testId"
                                 @repeat-attempt="repeatTestAttempt"
                    ></test-result>
                </div>
                <div v-show="!loadingFinished" class="test-loading">
                    {{ "KNOWLEDGE_TEST_LOADING" | translate }}
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import get from "lodash/get";
    import { mapGetters } from "vuex";

    import TestItems from "./TestItems";
    import TestResult from "./TestResult";
    import TestVideo from "./TestVideo";
    import TestAttachment from "./TestAttachment";
    import notificationService from "src/services/notificationService";
    import testTimeSyncService from "src/services/testTimeSyncService";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "KnowledgeTest";

    export default {
        name : "KnowledgeTest",

        components : { TestItems, TestResult, TestVideo, TestAttachment },

        created : function() {
            this.initTestAttempt();
        },

        computed : mapGetters([
            "currentTest", "currentTestAttemptId", "testTimeIsUp", "currentAreaCssClass",
            "isAnyAttemptActive", "isTestVideoActive", "isTestAttachmentActive"
        ]),

        watch : {
            // this will show the timeout modal if the time runs out
            testTimeIsUp(newValue) {
                if (newValue === true) {
                    this.showTimeout();
                }
            }
        },

        data() {
            return {
                showResults     : false,
                showVideoPage   : false,
                testId          : 0,
                loadingFinished : false
            };
        },

        methods : {
            initTestAttempt() {
                this.showResults = false;

                // download knowledge test info. This is necessary to accommodate for refresh and direct URL access
                return this.$store.dispatch("downloadKnowledgeTests")
                .catch(reason => {
                    log.log(logTag, "Download test error, reason follows:");
                    log.log(logTag, reason);
                    this.$router.push("/focus");
                    return Promise.reject(reason);
                })

                // continue an attempt. Note: the attempt cannot be started from here!
                .then(() => {
                    // we have to think about the use case when the user refreshes this page or goes here by
                    // URL - if there is no active test or attempt, DO NOT start it here, redirect to Focus page.
                    // An attempt can only be started from elsewhere! (Focus, TestResult)
                    if (!this.isAnyAttemptActive) {
                        log.log(logTag, `Redirecting. No attempt is active!`);
                        this.$router.push("/focus");
                        return Promise.reject();
                    }

                    // continue attempt?
                    // @todo: why is there no attempt id here? is this continue or start
                    return this.$store.dispatch("startTestAttempt", this.currentTest.id)
                    .catch(reason => {
                        log.log(logTag, "Start test attempt - error, reason follows:");
                        log.log(logTag, reason);
                        this.$store.dispatch("showErrorMessage", userMessages.afterStartTestAttempt(reason));
                        // @note new test data will be redownloaded in /focus
                        this.$router.push("/focus");
                        return Promise.reject(reason);
                    });
                })

                // download info about this attempt - items, user answers, remaining time
                .then(() => {
                    return this.$store.dispatch("downloadTestAttempt", this.currentTestAttemptId)

                    // start time sync and update
                    .then((response) => {
                        testTimeSyncService.start(get(response, "time_remaining", null));
                    })

                    .catch(reason => {
                        log.log(logTag, "Download test attempt - error, reason follows:");
                        log.log(logTag, reason);
                        this.$store.dispatch("showErrorMessage", userMessages.afterDownloadTestAttempt(reason));
                        this.$router.push("/focus");
                        return Promise.reject(reason);
                    });
                })

                // remember ID of current test as it will be lost after test is finished
                .then(() => {
                    this.testId = this.currentTest.id;
                })

                // show video page if video is available
                .then(() => {
                    this.showVideoPage = this.isTestVideoActive;
                    this.loadingFinished = true;
                })

                // just so that JS doesn't throw error about uncaught reject
                .catch(() => {});
            },

            finishTestAttempt() {
                // user's points have changed
                this.$store.dispatch("downloadUserData");

                // we have to remember the current attemptId as after the test is redownloaded,
                // the current attempt ID is lost
                const attemptId = this.currentTestAttemptId;

                // redownload all the test data, previous attempts (also containing the current attempt)
                this.$store.dispatch("downloadKnowledgeTests")

                .catch(reason => {
                    log.log(logTag, "Download test error, reason follows:");
                    log.log(logTag, reason);
                    this.$store.dispatch("showErrorMessage", this.$t("KNOWLEDGE_TEST_FINISH_ERROR"));
                    this.$router.push("/focus");
                })

                // download the info about the attempt - items, answers, correct answers, points...
                .then(() => {
                    return this.$store.dispatch("downloadTestAttempt", attemptId);
                })

                // show the TestResult page
                .then(() => {
                    this.showResults = true;
                })

                .catch(reason => {
                    this.$store.dispatch("showErrorMessage", userMessages.afterDownloadTestAttempt(reason));
                });
            },

            repeatTestAttempt() {
                log.log(logTag, "Repeating test attempt!");
                this.initTestAttempt();
            },

            showTimeout() {
                log.log(logTag, "The time is up!");
                notificationService.knowledgeTestTimeout(
                    this.currentTest.title, this.currentAreaCssClass, () => {
                        // @note that it doesn't matter if user clicked on modal button or closed the modal
                        this.finishTestAttempt();
                    }
                );
            },

            goToItems() {
                this.showVideoPage = false;
            },

            goToVideo() {
                this.showVideoPage = true;
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>