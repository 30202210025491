<template>
    <div id="onboarding" class="page">
        <div class="part-1">
            <div class="container">
                <div class="row main-title-row">
                    <div class="bg-img img-responsive"></div>

                    <div class="col-sm-5 col-sm-offset-7 main-title-col">
                        <h1>{{ currentAreaDetails.onboarding_main_title }}</h1>
                        <p class="main-subtitle">
                            {{ currentAreaDetails.onboarding_main_subtitle }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-2">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-lg-7">
                        <div class="flavour-text-wrapper">
                            <p class="flavour-text">
                                {{ currentAreaDetails.onboarding_flavour_text }}
                            </p>
                            <p class="flavour-text-tagline">
                                {{ currentAreaDetails.onboarding_flavour_text_tagline }}
                            </p>
                            <div class="bg-img img-responsive"></div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-5 flavour-img-wrapper">
                        <div class="bg-img img-responsive"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-3">
            <div class="container">
                <h1>{{ currentAreaDetails.onboarding_instructions_title }}</h1>

                <div class="row instruction first-instruction">
                    <div class="col-sm-6 image-col">
                        <div class="bg-img img-responsive"></div>
                    </div>

                    <div class="col-sm-6 instruction-col">
                        <div class="instruction-wrapper">
                            <div class="row">
                                <div class="col-xs-3 col-md-2 number">1</div>
                                <div class="col-xs-9 col-md-10 instruction-text">
                                    <h3>{{ currentAreaDetails.onboarding_instructions_first_title }}</h3>
                                    <p>{{ currentAreaDetails.onboarding_instructions_first_text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row instruction second-instruction">
                    <div class="col-sm-6 image-col">
                        <div class="bg-img img-responsive"></div>
                    </div>

                    <div class="col-sm-6 instruction-col">
                        <div class="instruction-wrapper">
                            <div class="row">
                                <div class="col-xs-3 col-md-2 number">2</div>
                                <div class="col-xs-9 col-md-10 instruction-text">
                                    <h3>{{ currentAreaDetails.onboarding_instructions_second_title }}</h3>
                                    <p>{{ currentAreaDetails.onboarding_instructions_second_text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row instruction third-instruction">
                    <div class="col-sm-6 image-col">
                        <div class="bg-img img-responsive"></div>
                    </div>

                    <div class="col-sm-6 instruction-col">
                        <div class="instruction-wrapper">
                            <div class="row">
                                <div class="col-xs-3 col-md-2 number">3</div>
                                <div class="col-xs-9 col-md-10 instruction-text">
                                    <h3>{{ currentAreaDetails.onboarding_instructions_third_title }}</h3>
                                    <p>{{ currentAreaDetails.onboarding_instructions_third_text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-4">
            <div class="container">
                <h2>{{ currentAreaDetails.onboarding_explanation_section_title }}</h2>

                <div class="row">
                    <div class="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                        <h3>{{ currentAreaDetails.onboarding_explanation_first_title }}</h3>
                        <p>{{ currentAreaDetails.onboarding_explanation_first_text }}</p>

                        <h3>{{ currentAreaDetails.onboarding_explanation_second_title }}</h3>
                        <p>{{ currentAreaDetails.onboarding_explanation_second_text }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-5">
            <div class="container">
                <div class="cta-img bg-img img-responsive">
                    <div class="cta-btn-wrapper">
                        <a href="/#/focus" class="btn primary-btn cta-btn">
                            {{ currentAreaDetails.onboarding_ctoa_button_title }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "currentAreaDetails" ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
