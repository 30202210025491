var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-answer" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._l(_vm.item.options.choices, function (choice, order) {
              return _c(
                "div",
                {
                  staticClass: "custom-checkbox",
                  class: { checked: _vm.isChecked(choice.id) },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-3 col-sm-2 checkbox-col" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.answer,
                              expression: "answer",
                            },
                          ],
                          staticClass: "regular-checkbox big-checkbox",
                          attrs: {
                            type: "checkbox",
                            id: "checkbox-" + choice.id,
                          },
                          domProps: {
                            value: choice.id,
                            checked: Array.isArray(_vm.answer)
                              ? _vm._i(_vm.answer, choice.id) > -1
                              : _vm.answer,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.answer,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = choice.id,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.answer = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.answer = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.answer = $$c
                                }
                              },
                              _vm.checkboxChanged,
                            ],
                          },
                        }),
                        _c(
                          "label",
                          { attrs: { for: "checkbox-" + choice.id } },
                          [_vm._v(_vm._s(_vm.orderToChar(order)))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xs-9 col-sm-10 tag-col" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tag",
                          on: { click: function ($event) {} },
                        },
                        [_vm._v(_vm._s(choice.text))]
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isErrorMessageShown,
                    expression: "isErrorMessageShown",
                  },
                ],
                staticClass: "validation-message has-error",
              },
              [_vm._v(_vm._s(_vm.errorMessage))]
            ),
          ],
          2
        ),
        _vm._v(" "),
        _vm._t("buttons"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }