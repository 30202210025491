<template>
    <div id="battle-waiting-for-finish">
        <div class="bg-img img-responsive"></div>
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <h2>{{ currentAreaDetails.battles_waiting_for_finish_title }}</h2>
                <p>{{ currentAreaDetails.battles_waiting_for_finish_text }}</p>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "currentAreaDetails" ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>