<template>
    <div id="test-pagination">
            <div class="page-item tooltip-container transport-left" key="left">
                <a class="page-link" aria-label="Předchozí otázka"
                   @click="() => selectItem(currentItemIndex - 1)"
                >
                    <span aria-hidden="true"><</span>
                </a>
                <div class="tooltip-content disable-select">
                    {{ 'KNOWLEDGE_TEST_PREV_BTN' | translate }}
                </div>
            </div>

            <div class="pagination-center">
                <div v-for="(item, itemIndex) in items" :key="item.id"
                    class="page-item tooltip-container"
                    :class="{ 'active' : itemIndex === currentItemIndex,
                              'answered' : answeredItems.indexOf(itemIndex.toString()) !== -1 }"
                >
                    <a class="page-link disable-select" @click="() => selectItem(itemIndex)">
                        {{ itemIndex + 1 }}
                    </a>
                    <div class="tooltip-content disable-select">
                        {{ item.name }}
                    </div>
                </div>
            </div>

            <div class="page-item tooltip-container transport-right" key="right">
                <a class="page-link" aria-label="Následující otázka"
                   @click="() => selectItem(currentItemIndex + 1)"
                >
                    <span aria-hidden="true">></span>
                </a>
                <div class="tooltip-content disable-select">
                    {{ 'KNOWLEDGE_TEST_NEXT_BTN' | translate }}
                </div>
            </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
// import log from 'src/services/logger';
const logTag = "TestPagination";

export default {
    name : logTag,

    props : {
        items : { type : Array, default : () => [] },
        currentItemIndex : { type : Number, default : 0 },
        answeredItems : { type : Array, default : () => [] }
    },

    methods : {
        selectItem(itemIndex) {
            // clamp the values. The max possible itemIndex is items.length (one more than max index would
            // normally have) - this represents the submit page. I.e. with 5 items, item index 5 = submit page
            itemIndex = itemIndex < 0 ? 0 : itemIndex;
            itemIndex = itemIndex > this.items.length ? this.items.length : itemIndex;

            if (itemIndex === this.currentItemIndex) {
                return;
            }

            this.$emit("on-item-select", itemIndex);
        }
    }
};
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>