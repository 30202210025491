var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "answer-response-view incorrect-answer" },
    [
      _c("h3", [_vm._v(_vm._s(_vm._f("translate")("INCORRECT_ANSWER_TITLE")))]),
      _vm._v(" "),
      _c("div", { staticClass: "bg-img img-responsive" }),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm._f("translate")("INCORRECT_ANSWER_DESCRIPTION"))),
      ]),
      _vm._v(" "),
      _vm.lastAnswerData.points < 0
        ? _c("p", { staticClass: "points" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("POINTS_GAINED", { points: _vm.lastAnswerData.points })
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "answer-title" }, [
        _vm._v(_vm._s(_vm._f("translate")("CORRECT_ANSWER_TITLE")) + ":"),
      ]),
      _vm._v(" "),
      _vm.currentItem.type === "choice"
        ? _vm._l(_vm.currentItem.options.choices, function (choice, order) {
            return _vm.lastAnswerData.correct_answer.indexOf(choice.id) !== -1
              ? _c("div", { staticClass: "custom-checkbox checked" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-3 col-sm-2 checkbox-col" },
                      [
                        _c("input", {
                          staticClass: "regular-checkbox big-checkbox",
                          attrs: {
                            type: "checkbox",
                            id: "checkbox-" + choice.id,
                            disabled: "",
                          },
                          domProps: { value: choice.id },
                        }),
                        _c(
                          "label",
                          { attrs: { for: "checkbox-" + choice.id } },
                          [_vm._v(_vm._s(_vm.orderToChar(order)))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xs-9 col-sm-10 tag-col" }, [
                      _c("div", { staticClass: "tag" }, [
                        _vm._v(_vm._s(choice.text)),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e()
          })
        : [_c("p", [_vm._v(_vm._s(_vm.lastAnswerData.correct_answer))])],
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn secondary-btn", on: { click: _vm.next } },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("translate")("ANOTHER_QUESTION_BUTTON")) +
              "\n    "
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }