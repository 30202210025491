<template>
    <div class="panel panel-default">
        <div class="panel-heading">{{ 'CHANGE_PASS_TITLE' | translate }}</div>
        <div class="panel-body">
            <form novalidate @submit.prevent="sendRequest">
                <div class="row">
                    <div class="col-xs-12 form-group"
                         :class="{'has-error' : $v.old_password.$error}"
                    >
                        <label for="old_password" class="input-label">{{ 'OLD_PASSWORD' | translate }}</label>
                        <vue-password name="old_password" id="old_password"
                                      v-model="old_password" @input="$v.old_password.$touch()"
                                      classes="form-control"
                                      disableStrength
                        ></vue-password>
                        <validation-messages :field="$v.old_password" field-name="old_password"></validation-messages>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 form-group"
                         :class="{'has-error' : $v.new_password.$error}"
                    >
                        <label for="new_password" class="input-label">{{ 'NEW_PASSWORD' | translate }}</label>
                        <vue-password name="new_password" id="new_password"
                                      v-model="new_password" @input="$v.new_password.$touch()"
                                      classes="form-control"
                                      disableStrength
                        ></vue-password>
                        <validation-messages :field="$v.new_password" field-name="new_password"></validation-messages>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 submit-btn-wrapper">
                        <button class="btn brand-secondary-btn" type="submit">
                            {{ "CHANGE_PASS_SUBMIT" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from 'vuex';
    import VuePassword from 'vue-password/dist/custom';
    import { required, minLength } from "vuelidate/lib/validators";

    import ValidationMessages from "Components/common/ValidationMessages";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "ChangePassword";

    export default {
        components : { VuePassword, ValidationMessages },

        data() {
            return {
                old_password    : "",
                new_password    : ""
            };
        },

        validations : {
            old_password : { required },
            new_password : { required, minLength : minLength(6) }
        },

        computed : mapGetters([ 'user' ]),

        methods : {
            sendRequest() {
                log.log(logTag, "Change pass submit button pressed");
                this.$v.$touch();

                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, sending change pass request");

                    this.$store.dispatch("sendChangePassRequest", {
                        old_password : this.old_password,
                        new_password : this.new_password
                    })
                    .then((response) => {
                        this.successMessage = userMessages.afterChangePassword(response);
                        this.$store.dispatch("showSuccessMessage", this.successMessage);
                    })
                    // forgotten pass request unsuccessful, show the error
                    .catch(reason => {
                        this.errorMessage = userMessages.afterChangePassword(reason);
                        this.$store.dispatch("showErrorMessage", this.errorMessage);
                        // @todo: logout and redirect if it fails?
                    });
                }
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>