<template>
    <div class="item-answer choice-answer">
        <div class="form-group">
            <div v-for="(choice, index) in choices"
                 :key="index"
                 :class="{ 'checked' : isChecked(choice.id) }"
                 class="test-checkbox"
                 @click.prevent="selectChoice(choice.id)"
            >
                <div class="row">
                    <div class="col-xs-3 col-sm-2 col-md-1 checkbox-col">
                        <input type="checkbox" :id="'checkbox-' + choice.id"
                               :value="choice.id" v-model="answer"
                               class="regular-checkbox big-checkbox"
                        /><label :for="'checkbox-' + choice.id">
                            <i class="fa fa-check" v-show="isChecked(choice.id)"></i>
                        </label>
                    </div>
                    <div class="col-xs-9 col-sm-10 col-md-11 tag-col">
                        <div class="tag" >{{ choice.text }}</div>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn btn-success center-block"
                @click="$emit('answer', answer)"
                :disabled="answer.length === 0"
        >
            {{ 'KNOWLEDGE_TEST_ANSWER' | translate }}
        </button>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import get from "lodash/get";
    import clone from "lodash/clone";
    // import log from "src/services/logger";
    // const logTag = "TestChoiceAnswer";

    export default {
        name : "TestChoiceAnswer",

        props : {
            item : { type : Object, default : null },
            currentAnswer : { type : Array, default : () => [] },
        },

        // we need this to set the initial pre-filled value for this answer (if the user already answered)
        mounted : function() {
            this.answer = clone(this.currentAnswer);
        },

        watch : {
            // we need this to update the pre-filled value for this specific question. For example, when
            // there are two consecutive answers of type Text, the component is not mounted again, only
            // re-used.
            // @note 6/24: do not watch for currentAnswer, as when two answers have the same currentAnswer, 
            // the watcher won't be triggered
            item() {
                this.answer = clone(this.currentAnswer);
            }
        },

        data() {
            return {
                // array of choice IDs
                answer          : [],
            };
        },

        computed : {
            // this is needed to shut Vue up about render errors
            choices() { return get(this.item, "options.choices", []); },
        },

        methods : {
            selectChoice(choiceId) {
                const choiceIdIndex = this.answer.indexOf(choiceId);
                if (choiceIdIndex === -1) {
                    this.answer.push(choiceId);
                } else {
                    this.answer.splice(choiceIdIndex, 1);
                }
            },

            isChecked(id) {
                return this.answer.indexOf(id) !== -1;
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>