var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "test-pagination" } }, [
    _c(
      "div",
      {
        key: "left",
        staticClass: "page-item tooltip-container transport-left",
      },
      [
        _c(
          "a",
          {
            staticClass: "page-link",
            attrs: { "aria-label": "Předchozí otázka" },
            on: { click: () => _vm.selectItem(_vm.currentItemIndex - 1) },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("<")])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tooltip-content disable-select" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_PREV_BTN")) +
              "\n            "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pagination-center" },
      _vm._l(_vm.items, function (item, itemIndex) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "page-item tooltip-container",
            class: {
              active: itemIndex === _vm.currentItemIndex,
              answered: _vm.answeredItems.indexOf(itemIndex.toString()) !== -1,
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link disable-select",
                on: { click: () => _vm.selectItem(itemIndex) },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(itemIndex + 1) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tooltip-content disable-select" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(item.name) +
                  "\n                "
              ),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        key: "right",
        staticClass: "page-item tooltip-container transport-right",
      },
      [
        _c(
          "a",
          {
            staticClass: "page-link",
            attrs: { "aria-label": "Následující otázka" },
            on: { click: () => _vm.selectItem(_vm.currentItemIndex + 1) },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(">")])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tooltip-content disable-select" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_NEXT_BTN")) +
              "\n            "
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }