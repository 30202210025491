var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "knowledge-test" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", [
          _vm._v(
            _vm._s(_vm.currentTest.title || _vm.$t("KNOWLEDGE_TEST_MAIN_TITLE"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingFinished,
                expression: "loadingFinished",
              },
            ],
          },
          [
            !_vm.showResults
              ? [
                  _vm.showVideoPage
                    ? [
                        _vm.isTestAttachmentActive
                          ? _c("test-attachment", {
                              on: { "go-to-items": _vm.goToItems },
                            })
                          : _c("test-video", {
                              on: { "go-to-items": _vm.goToItems },
                            }),
                      ]
                    : _c("test-items", {
                        on: {
                          "go-to-video": _vm.goToVideo,
                          "finish-attempt": _vm.finishTestAttempt,
                          timeout: _vm.showTimeout,
                        },
                      }),
                ]
              : _c("test-result", {
                  attrs: { "test-id": _vm.testId },
                  on: { "repeat-attempt": _vm.repeatTestAttempt },
                }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loadingFinished,
                expression: "!loadingFinished",
              },
            ],
            staticClass: "test-loading",
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_LOADING")) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }