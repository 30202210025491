<template>
    <div id="no-battle">
        <div class="bg-img img-responsive"></div>
        <h2>{{ 'BATTLES_NO_BATTLE_TITLE_1' | translate }}</h2>
        <h2>{{ 'BATTLES_NO_BATTLE_TITLE_2' | translate }}</h2>
        <p>{{ 'BATTLES_NO_BATTLE_TEXT' | translate }}</p>

        <div id="search-wrapper">
            <autocomplete
                    :url="apiBase + apiEndpoint + apiDefinition.battles.base + apiDefinition.battles.opponents"
                    param="q"
                    :customHeaders="customHeaders"
                    anchor="name"
                    label="id"
                    :min="1"
                    :on-select="selectOpponent"
                    :onInput="onAutocompleteInput"
                    :placeholder="'BATTLES_OPPONENT_SEARCH_PH' | translate"
            ></autocomplete>

            <button @click="sendBattleRequest()" class="btn primary-btn cta-btn"
                    :disabled="!selectedOpponent"
            >
               {{ currentAreaDetails.battles_ctoa_button_title }}
            </button>
            <p v-if="opponentIsInAnotherBattle">{{ 'BATTLES_OPPONENT_IS_BUSY' | translate}}</p>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import Autocomplete from 'vue2-autocomplete-js';

    import apiDefinition from "src/config/apiDefinition";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "NoBattle";

    export default {
        components : { Autocomplete },

        created : function() {},

        data() {
            return {
                // just forwarding so that Autocomplete can use it
                apiBase                     : config.api.base,
                apiEndpoint                 : config.api.endpoint,
                apiDefinition,

                selectedOpponent            : null,
                opponentIsInAnotherBattle   : false,
            };
        },

        computed : {
            ...mapGetters([ "currentAreaDetails", "authToken", "currentAreaId" ]),
            customHeaders() {
                let headers = {
                    "Authorization" : 'Bearer ' + this.authToken,
                    "x-ifo-area"    : this.currentAreaId
                };
                return headers;
            },
        },

        methods : {
            sendBattleRequest() {
                log.log(logTag, "Sending battle request");

                this.$store.dispatch("sendBattleChallenge", this.selectedOpponent.id)
                .catch(reason => {
                    log.log(logTag, "Sending battle request failed! Reason follows");
                    log.log(logTag, reason);

                    // 409 - show that the opponent is already in some battle
                    if (reason.status === apiDefinition.codes.conflict) {
                        this.selectedOpponent = null;
                        this.opponentIsInAnotherBattle = true;

                    // OR show that an error occured
                    } else {
                        this.$store.dispatch("showErrorMessage", userMessages.afterBattleChallengeSent(reason));
                    }
                });
            },

            // called by Autocomplete's event
            selectOpponent(opponent){
                this.selectedOpponent = opponent;
                this.opponentIsInAnotherBattle = false;
            },

            // called by Autocomplete's event
            onAutocompleteInput(input) {
                // clear selected opponent when autocomplete input is cleared, which also disables
                // the send button
                if (!input) {
                    this.selectedOpponent = null;
                }
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
