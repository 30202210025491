<template>
    <div>
        <div v-for="test in knowledgeTestsArray" :key="test.id"
             class="col-sm-6 col-md-4"
        >
            <knowledge-test-box :test-id="test.id"></knowledge-test-box>
        </div>

        <div v-if="!areThereAnyTests"
             class="col-sm-6 col-md-4"
        >
            <div class="category-box knowledge-test-box">
                <div data-key="sameHeightCategories">
                    <!-- this is different than img-responsive! -->
                    <div class="bg-img responsive-bg-img"></div>
                    <div class="content-wrapper">
                        <h3 class="test-title">
                            {{ 'KNOWLEDGE_TEST_FOCUS_TITLE' | translate }}
                        </h3>
                        <p class="category-description">
                            {{ 'KNOWLEDGE_TEST_NO_TEST' | translate }}
                        </p>
                    </div>
                </div>
                <div class="button-wrapper"> </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    import KnowledgeTestBox from "./KnowledgeTestBox";
    import log from 'src/services/logger';
    const logTag = "KnowledgeTestList";

    export default {
        name : "KnowledgeTestList",

        components : { KnowledgeTestBox },

        created : function() {
            this.$store.dispatch("downloadKnowledgeTests").catch((reason) => {
                log.log(logTag, "Download of knowledge test failed, reason follows");
                log.log(logTag, reason);
            });
        },

        data() { return {}; },

        computed : mapGetters(["areThereAnyTests", "knowledgeTestsArray"]),

        methods : {}
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>