<template>
    <div id="waiting-for-user">
        <div class="bg-img img-responsive"></div>

        <div class="container">
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <h2>{{ battles.opponent }} {{ 'BATTLES_CHALLENGED_TITLE' | translate }}</h2>

                    <p>
                        {{ 'BATTLES_CHALLENGED_TEXT' | translate }}
                        {{ currentAreaDetails.battles_waiting_for_user_decline_points }}
                    </p>

                    <button @click="acceptBattle()" class="btn primary-btn">
                        {{ 'BATTLES_ACCEPT' | translate }}
                    </button>
                    <button @click="rejectBattle()" class="btn secondary-btn reject-button">
                        {{ 'BATTLES_REJECT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    import userMessages from "src/services/userMessages";

    export default {
        computed : mapGetters([ "currentAreaDetails", "battles" ]),

        methods : {
            acceptBattle() {
                this.$store.dispatch("acceptBattle").then(response => {
                    this.$store.dispatch("showSuccessMessage", userMessages.afterBattleAccept(response));
                })
                .catch(reason => {
                    this.$store.dispatch("showErrorMessage", userMessages.afterBattleAccept(reason));
                });
            },
            rejectBattle() {
                this.$store.dispatch("declineBattle").then(response => {
                    this.$store.dispatch("showSuccessMessage", userMessages.afterBattleDecline(response));
                })
                .catch(reason => {
                    this.$store.dispatch("showErrorMessage", userMessages.afterBattleDecline(reason));
                });
            },
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>