import log from "src/services/logger";
const logTag = "sameHeight";

const equalize = function(selector = '[data-key="sameHeights"]') {
    log.log(logTag, "Equalizing for selector: " + selector, true);

    let elements = document.querySelectorAll(selector),
        max_height = 0;

    if ((elements) && (elements.length > 0)) {
        let numberOfElements = elements.length;

        for (let i = 0; i < numberOfElements; i++) { // get max height
            elements[i].style.height = ''; // reset height attr
            if (elements[i].clientHeight > max_height) {
                max_height = elements[i].clientHeight;
            }
        }

        for (let i = 0; i < numberOfElements; i++) { // set max height to all elements
            elements[i].style.height = max_height + 'px';
        }
    }
};

export default {
    equalize
};
