<template>
    <form class="login-form"
          novalidate @submit.prevent="login"
    >
        <div class="row">
            <div class="col-xs-12 form-group"
                 :class="{'has-error' : $v.loginData.email.$error}"
            >
                <label for="email" class="input-label">{{ 'EMAIL' | translate }}:</label>
                <input type="email" name="email" id="email"
                       v-model="loginData.email" @input="$v.loginData.email.$touch()"
                       class="form-control"
                >
                <validation-messages :field="$v.loginData.email" field-name="email"></validation-messages>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 form-group"
                 :class="{'has-error' : $v.loginData.password.$error}"
            >
                <label for="password" class="input-label">{{ 'PASSWORD' | translate }}:</label>
                <input type="password" name="password" id="password"
                       v-model="loginData.password" @input="$v.loginData.password.$touch()"
                       class="form-control"
                >
                <validation-messages :field="$v.loginData.password" field-name="password"></validation-messages>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 submit-btn-wrapper">
                <button class="btn brand-primary-btn" type="submit">
                    {{ "LOGIN_BUTTON" | translate }}
                </button>
            </div>
        </div>

    </form>
</template>
<!---------------------------------------------------------------------------->
<script>
    import get from "lodash/get";
    import { required, email } from "vuelidate/lib/validators";

    import authenticationService from "src/services/api/authenticationService";
    import ValidationMessages from "Components/common/ValidationMessages";
    import apiDefinition from "src/config/apiDefinition";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "LoginForm";

    export default {
        components : { ValidationMessages },

        activated : function() { this.$emit('created', this.$t("LOGIN_TITLE")); },
        created : function() {
            if (config.debugMode) {
                this.loginData = {
                    email       : "user1@example.com",
                    password    : "111111"
                };
            }

            let lastLoggedInEmail = localStorage.getItem("lastLoggedInEmail") || false;
            if (lastLoggedInEmail) {
                this.loginData.email = lastLoggedInEmail;
            }
        },

        data() {
            return {
                loginData : {
                    email       : "",
                    password    : ""
                },
            };
        },

        validations : {
            loginData : {
                email : { required, email },
                password : { required }
            }
        },

        methods : {
            login() {
                log.log(logTag, "Login button pressed");
                this.$v.$touch();

                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, logging in");

                    this.$store.dispatch("sendLoginRequest", this.loginData)
                    // redirect from Login to Dashboard or somewhere
                    .then((response) => {
                        this.$store.dispatch("showSuccessMessage", userMessages.afterLogin(response));

                        // store the user's email for next login
                        localStorage.setItem("lastLoggedInEmail", this.loginData.email);

                        // has this user been already onboarded? if not, redirect him to Onboarding first
                        if (authenticationService.checkIfUserWasOnboardedAndStore(this.loginData.email)) {
                            this.$router.push("/focus");
                        } else {
                            this.$router.push("/onboarding");
                        }

                        // clear password, just for good measure
                        this.loginData.password = "";
                        this.$v.loginData.$reset();
                    })

                    // login unsuccessful, show the error, clear the password field
                    .catch(reason => {
                        this.$store.dispatch("showErrorMessage", userMessages.afterLogin(reason));

                        if (get(reason, "status", null) === apiDefinition.codes.forbidden) {
                            this.loginData.password = "";
                            this.$v.loginData.$reset();
                        }
                    });
                }
            }
        },
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
