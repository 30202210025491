import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import clone from "lodash/clone";
import maxBy from "lodash/maxBy";
import keyBy from "lodash/keyBy";
import findIndex from "lodash/findIndex";
import size from "lodash/size";

import Vue from "vue";
import store from 'src/store/store';
import apiService from "src/services/api/apiService";
import testTimeSyncService from "src/services/testTimeSyncService";
import * as types from "./mutationTypes";

import log from "src/services/logger";
const logTag = "knowledgeTestStore";

// just a note...check API doc for more info
// https://docs.google.com/document/d/1USPqnre39xnz_2wQIfBi_ocbCzoRHg9gGdegyzi4C5M/edit?usp=sharing
// testItems : [{ id, name, description, type, options, points, image_path, audio_path, answer, is_correct,
//         correct_answer}, {...}, {...}]

const moduleState = {
    // basic knowledge test info
    knowledgeTestsArray    : [],
    knowledgeTests         : {},
    currentTestAttemptIds  : null,

    // concerning current test attempt
    testItems               : [],
    testTimeRemaining       : "",
    currentTestItemIndex    : 0,
    testTimeIsUp            : false,

    // answers to items in this attempt - needed for repopulation on refresh, on "previous item" btn etc
    testAnswers             : {},
};

const moduleGetters = {
    knowledgeTestsArray     : state => state.knowledgeTestsArray,
    knowledgeTests          : state => state.knowledgeTests,
    currentTestAttemptIds   : state => state.currentTestAttemptIds,

    // the test for which the attempt is currently active
    currentTest             : (_, getters) =>
        getters.isAnyAttemptActive ? getters.knowledgeTests[getters.currentTestAttemptIds.test_id] : {},
    currentTestAttemptId    : (_, getters) =>
        getters.isAnyAttemptActive ? getters.currentTestAttemptIds.attempt_id : null,
    areThereAnyTests        : state => !isEmpty(state.knowledgeTestsArray),

    isAnyAttemptActive      : state =>
        state.currentTestAttemptIds !== null && state.currentTestAttemptIds.test_id !== null,
    isAttemptForTestActive  : state => testId =>
        state.currentTestAttemptIds !== null && state.currentTestAttemptIds.test_id === testId,

    testAttempts            : state => testId =>
        get(state.knowledgeTests[testId], "attempts", []),
    previousTestAttempt     : (_, getters) => testId =>
        getters.testAttempts(testId)[getters.testAttempts(testId).length - 1] || {},
    bestTestAttempt         : (_, getters) => testId =>
        maxBy(getters.testAttempts(testId), "percentage"),
    isTestClosingSoon       : state => testId =>
        get(state.knowledgeTests[testId], "closing_soon", false),
    didUserPassTest         : (_, getters) => testId =>
        getters.bestTestAttempt(testId) && getters.bestTestAttempt(testId).result === true,

    maxTestAttempts         : state => testId =>
        get(state.knowledgeTests[testId], "attempts_allowance", 1),
    remainingTestAttempts   : (_, getters) => testId =>
        getters.maxTestAttempts(testId) - get(getters.testAttempts(testId), "length", 0),
    currentAttemptNumber    : (_, getters) => testId => getters.testAttempts(testId).length + 1,

    // ### all these deal with only the currentTest and currently active attempt, so they don't have to take
    // ### testId as a parameter
    testItems               : state => state.testItems,
    // @todo: check this below, as we can also use items_count in test
    testItemsCount          : (_, getters) => getters.testItems.length,
    // testItemsCount          : state => get(state.currentTest, "items_count", 1),
    testTimeRemaining       : state => state.testTimeRemaining,
    testAnswers             : state => state.testAnswers,

    currentTestItemIndex    : state => state.currentTestItemIndex,
    currentTestItem         : state => get(state.testItems, state.currentTestItemIndex, {}),
    currentTestProgress     : (_, getters) => Math.floor((size(getters.testAnswers) / getters.testItemsCount) * 100),
    currentTestItemAnswer   : (state, getters) => state.testAnswers[getters.currentTestItemIndex],

    correctAnswerCount      : (_, getters) => filter(getters.testItems, { is_correct : true }).length,
    testTimeIsUp            : state => state.testTimeIsUp,

    isTestVideoActive       : (_, getters) => !!getters.currentTest.is_video_active,
    isTestAttachmentActive  : (_, getters) => getters.isTestVideoActive
        && isString(getters.currentTest.attachment_exe) && getters.currentTest.attachment_exe.length > 0
};

const actions = {
    downloadKnowledgeTests({ commit }) {
        return apiService.getKnowledgeTests().then(result => {
            commit(types.REPLACE_KNOWLEDGE_TEST_INFO, result.data);
            return result.data;
        });
    },

    startTestAttempt({ commit, getters }, testId) {
        // reset video watched local flag, but only if the attempt is being started, not continued
        if (getters.currentTestAttemptId === null) {
            store.dispatch("resetVideoWatched", testId);
        }

        return apiService.startTestAttempt(testId, getters.currentTestAttemptId).then(result => {
            // reset any previous attempt data
            store.dispatch("resetAttemptData");

            // store the returned attempt ID
            commit(
                types.REPLACE_TEST_ATTEMPT_IDS,
                { testId, attemptId : get(result.data, "attempt_id", null) }
            );
            return result.data;
        });
        // propagating errors
    },

    resetAttemptData({ commit }) {
        commit(types.REPLACE_TEST_TIME_REMAINING, "");
        commit(types.REPLACE_CURRENT_TEST_ITEM_INDEX, 0);
        commit(types.REPLACE_TEST_ANSWERS, {});
        commit(types.REPLACE_TEST_TIME_IS_UP, false);
    },

    resetVideoWatched({}, testId) {
        // remove flag saying video was watched, if present. We don't have to check if previous attempt was
        // unsuccessful because the server returns is_video_watched anyway
        let watchedVideos = JSON.parse(localStorage.getItem("watchedTestVideos")) || [];
        const testVideoIndex = watchedVideos.indexOf(testId);
        if (testVideoIndex !== -1) {
            watchedVideos.splice(testVideoIndex, 1);
            localStorage.setItem("watchedTestVideos", JSON.stringify(watchedVideos));
        }
    },

    downloadTestAttempt({ commit }, attemptId) {
        return apiService.getTestAttemptInfo(attemptId).then(result => {
            // store the attempt data
            commit(types.REPLACE_TEST_ATTEMPT_INFO, result.data);
            return result.data;
        });
    },

    finishTestAttempt({ commit, getters }) {
        return apiService.finishTestAttempt(getters.currentTestAttemptId).then(result => {
            // eagerly store the resulting questions + answers, even though we'll request them again
            commit(types.REPLACE_TEST_ATTEMPT_INFO, result.data);

            // stop the time service, clear the attempt data
            testTimeSyncService.stop();
            store.dispatch("resetAttemptData");

            return result.data;
        });
        // propagating errors
    },

    sendTestAnswer({ commit, getters }, { id, answer }) {
        return apiService.postTestAnswer({ id, answer }).then(result => {
            commit(types.REPLACE_TEST_ANSWER, { testItemIndex : getters.currentTestItemIndex, answer });
            // nothing in payload, nothing to do
            return result.data;
        });
        // propagating errors
    },

    syncTestTime({ getters }) {
        return apiService.getTestSyncTime(getters.currentTestAttemptId).then(result => {
            const timeRemaining = get(result.data, "time_remaining", null);
            // log.log(logTag, `syncTestTime: ${timeRemaining}`);
            return timeRemaining;
        });
        // propagating errors
    },

    updateTimeRemaining({ commit }, formattedTime) {
        commit(types.REPLACE_TEST_TIME_REMAINING, formattedTime);
    },

    setCurrentTestItem({ commit }, newTestItemIndex) {
        // log.log(logTag, `setCurrentTestItem: ${newTestItemIndex}`);
        commit(types.REPLACE_CURRENT_TEST_ITEM_INDEX, newTestItemIndex);
    },

    setTimeIsUp({ commit }, newValue) {
        commit(types.REPLACE_TEST_TIME_IS_UP, newValue);
    },

    setVideoWatched({ commit, getters }) {
        return apiService.postTestVideoWatched(getters.currentTestAttemptId).then(result => {
            commit(types.SET_TEST_VIDEO_WATCHED, getters.currentTest.id);
            return result;
        });
        // propagating errors
    },
};

const mutations = {
    [types.REPLACE_KNOWLEDGE_TEST_INFO](state, newTestInfo) {
        state.knowledgeTestsArray = get(newTestInfo, "current_tests", {});
        state.knowledgeTests = keyBy(state.knowledgeTestsArray, "id");
        state.currentTestAttemptIds = get(newTestInfo, "current_attempt", null);
    },

    [types.REPLACE_TEST_ATTEMPT_IDS](state, { testId, attemptId }) {
        state.currentTestAttemptIds = { test_id : testId, attempt_id : attemptId };
        // Vue.set(state, "currentTestAttemptIds", clone({ testId, attemptId }));
    },

    [types.REPLACE_TEST_ATTEMPT_INFO](state, newAttemptInfo) {
        const items = get(newAttemptInfo, "items", []);
        state.testItems = items;

        // repopulate the answers the user filled in previously, e.g. before page refresh
        let testAnswers = {};
        forEach(items, (item, index) => {
            if (!isEmpty(item.answer)) {
                testAnswers[index] = item.answer;
            }
        });

        Vue.set(state, 'testAnswers', testAnswers);
    },

    [types.REPLACE_TEST_TIME_REMAINING](state, newTimeRemaining) {
        state.testTimeRemaining = newTimeRemaining;
    },

    [types.REPLACE_CURRENT_TEST_ITEM_INDEX](state, newTestItemIndex) {
        state.currentTestItemIndex = newTestItemIndex;
    },

    [types.REPLACE_TEST_ANSWER](state, { testItemIndex, answer }) {
        Vue.set(state.testAnswers, testItemIndex, clone(answer));
    },

    [types.REPLACE_TEST_ANSWERS](state, newAnswers) {
        Vue.set(state, "testAnswers", clone(newAnswers));
    },

    [types.REPLACE_TEST_TIME_IS_UP](state, newValue) {
        state.testTimeIsUp = newValue;
    },

    [types.SET_TEST_VIDEO_WATCHED](state, testId) {
        const testIndex = findIndex(state.knowledgeTestsArray, { id : testId });
        if (testIndex !== -1) {
            Vue.set(state.knowledgeTestsArray[testIndex], "is_video_watched", true);
            Vue.set(state.knowledgeTests[testId], "is_video_watched", true);
        } else {
            log.log(logTag, `Commit SET_TEST_VIDEO_WATCHED: no test ${testId} found!`);
        }
    },
};

export default {
    state : moduleState,
    getters : moduleGetters,
    actions,
    mutations
};
