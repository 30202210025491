var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "profile" } },
    [
      _c("div", { staticClass: "part-1" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", [_vm._v(_vm._s(_vm._f("translate")("PROFILE_MAIN_TITLE")))]),
          _vm._v(" "),
          _c("h2", [
            _vm._v(_vm._s(_vm._f("translate")("PROFILE_MAIN_SUBTITLE"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.allProgress, function (areaProgress, areaId) {
        return _c(
          "div",
          {
            key: areaId,
            staticClass: "area-specific-part",
            class: "part-" + _vm.area(areaId).css_class_name,
          },
          [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("div", { staticClass: "row area-description-row" }, [
                  _c("div", { staticClass: "bg-img img-responsive" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-5 col-sm-offset-7 area-description-col",
                    },
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.area(areaId).name))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.area(areaId).description))]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn primary-btn",
                          attrs: {
                            disabled:
                              areaId == _vm.currentAreaId ? false : true,
                          },
                          on: { click: _vm.continueButton },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm._f("translate")("PROFILE_CONTINUE")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      areaId != _vm.currentAreaId
                        ? _c("div", { staticClass: "area-finished-text" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")("PROFILE_AREA_FINISHED")
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("user-progress", {
                  attrs: {
                    stats: areaProgress,
                    showProgressbar: true,
                    progressbarValue: _vm.areaLevelProgress(areaId),
                    showLeaderboardLink:
                      areaId == _vm.currentAreaId ? true : false,
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      !_vm.ssoEnabled
        ? _c("div", { staticClass: "part-2" }, [
            _c("div", { staticClass: "container" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm._f("translate")("PROFILE_EDIT_MAIN_TITLE"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-6 col-md-offset-3" },
                  [_c("change-password")],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }