import Vue from "vue";
import VueRouter from "vue-router";
import { mapGetters } from "vuex";
import vuexI18n from "vuex-i18n";
import { sync } from "vuex-router-sync";
import Vuelidate from "vuelidate";
import VueScrollTo from "vue-scrollto";
import * as Sentry from "@sentry/vue";
import VueImg from 'v-img';

import router from "src/router/router";
import store from "src/store/store";

// #### translations
import translationsEn from "variantLangs/en";
import translationsCs from "variantLangs/cs";
import translationsSk from "variantLangs/sk";

// #### main components of the app - creating the basic structure
import Navigation from "Components/common/Navigation";
import Loader from "Components/common/Loader";
import AppFooter from "Components/common/AppFooter";
import alert from "vue-strap/src/Alert.vue";

// #### services and stuff
import "src/services/api/httpInterceptors";

// #### app"s main styles, importing also bootstrap etc
import "src/app.scss";

// #### setup VueRouter, sync router with vuex store
// https://github.com/vuejs/vuex-router-sync
Vue.use(VueRouter);
sync(store, router);

// #### setup Sentry - bug tracking
if (config.sentry.enabled) {
    Sentry.init({
        Vue             : Vue,
        dsn             : config.sentry.dns,
        release         : config.sentry.releaseName + "@" + PACKAGE_VERSION,
        integrations    : [
            new Sentry.BrowserTracing({
                routingInstrumentation  : Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets : ["localhost", config.api.base, /^\//],
            }),
        ],

        // @note: set to 0.1 at 1/2023 after 10k limit was reached. Might need readjusting
        tracesSampleRate : 0.1,
    });
}

// #### setup i18n plugin, sync it with vuex store, add translation objects, set default locale to use
// https://github.com/dkfbasel/vuex-i18n
Vue.use(vuexI18n.plugin, store);
Vue.i18n.add("en", translationsEn);
Vue.i18n.add("cs", translationsCs);
Vue.i18n.add("sk", translationsSk);
Vue.i18n.set(config.language.default);

// #### setup Vuelidate
// https://monterail.github.io/vuelidate/
Vue.use(Vuelidate);

// #### setup vue-scrollto
// https://github.com/rigor789/vue-scrollTo
Vue.use(VueScrollTo);

// #### setup v-img
// https://github.com/crowdbotics/v-img
const vueImgConfig = {
    // Use `alt` attribute as gallery slide title
    altAsTitle : false,
    // Display 'download' button near 'close' that opens source image in new tab
    sourceButton : false,
    // Event listener to open gallery will be applied to <img> element
    openOn : 'click',
    // Show thumbnails for all groups with more than 1 image
    thumbnails : false,
};
Vue.use(VueImg, vueImgConfig);


// #### init services and stuff
store.dispatch("initialize").then(() => {
    new Vue({
        router,
        store,
        components : { Navigation, Loader, alert, AppFooter },
        computed : mapGetters([
            "isLoaderShown", "isErrorMessageShown", "errorMessage", "isSuccessMessageShown", "successMessage",
            "currentAreaDetails", "currentAreaCssClass"
        ]),
        created() {
            console.log(`==== Main instance created. Version: ${PACKAGE_VERSION} ====`);
        }
    }).$mount("#app");
});
