<template>
    <div id="test-video">
        <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-2 attempt-number">
                {{ 'KNOWLEDGE_TEST_ATTEMPT' | translate }}
                {{ currentAttemptNumber(currentTest.id) }} / {{ maxTestAttempts(currentTest.id) }}
            </div>
            <div class="col-xs-12 col-sm-9 col-md-10">
                {{ 'KNOWLEDGE_TEST_TIME_REMAINING' | translate }}
                <span class="remaining-time">
                    {{ testTimeRemaining }}
                </span>
            </div>
        </div>

        <h3 class="video-title">{{ currentTest.video_name }}</h3>

        <p class="video-desc">{{ currentTest.video_description }}</p>

        <div class="row video-row">
            <div class="col-xs-12 col-sm-12 col-md-12"
                 :class="{ 'video-not-watched' : !videoHasBeenWatched }"
            >
                <video id="video" playsinline controls crossorigin
                       ref="video"
                ></video>
            </div>
        </div>

        <div class="btn-row">
            <button class="btn secondary-btn"
                    @click="onContinueButton"
                    :disabled="!nextButtonEnabled"
            >
                {{ 'KNOWLEDGE_TEST_VIDEO_TO_ITEMS' | translate }}
            </button>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
import { mapGetters } from "vuex";
import Plyr from 'plyr/dist/plyr.min';


import apiDefinition from "src/config/apiDefinition";
import userMessages from "src/services/userMessages";
import log from 'src/services/logger';
const logTag = "TestVideo";

export default {
    name : "TestVideo",

    components : { },

    mounted : function() {
        log.log(logTag, "mounted");

        // determine whether "Continue to questions" button should be disabled
        const watchedVideos = JSON.parse(localStorage.getItem("watchedTestVideos")) || [];
        if (watchedVideos.indexOf(this.currentTest.id) !== -1 || this.currentTest.is_video_watched) {
            log.log(logTag, `Video was watched before. test.is_video_watched from server: ${this.currentTest.is_video_watched}`);
            this.videoHasBeenWatched = true;
            this.nextButtonEnabled = true;
        }

        // prepare the video player etc
        this.prepareVideo();
    },

    beforeDestroy() {
        try {
            log.log(logTag, "Destroying video player");
            this.videoPlayer.destroy();
        } catch (e) {
            log.error(logTag, e);
        }
    },

    computed : mapGetters([
        "currentTest", "currentAttemptNumber", "maxTestAttempts", "testTimeRemaining"
    ]),

    data() {
        return {
            videoPlayer         : null,
            nextButtonEnabled   : false,
            videoHasBeenWatched : false
        };
    },

    methods : {
        prepareVideo() {
            if (this.videoPlayer !== null) {
                return;
            }

            log.log(logTag, "Mounting Plyr");

            // if the user has already watched this video, show the scrub area so that they can rewind. Otherwise
            // hide it, so that we can just wait for "ended" event to know user has watched the whole video.
            // https://github.com/sampotts/plyr/blob/master/CONTROLS.md
            // @note: this actually didn't work, as on IE11, removing 'progress' from controls caused the
            // other controls to disappear - some flex error. So now we show all the controls, they are
            // hidden in CSS by adding video-not-watched class which simply does display: none on scrub
            let controls;
            if (this.videoHasBeenWatched) {
                controls = ['play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'volume',
                    'fullscreen'];
            } else {
                controls = ['play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'volume',
                    'fullscreen'];
            }

            this.videoPlayer = new Plyr('#video', {
                debug       : false,
                controls    : controls,
                keyboard    : { focused : false, global : false },
            });

            // compile video sources from test data from server
            // @todo: what about size parameter?
            let sources = [];
            if (this.currentTest.video_mp4) {
                sources.push({ src : this.currentTest.video_mp4, type : "video/mp4" });
            }
            if (this.currentTest.video_webm) {
                sources.push({ src : this.currentTest.video_webm, type : "video/webm" });
            }

            this.videoPlayer.source = {
                type    : 'video',
                title   : this.currentTest.video_name,
                sources : sources
            };

            this.videoPlayer.on('ended', this.onVideoEnded);

            // @todo: not needed?
            // this.videoPlayer.on('timeupdate', () => {
            //     console.log("Video timeupdate: " + this.videoPlayer.currentTime);
            // });
        },

        onVideoEnded(event) {
            // this shouldn't happen, so just to be sure
            if (!event || !event.detail) {
                log.log(logTag, `Video "${this.currentTest.video_name}" ended, but no event details!`);
                return;
            }

            // important - this can happen on start, probably when the new sources are set and the player
            // considers it as a destruction of a previous empty player. It is a bug, but in such cases, the
            // "ended" property of plyr is still false
            // https://github.com/sampotts/plyr/issues/1008
            if (!event.detail.plyr.ended) {
                log.log(logTag, `Video "${this.currentTest.video_name}" ended, but not really`
                    + ` - ghost ending at start?`);
                return;
            }

            if (this.currentTest.is_video_watched) {
                log.log(logTag, `Video "${this.currentTest.video_name}" ended, but was already watched before`);
                return;
            }

            log.log(logTag, `Video "${this.currentTest.video_name}" ended; storing the fact into local storage`);

            // check local storage if this video has already been watched. @note: it might've been watched,
            // just not announced to server yet, due to user refreshing by mistake or server error
            // locally store the fact that the video has been watched
            let watchedVideos = JSON.parse(localStorage.getItem("watchedTestVideos")) || [];
            if (watchedVideos.indexOf(this.currentTest.id) === -1) {
                watchedVideos.push(this.currentTest.id);
            }
            localStorage.setItem("watchedTestVideos", JSON.stringify(watchedVideos));

            // user can now click on "Continue to questions" button
            this.nextButtonEnabled = true;
        },

        onContinueButton() {
            if (this.currentTest.is_video_watched) {
                this.$emit("go-to-items");
                return;
            }

            log.log(logTag, "Dispatching 'video watched' event to server");
            this.$store.dispatch("setVideoWatched")

            .then(() => {
                this.$emit("go-to-items");
            })

            .catch(reason => {
                this.$store.dispatch("showErrorMessage", userMessages.afterSetVideoWatched(reason));

                // give the user the chance to re-send the video-watched POST to server if the error was caused
                // by server or sth
                switch (reason.status) {
                    case apiDefinition.codes.gone :
                    case apiDefinition.codes.failedDependency :
                        this.$emit("timeout");
                        log.log(logTag, "Redirecting - attempt timed out or test is already inactive");
                        this.$router.push("/focus");
                        break;
                    default:
                        // do nothing
                        break;
                }
            });
        }

    }
};
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>