<template>
    <div id="battles" class="page">
        <div class="part-1">
            <div class="container">
                <div class="row main-title-row">
                    <div class="bg-img img-responsive"></div>

                    <div class="col-sm-6 col-sm-offset-6 col-md-5 col-md-offset-7 main-title-col">
                        <h1>{{ currentAreaDetails.battles_main_title_first_row }}</h1>
                        <h1>{{ currentAreaDetails.battles_main_title_second_row }}</h1>
                        <p class="main-subtitle">
                            {{ currentAreaDetails.battles_main_subtitle }}
                        </p>
                        <button @click="ctaButtonClick()" class="btn primary-btn cta-btn">
                            <span v-if="battles.currentState === battles.states.NO_BATTLE">
                               {{ currentAreaDetails.battles_ctoa_button_title }}
                            </span>
                            <span v-else>
                                {{ 'BATTLES_CONTINUE_BATTLE' | translate }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div class="part-2">
            <div class="container">
                <h2>{{ currentAreaDetails.battles_instructions_title }}</h2>

                <div class="row">
                    <div class="col-sm-6 col-md-4 instruction first-instruction">
                        <div class="bg-img img-responsive"></div>

                        <div class="instruction-wrapper"
                             data-key="sameHeightInstructions"
                        >
                            <h3>{{ currentAreaDetails.battles_instructions_first_title }}</h3>
                            <p>{{ currentAreaDetails.battles_instructions_first_text }}</p>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 instruction second-instruction">
                        <div class="bg-img img-responsive"></div>

                        <div class="instruction-wrapper"
                             data-key="sameHeightInstructions"
                        >
                            <h3>{{ currentAreaDetails.battles_instructions_second_title }}</h3>
                            <p>{{ currentAreaDetails.battles_instructions_second_text }}</p>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 instruction third-instruction">
                        <div class="bg-img img-responsive"></div>

                        <div class="instruction-wrapper"
                             data-key="sameHeightInstructions"
                        >
                            <h3>{{ currentAreaDetails.battles_instructions_third_title }}</h3>
                            <p>{{ currentAreaDetails.battles_instructions_third_text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-3" id="arena-area">
            <h2>{{ 'BATTLES_ARENA' | translate }}</h2>
        </div>

        <!-- Arena -->
        <div class="part-4">
            <transition name="component-fade" mode="out-in">
                <component :is="currentArenaComponent"></component>
            </transition>
        </div>

    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import sameHeight from "src/services/sameHeight";

    import NoBattle from "./NoBattle";
    import WaitingForOpponent from "./WaitingForOpponent";
    import WaitingForUser from "./WaitingForUser";
    import BattleInProgress from "./BattleInProgress";

    import { sendToSentry } from "src/services/sentryWrapper";

    // import log from 'src/services/logger';
    // const logTag = "Battles";

    export default {
        components : { NoBattle, WaitingForOpponent, WaitingForUser, BattleInProgress },

        created : function() {
            this.$store.dispatch("downloadBattles").then(() => {
                this.$nextTick().then(() => {
                    this.equalizeHeights();
                });
            }).catch(() => {});
        },

        mounted : function() {
            setTimeout(this.equalizeHeights, 500);
            setTimeout(this.equalizeHeights, 1500);
            window.addEventListener('resize', this.equalizeHeights);
        },

        destroyed : function() {
            window.removeEventListener('resize', this.equalizeHeights);
        },

        data() {
            return {};
        },

        computed : mapGetters([ "currentAreaDetails", "battles", "currentArenaComponent" ]),

        methods : {
            ctaButtonClick() {
                // this.$store.dispatch("testSwal"); // debug only
                // sendToSentry("text", "abcd", { value : "test" })
                this.$scrollTo("#arena-area", 400, {});
            },

            equalizeHeights() {
                sameHeight.equalize("[data-key='sameHeightInstructions']");
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
