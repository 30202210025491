<template>
    <div id="reset-password" class="page">
        <div class="part-1">
            <div class="container">
                <h1>{{ 'RESET_PASS_MAIN_TITLE' | translate }}</h1>
                <h3>{{ 'RESET_PASS_MAIN_SUBTITLE' | translate }}</h3>
                <h2>{{ 'RESET_PASS_SECONDARY_TITLE' | translate }}</h2>

                <div class="row">
                    <div class="col-xs-12 col-md-6 col-md-offset-3">
                        <div class="panel panel-default">
                            <div class="panel-heading">{{ 'RESET_PASS_TITLE' | translate }}</div>
                            <div class="panel-body">
                                <form @submit.prevent="reset" novalidate>
                                    <div class="row">
                                        <div class="col-xs-12 form-group"
                                             :class="{'has-error' : $v.password.$error}"
                                        >
                                            <label for="password" class="input-label">{{ 'NEW_PASSWORD' | translate }}</label>
                                            <vue-password name="password" id="password"
                                                          v-model="password" @input="$v.password.$touch()"
                                                          classes="form-control"
                                                          disableStrength
                                            >
                                            </vue-password>
                                            <validation-messages :field="$v.password" field-name="password"></validation-messages>
                                        </div>
                                    </div>

                                    <div class="btn-row">
                                        <button class="btn brand-primary-btn" type="submit">
                                            {{ "RESET_PASS_BUTTON" | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from 'vuex';
    import VuePassword from 'vue-password/dist/custom';
    import { required, minLength } from "vuelidate/lib/validators";

    import ValidationMessages from "Components/common/ValidationMessages";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "ResetPassword";

    export default {
        components : { VuePassword, ValidationMessages },

        data() {
            return {
                password : ""
            };
        },

        validations : {
            password : { required, minLength : minLength(6) }
        },

        computed : mapGetters([ 'emailParam', 'tokenParam' ]),

        created() {
            this.$store.dispatch("doLocalLogout").catch(() => {});
        },

        methods : {
            reset() {
                log.log(logTag, "Reset button pressed");
                this.$v.$touch();

                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, sending request to reset password");

                    this.$store.dispatch("sendResetPassRequest", {
                        password    : this.password,
                        email       : this.emailParam,
                        token       : this.tokenParam
                    })

                    // redirect to Dashboard or somewhere. User is already logged in, has new token
                    .then((response) => {
                        this.$store.dispatch("showSuccessMessage", userMessages.afterResetPassword(response));
                        this.$router.push("/focus");
                    })
                    // registration unsuccessful, show the error
                    .catch(reason => {
                        this.$store.dispatch("showErrorMessage", userMessages.afterResetPassword(reason));
                    });
                }
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>