<template>
    <div class="item-answer">
        <form novalidate @submit.prevent="submit">
            <div class="form-group" :class="{'has-error' : $v.answer.$error}">
                <h4 class="answer-instruction">{{ 'YOUR_ANSWER' | translate }}:</h4>

                <div class="text-answer-wrapper vertical-align">
                    <!--@note: we do not $touch the field on @input, only on submit! better UE -->
                    <input type="text" name="answer" id="answer"
                           v-model="answer"
                           class="form-control"
                    >
                    <span class="text-answer-length-counter">
                        {{ answerLength }} / {{ item.options.length }}
                    </span>
                </div>
                <validation-messages :field="$v.answer" field-name="answer"></validation-messages>
            </div>

            <slot name="buttons"></slot>
        </form>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import answerLength from "src/services/validators/answerLength";

    import ValidationMessages from "Components/common/ValidationMessages";

    import log from "src/services/logger";
    const logTag = "TextAnswer";

    export default {
        components : { ValidationMessages },

        props : {
            item : { type : Object, default : null }
        },

        data() {
            return {
                answer : ""
            };
        },

        validations() {
            return {
                answer : { answerLength : answerLength(this.item.options.length) }
            };
        },

        computed : {
            answerLength() { return String(this.answer).trim().length; }
        },

        methods : {
            submit() {
                log.log(logTag, "Answer button pressed");
                // @note: we do not $touch the field on @input, only on submit! better UE
                this.$v.$touch();

                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, answering");
                    // just send the data to parent element
                    this.$emit("answer", this.answer);
                }
            },
            reset() {
                this.answer = "";
                this.$v.answer.$reset();
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
