<template>
    <div class="item-answer">
        <form novalidate @submit.prevent="submit">
            <div class="form-group">
                <!-- @note: disabled on 24.09.2018 as per client's wish https://app.asana.com/0/292504923637095/834339502262266/f -->
                <!--<h4 class="answer-instruction">-->
                    <!--<span v-if="choicesLength === 0">{{ 'SELECT_ALL_CORRECT_ANSWERS' | translate }}</span>-->
                    <!--<span v-else-if="choicesLength === 1">{{ 'SELECT_ONE_CORRECT_ANSWER' | translate }}</span>-->
                    <!--<span v-else-if="choicesLength < 5">{{ $t("SELECT_2_TO_4_CORRECT_ANSWERS", { count : choicesLength }) }}</span>-->
                    <!--<span v-else>{{ $t("SELECT_5_AND_MORE_CORRECT_ANSWERS", { count : choicesLength }) }}</span>-->
                <!--</h4>-->

                <div v-for="(choice, order) in item.options.choices"
                     :class="{ 'checked' : isChecked(choice.id) }"
                     class="custom-checkbox"
                >
                    <div class="row">
                        <div class="col-xs-3 col-sm-2 checkbox-col">
                            <input type="checkbox" :id="'checkbox-' + choice.id"
                                   :value="choice.id" v-model="answer" @change="checkboxChanged"
                                   class="regular-checkbox big-checkbox"
                            /><label :for="'checkbox-' + choice.id">{{ orderToChar(order) }}</label>
                        </div>
                        <div class="col-xs-9 col-sm-10 tag-col">
                            <div class="tag" @click="">{{ choice.text }}</div>
                        </div>
                    </div>
                </div>

                <span v-show="isErrorMessageShown" class="validation-message has-error">{{ errorMessage }}</span>
            </div>

            <slot name="buttons"></slot>
        </form>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import log from "src/services/logger";
    const logTag = "ChoiceAnswer";

    export default {
        components : { },

        props : {
            item : { type : Object, default : null }
        },

        data() {
            return {
                // array of choice IDs
                answer          : [],
                errorMessage    : ""
            };
        },

        computed : {
            choicesLength() { return this.item.options.length; },
            isErrorMessageShown() { return this.errorMessage.length > 0; },
            orderToChar() {
                return function(order) {
                    let map = [
                        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
                        'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
                    ];
                    return map[order];
                };
            }
        },

        methods : {
            checkboxChanged() {
                // if the length of the answer (number of choices) is fixed to N, then when (N+1)th
                // @note: disabled on 24.09.2018 as per client's wish https://app.asana.com/0/292504923637095/834339502262266/f
                // choice is selected, unselect the first selected choice
                // if (this.choicesLength !== 0 && this.answer.length > this.choicesLength) {
                //     this.answer.shift();
                // }
                this.errorMessage = "";
            },

            submit() {
                log.log(logTag, "Answer button pressed");

                // first, "validate" the answer array
                // @note: disabled on 24.09.2018 as per client's wish https://app.asana.com/0/292504923637095/834339502262266/f
                // if (this.choicesLength !== 0 && this.answer.length !== this.choicesLength) {
                //     this.errorMessage = this.$t("INCORRECT_ANSWER_COUNT");
                // } else {
                //     this.errorMessage = "";
                //     // just send the data to parent element
                //     this.$emit("answer", this.answer);
                // }

                // just "validate" whether there is at least one answer selected
                if (this.answer.length === 0) {
                    this.errorMessage = this.$t("SELECT_SOME_ANSWERS");
                } else {
                    this.errorMessage = "";
                    // just send the data to parent element
                    this.$emit("answer", this.answer);
                }
            },

            reset() {
                this.answer = [];
            },

            isChecked(id) {
                return this.answer.indexOf(id) !== -1;
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>