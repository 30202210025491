<template>
    <div>
        <div class="alert alert-success" role="alert" v-if="isSuccessMessageShown">{{ successMessage }}</div>

        <form class="login-form"
              v-else
              novalidate @submit.prevent="sendRequest"
        >
            <div class="row">
                <div class="col-xs-12 form-group"
                     :class="{'has-error' : $v.formData.email.$error}"
                >
                    <label for="email" class="input-label">{{ 'EMAIL' | translate }}</label>
                    <input type="email" name="email" id="email"
                           v-model="formData.email" @input="errorMessage = ''"
                           class="form-control"
                    >
                    <validation-messages :field="$v.formData.email" field-name="email"></validation-messages>
                    <span v-show="isErrorMessageShown" class="validation-message has-error">{{ errorMessage }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 submit-btn-wrapper">
                    <button class="btn brand-primary-btn" type="submit">
                        {{ "FORGOTTEN_PASS_SUBMIT" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { required, email } from "vuelidate/lib/validators";

    import ValidationMessages from "Components/common/ValidationMessages";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "ForgottenPassForm";

    export default {
        components : { ValidationMessages },

        activated() { this.$emit('created', this.$t("FORGOTTEN_PASSWORD_TITLE")); },

        data() {
            return {
                formData : {
                    email       : "",
                },
                errorMessage    : "",
                successMessage  : ""
            };
        },

        validations : {
            formData : {
                email : { required, email },
            }
        },

        computed : {
            isErrorMessageShown() { return this.errorMessage.length > 0; },
            isSuccessMessageShown() { return this.successMessage.length > 0; }
        },

        methods : {
            sendRequest() {
                log.log(logTag, "Forgotten pass submit button pressed");
                this.$v.$touch();

                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, sending forgotten pass request");
                    this.errorMessage = "";

                    this.$store.dispatch("sendForgottenPassRequest", this.formData)
                    .then((response) => {
                        this.successMessage = userMessages.afterForgottenPass(response);
                        this.$store.dispatch("showSuccessMessage", this.successMessage);
                    })
                    // forgotten pass request unsuccessful, show the error
                    .catch(reason => {
                        this.errorMessage = userMessages.afterForgottenPass(reason);
                        this.$store.dispatch("showErrorMessage", this.errorMessage);
                    });
                }
            }
        }

    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>