<template>
    <div class="row answer-btn-row">
        <div class="col-xs-12 col-sm-6 btn-col">
            <button class="btn primary-btn" type="submit"
                    :disabled="isLoaderShown"
            >
                {{ "ANSWER_BUTTON" | translate }}
            </button>
        </div>

        <div class="col-xs-12 col-sm-6 btn-col">
            <button class="btn secondary-btn" type="button" @click.prevent="postpone()"
                    :disabled="isLoaderShown"
            >
                {{ "ANOTHER_QUESTION_BUTTON" | translate }}
            </button>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "isLoaderShown" ]),

        methods : {
            // @note: 'submit' event is caught by the parent form automatically
            postpone() {
//                this.$store.dispatch("testSwal"); // debug only
                this.$emit("postpone");
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>