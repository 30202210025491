var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-answer" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.$v.answer.$error },
          },
          [
            _c("h4", { staticClass: "answer-instruction" }, [
              _vm._v(_vm._s(_vm._f("translate")("YOUR_ANSWER")) + ":"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-answer-wrapper vertical-align" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.answer,
                    expression: "answer",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "answer", id: "answer" },
                domProps: { value: _vm.answer },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.answer = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-answer-length-counter" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.answerLength) +
                    " / " +
                    _vm._s(_vm.item.options.length) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("validation-messages", {
              attrs: { field: _vm.$v.answer, "field-name": "answer" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm._t("buttons"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }