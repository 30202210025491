<template>
    <div id="area-chooser" class="page">
        <div class="part-1">
            <div class="container">
                <h1>{{ 'AREA_CHOOSER_MAIN_TITLE' | translate }}</h1>
                <h3>{{ 'AREA_CHOOSER_MAIN_SUBTITLE_1' | translate }}</h3>
                <h3>{{ 'AREA_CHOOSER_MAIN_SUBTITLE_2' | translate }}</h3>
            </div>
        </div>

        <div class="area-specific-part"
             v-for="(areaProgress) in allAreasProgressSorted" :key="areaProgress.id"
             :class="'part-' + area(areaProgress.id).css_class_name"
             style=""
        >
            <div class="container">
                <div class="row area-description-row">
                    <div class="bg-img img-responsive"></div>

                    <div class="col-xs-12 col-sm-5 col-sm-offset-7 area-description-col">
                        <h2>{{ area(areaProgress.id).name }}</h2>
                        <p>{{ area(areaProgress.id).description }}</p>

                        <button @click="goToArea(areaProgress.id)"
                                class="btn primary-btn"
                                :disabled="areaProgress.id == currentAreaId"
                        >
                            {{ 'AREA_CHOOSER_CONTINUE' | translate }}
                        </button>
                        <div class="area-finished-text"
                             v-if="areaProgress.id == currentAreaId"
                        >
                            {{ 'AREA_CHOOSER_CURRENT_AREA' | translate }}
                        </div>
                    </div>

                </div>

                <user-progress :stats="areaProgress"
                               :showProgressbar="true"
                               :progressbarValue="areaLevelProgress(areaProgress.id)"
                               :showLeaderboardLink="(areaProgress.id == currentAreaId) ? true : false"
                ></user-progress>
            </div>
        </div>

    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import sortBy from "lodash/sortBy";
    import map from "lodash/map";
    import { mapGetters } from "vuex";
    import UserProgress from "Components/common/UserProgress";

    export default {
        components : { UserProgress },

        data() {
            return {};
        },

        computed : {
            ...mapGetters([ "allProgress", "currentAreaId", "area", "areaLevelProgress" ]),

            // areas are sorted by ID, descending, so newer areas are on top
            allAreasProgressSorted() {
                const allAreaProgressWithIds = map(this.allProgress, (areaProgress, areaId) =>
                    ({ ...areaProgress, id : areaId })
                );
                return sortBy(allAreaProgressWithIds, ['id']).reverse();
            }
        },

        methods : {
            goToArea(areaId) {
                // inform the server about preferred area, set current area, set area ID to headers
                // we don't need to reload
                this.$store.dispatch("changePreferredArea", areaId)
                .then(() => {
                    this.$router.push("/onboarding");
                });
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
