<template>
    <div id="test-item-audio">
        <audio id="audio" playsinline controls crossorigin
        ></audio>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
import Plyr from 'plyr/dist/plyr.min';

import log from 'src/services/logger';
const logTag = "TestItemAudio";

export default {
    name : "TestItemAudio",

    mounted : function() {
        log.log(logTag, "mounted");

        // create Plyr instance
        if (this.audioPlayer === null) {
            log.log(logTag, "Mounting Plyr for audio purposes");
            this.audioPlayer = new Plyr('#audio', {});
        }

        // set source
        this.audioPlayer.source = {
            type    : 'audio',
            sources : [{ src : this.item.audio_path }]
        };
    },

    beforeDestroy() {
        try {
            log.log(logTag, "Destroying audio player");
            this.audioPlayer.destroy();
        } catch (e) {
            log.error(logTag, e);
        }
    },

    props : {
        item : { type : Object, default : null }
    },

    data() {
        return {
            audioPlayer         : null,
        };
    },

    watch : {
        // we need this to update the audio source because for example, when there are two consecutive items
        // with audio, the component is not mounted again, only re-used
        item(newItem) {
            this.audioPlayer.source = {
                type    : 'audio',
                sources : [{ src : newItem.audio_path }]
            };
        },
    },
};
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>