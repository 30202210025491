import * as types from "./mutationTypes";
import store from 'src/store/store';
import apiService from "src/services/api/apiService";
import authenticationService from 'src/services/api/authenticationService';
// import log from "src/services/logger";
// const logTag = "store.auth";

const moduleState = {
    isAuthenticated     : false,
    authToken           : ""
};

const moduleGetters = {
    isAuthenticated     : state => state.isAuthenticated,
    authToken           : state => state.authToken
};

const actions = {
    sendLoginRequest(_, loginData) {
        return authenticationService.login(loginData).then(result => {
            // user is now logged in
            // @note: we don't wait for it in this case, but if we wanted to
            // use Area Chooser feature, we'd have to - see sendSsoLoginRequest
            store.dispatch("afterLogin", result.data.token);
            return result;
        });
    },

    sendSsoLoginRequest(_, loginData) {
        return authenticationService.ssoLogin(loginData).then(result => {
            // user is now logged in
            // @note we wait for afterLogin
            return store.dispatch("afterLogin", result.data.token).then(() => {
                // return the original ssoLogin api call response
                return result;
            });
        });
    },

    afterLogin({ commit }, token) {
        commit(types.SET_AUTH_DATA, token);
        store.dispatch("downloadAreas");
        // pull user info and wait for it - it also contains preferred_area_id which is then set into
        // HTTP header in Area Chooser feature. The caller of this action doesn't necessarily have to wait
        // for it though
        return store.dispatch("downloadUserData")
            .then(() => store.dispatch("startNotificationPolling"));
    },

    sendLogoutRequest() {
        return authenticationService.logout().then((result) => {
            store.dispatch("doLocalLogout");
            return result;
        });
    },

    doLocalLogout({ commit }) {
        store.dispatch("stopNotificationPolling");
        authenticationService.handleTokenRemoval();
        commit(types.UNSET_AUTH_DATA);
    },

    sendRegistrationRequest(_, registrationData) {
        // @note: a useless action? apiService can be called directly
        return authenticationService.register(registrationData);
    },

    sendActivateRequest(_, activationData) {
        return authenticationService.activate(activationData).then(result => {
            // user is now logged in
            store.dispatch("afterLogin", result.data.token);
            return result;
        });
    },

    sendForgottenPassRequest(_, data) {
        return apiService.forgottenPass(data);
    },

    sendResetPassRequest({ commit }, passData) {
        return authenticationService.resetForgottenPass(passData).then(result => {
            // user is now logged in
            store.dispatch("afterLogin", result.data.token);
            return result;
        });
    },

    sendChangePassRequest({ commit }, passData) {
        return authenticationService.changePass(passData)
        .then(result => {
            commit(types.SET_AUTH_DATA, result.data.token);
            return result;
        });
    },
};

const mutations = {
    [types.SET_AUTH_DATA](state, token) {
        state.authToken = token;
        state.isAuthenticated = true;
    },
    [types.UNSET_AUTH_DATA](state) {
        state.authToken = "";
        state.isAuthenticated = false;
    },
};

export default {
    state : moduleState,
    getters : moduleGetters,
    actions,
    mutations
};
