<template>
    <div id="test-result">
        <h3 v-if="previousTestAttempt(testId).result === true"
            class="result-title text-correct"
        >
            {{ 'KNOWLEDGE_TEST_SUCCESS' | translate }}
        </h3>
        <h3 v-else-if="previousTestAttempt(testId).result === false"
            class="result-title text-incorrect"
        >
            {{ 'KNOWLEDGE_TEST_FAIL' | translate }}
        </h3>
        <!-- test will be evaluated manually (contains any-text item)-->
        <h3 v-else class="result-title">
            {{ 'KNOWLEDGE_TEST_MANUAL_RESULT' | translate }}
        </h3>

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 attempt-number">
                {{ 'KNOWLEDGE_TEST_ATTEMPT' | translate }}
                <!-- note that we have to subtract 1 as this attempt is already finished -->
                {{ currentAttemptNumber(testId) - 1 }} / {{ maxTestAttempts(testId) }}
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 correct-answers">
                {{ 'KNOWLEDGE_TEST_CORRECT_ANSWERS' | translate }}
                {{ correctAnswerCount }}
                <template v-if="!testHasManualEvaluation">/ {{ testItemsCount }}</template>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-5 result-points"
                 v-if="!testHasManualEvaluation"
            >
                {{ 'KNOWLEDGE_TEST_RESULT_POINTS' | translate }}
                {{ previousTestAttempt(testId).attempt_points }} / {{ previousTestAttempt(testId).test_points }}
            </div>
        </div>

        <!-- Test answers 04/2024 - 
            if the user passed the test, test answers are shown. If not, they are shown to everyone on the last attempt. -->
        <template v-if="previousTestAttempt(testId).result === true || remainingTestAttempts(testId) === 0">
            <div v-for="(item, index) in testItems" :key="item.id"
                class="item-result" :class="calculateItemClass(item)"
            >
                <div class="row">
                    <div class="col-xs-12 col-sm-8">
                        <span class="item-number">
                            {{ 'KNOWLEDGE_TEST_QUESTION' | translate }} {{ index + 1 }} / {{ testItemsCount }}
                        </span>

                        <span v-html="createLabel(item)"></span>
                    </div>
                    <div class="col-xs-12 col-sm-4 text-right item-points"
                        v-if="!testHasManualEvaluation || item.type !== 'any_text'"
                    >
                        {{ $t("KNOWLEDGE_TEST_POINTS", { 'variable' : item.is_correct === true ? item.points : '0' }) }}
                    </div>
                </div>

                <h3 class="item-name">{{ item.name }}</h3>

                <!-- Text type of question -->
                <template v-if="item.type === 'text'">
                    <template v-if="item.answer">
                        <p class="user-answer">
                            <span class="answer-title">{{ 'KNOWLEDGE_TEST_YOUR_ANSWER' | translate }}:</span>
                            {{ item.answer }}
                        </p>

                        <template v-if="item.correct_answer && item.is_correct === false">
                            <p>
                                <span class="answer-title">{{ 'KNOWLEDGE_TEST_CORRECT_ANSWER' | translate }}:</span>
                                {{ item.correct_answer }}
                            </p>
                        </template>
                    </template>
                </template>

                <!-- Choice type of question -->
                <template v-else-if="item.type === 'choice'">
                    <template v-if="item.answer">
                        <div class="answer-title user-answer">{{ 'KNOWLEDGE_TEST_YOUR_ANSWER' | translate }}:</div>
                        <ul class="answer-list">
                            <li v-for="answerId in item.answer" :key="answerId">
                                {{ find(item.options.choices, { id : answerId }).text }}
                            </li>
                        </ul>

                        <template v-if="item.correct_answer && item.is_correct === false">
                            <div class="answer-title ">{{ 'KNOWLEDGE_TEST_CORRECT_ANSWER' | translate }}:</div>
                            <ul class="answer-list">
                                <li v-for="answerId in item.correct_answer" :key="answerId">
                                    {{ find(item.options.choices, { id : answerId }).text }}
                                </li>
                            </ul>
                        </template>
                    </template>
                </template>


                <!-- manual test evaluation, any-text item -->
                <template v-else-if="item.type === 'any_text'">
                    <p class="user-answer">
                        <span class="answer-title">{{ 'KNOWLEDGE_TEST_YOUR_ANSWER' | translate }}:</span>
                        <template v-if="item.answer">
                            {{ item.answer }}
                        </template>
                        <template v-else>
                            {{ 'KNOWLEDGE_TEST_RESULT_NO_ANSWER' | translate }}
                        </template>
                    </p>
                    <p class="manual-item-info">{{ 'KNOWLEDGE_TEST_MANUAL_ITEM' | translate }}</p>
                </template>
            </div>
        </template>


        <p class="manual-info" v-if="testHasManualEvaluation">
            {{ 'KNOWLEDGE_TEST_MANUAL_INFO' | translate }}
        </p>

        <div class="remaining-attempts">
            <template v-if="remainingTestAttempts(testId) === 0">
                {{ 'KNOWLEDGE_TEST_ATTEMPTS_0' | translate }}
            </template>
            <template v-else-if="remainingTestAttempts(testId) === 1">
                {{ 'KNOWLEDGE_TEST_ATTEMPTS_1' | translate }}
            </template>
            <template v-else-if="remainingTestAttempts(testId) <= 4">
                {{ $t('KNOWLEDGE_TEST_ATTEMPTS_2_4', { count : remainingTestAttempts(testId) }) }}
            </template>
            <template v-else>
                {{ $t('KNOWLEDGE_TEST_ATTEMPTS_5_MORE', { count : remainingTestAttempts(testId) } ) }}
            </template>
        </div>

        <div class="btn-row">
            <button class="btn btn-success" @click="exit">
                {{ 'KNOWLEDGE_TEST_EXIT' | translate }}
            </button>
            <button class="btn secondary-btn" @click="newAttempt"
                    :disabled="remainingTestAttempts(testId) === 0"
            >
                {{ 'KNOWLEDGE_TEST_NEW_ATTEMPT' | translate }}
            </button>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import find from "lodash/find";

    import notificationService from "src/services/notificationService";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "TestResult";

    export default {
        name : "TestResult",

        components : { },

        created : function() {},

        props : {
            testId : { type : Number, default : null }
        },

        computed : {
            testHasManualEvaluation() {
                return this.previousTestAttempt(this.testId).result === null;
            },
            ...mapGetters([
                "knowledgeTests", "previousTestAttempt", "currentAttemptNumber", "maxTestAttempts", "testItems",
                "correctAnswerCount", "testItemsCount", "remainingTestAttempts", "currentAreaCssClass"
            ])
        },

        data() {
            return {};
        },

        methods : {
            find,

            exit() {
                this.$router.push("/focus");
            },

            calculateItemClass(item) {
                if (!item.answer) {
                    return "no-answer";
                }

                if (item.is_correct === true) {
                    return "correct-answer";
                }

                if (item.is_correct === false) {
                    return "incorrect-answer";
                }

                if (item.is_correct === null) {
                    return "manual-answer";
                }
                return "";
            },

            createLabel(item) {
                if (this.testHasManualEvaluation && item.type === 'any_text') {
                    return `
                        <span class="label result-label label-default">
                            ${this.$t("KNOWLEDGE_TEST_RESULT_MANUAL")}
                        </span>`;
                }

                if (!item.answer) {
                    return `
                         <span class="label result-label label-warning">
                             ${this.$t("KNOWLEDGE_TEST_RESULT_NO_ANSWER")}
                         </span>`;
                }

                if (item.is_correct === true) {
                    return `
                        <span class="label result-label label-success">
                            ${this.$t("KNOWLEDGE_TEST_RESULT_CORRECT")}
                        </span>`;
                }

                if (item.is_correct === false) {
                    return `
                        <span class="label result-label label-danger">
                            ${this.$t("KNOWLEDGE_TEST_RESULT_INCORRECT")}
                        </span>`;
                }

                return ``;
            },

            newAttempt() {
                let title;
                let description;

                if (this.previousTestAttempt(this.testId).result === true) {
                    title = this.$t("KNOWLEDGE_TEST_REPEAT_SUCCESS_TITLE");
                    description = this.$t("KNOWLEDGE_TEST_REPEAT_SUCCESS_TEXT");
                } else {
                    title = this.$t("KNOWLEDGE_TEST_REPEAT_FAIL_TITLE");
                    description = this.$t("KNOWLEDGE_TEST_REPEAT_FAIL_TEXT");
                }

                notificationService.knowledgeTestGeneric(
                    this.knowledgeTests[this.testId].title,
                    title,
                    description,
                    this.currentAreaCssClass,
                    (result) => {
                        if (result === true) {
                            // we have to start the attempt here, before redirecting back to the state
                            // before TestResult
                            this.$store.dispatch("startTestAttempt", this.testId)

                            .then(() => {
                                this.$emit("repeat-attempt");
                            })

                            .catch(reason => {
                                log.log(logTag, "Start test attempt - error, reason follows:");
                                log.log(logTag, reason);
                                this.$store.dispatch("showErrorMessage", userMessages.afterStartTestAttempt(reason));
                                // redirect. /focus will redownload the test data
                                this.$router.push("/focus");
                            });
                        }
                    }
                );
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>