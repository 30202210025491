var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.variant === "ihrdinove"
            ? [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: require("variantImages/logo_footer.png") },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12 col-sm-4 left-column" }, [
                  _c("a", { attrs: { href: "https://www.innogy.cz/" } }, [
                    _vm._v("innogy"),
                  ]),
                  _vm._v(
                    "\n                    ©2017 " +
                      _vm._s(_vm._f("translate")("CREATED_BY")) +
                      "\n                    "
                  ),
                  _c("a", { attrs: { href: "http://www.luducrafts.com/" } }, [
                    _vm._v("Luducrafts"),
                  ]),
                  _vm._v(
                    ",\n                    v" +
                      _vm._s(_vm.version) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-12 col-sm-4 text-center middle-column",
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("translate")("FOR_MORE_INFO_CONTACT")) +
                        "\n                    "
                    ),
                    _c("a", { attrs: { href: "mailto:hrej@innogy.cz" } }, [
                      _vm._v("hrej@innogy.cz"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12 col-sm-4 right-column" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("translate")("FOOTER_RIGHT_TEXT")) +
                      "\n                "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.variant === "akademia"
            ? [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-6 col-md-8 col-lg-6 text-center middle-column",
                  },
                  [
                    _vm._v(
                      "\n                    Akadémia Budúcnosti\n                    |\n                    " +
                        _vm._s(_vm._f("translate")("CREATED_BY")) +
                        " "
                    ),
                    _c("a", { attrs: { href: "http://www.luducrafts.com/" } }, [
                      _vm._v("Luducrafts"),
                    ]),
                    _vm._v(
                      "\n                    |\n                    ©2023 Slovenská sporiteľňa, a.s.\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-3 col-md-2 col-lg-3 right-column",
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("translate")("FOOTER_RIGHT_TEXT")) +
                        "\n                "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-xs-12 col-sm-3 col-md-2 col-lg-3 left-column" },
      [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("variantImages/logo_footer.svg") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }