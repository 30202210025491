var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "answer-response-view correct-answer" }, [
    _c("h3", [_vm._v(_vm._s(_vm._f("translate")("CORRECT_ANSWER_TITLE")))]),
    _vm._v(" "),
    _c("div", { staticClass: "bg-img img-responsive" }),
    _vm._v(" "),
    _c("p", [
      _vm._v(_vm._s(_vm._f("translate")("CORRECT_ANSWER_DESCRIPTION"))),
    ]),
    _vm._v(" "),
    _vm.lastAnswerData.points > 0
      ? _c("p", { staticClass: "points" }, [
          _vm._v(
            "\n        +" +
              _vm._s(
                _vm.$t("POINTS_GAINED", { points: _vm.lastAnswerData.points })
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn secondary-btn", on: { click: _vm.next } },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("translate")("ANOTHER_QUESTION_BUTTON")) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }