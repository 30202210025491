<template>
    <div class="item-answer">
        <div class="form-group" :class="{'has-error' : $v.answer.$error}">
            <div class="text-answer-wrapper vertical-align">
                <!--@note: we do not $touch the field on @input, only on submit! better UE -->
                <textarea name="answer"
                          id="answer"
                          class="form-control"
                          :class="{ 'answered' : answer.length > 0 }"
                          v-model="answer"
                          rows="3"
                          :placeholder="'KNOWLEDGE_TEST_SUBMIT_TEXT_PH' | translate"
                >
                </textarea>
            </div>
            <validation-messages :field="$v.answer" field-name="answer"></validation-messages>
        </div>

        <button class="btn btn-success center-block"
                @click="submit()"
                :disabled="answer.length === 0"
        >
            {{ 'KNOWLEDGE_TEST_ANSWER' | translate }}
        </button>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import clone from "lodash/clone";
    import { required } from "vuelidate/lib/validators";
    // @note: no validation is currently applied, this is just future-proofing
    import ValidationMessages from "Components/common/ValidationMessages";

    import log from "src/services/logger";
    const logTag = "TestTextAnswer";

    export default {
        name : "TestTextAnswer",

        components : { ValidationMessages },

        props : {
            item : { type : Object, default : null },
            currentAnswer : { type : String, default : "" }
        },

        // we need this to set the initial pre-filled value for this answer (if the user already answered)
        mounted : function() {
            this.answer = clone(this.currentAnswer);
        },

        watch : {
            // we need this to update the pre-filled value for this specific question. For example, when
            // there are two consecutive answers of type Text, the component is not mounted again, only 
            // re-used.
            // @note 6/24: do not watch for currentAnswer, as when two answers have the same currentAnswer, 
            // the watcher won't be triggered
            item() {
                this.answer = clone(this.currentAnswer);
                this.$v.answer.$reset();
            }
        },

        data() {
            return {
                answer : ""
            };
        },

        validations() {
            return {
                answer : { required }
            };
        },

        methods : {
            submit() {
                log.log(logTag, "Answer button pressed");
                // @note: we do not $touch the field on @input, only on submit! better UE
                this.$v.$touch();

                // @note there's actually no real validation as the button is simply disabled
                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, answering");
                    // just send the data to parent element
                    this.$emit("answer", this.answer);
                }
            },
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
