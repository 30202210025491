var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel panel-default" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _vm._v(_vm._s(_vm._f("translate")("CHANGE_PASS_TITLE"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.sendRequest.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-xs-12 form-group",
                class: { "has-error": _vm.$v.old_password.$error },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "input-label",
                    attrs: { for: "old_password" },
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("OLD_PASSWORD")))]
                ),
                _vm._v(" "),
                _c("vue-password", {
                  attrs: {
                    name: "old_password",
                    id: "old_password",
                    classes: "form-control",
                    disableStrength: "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.old_password.$touch()
                    },
                  },
                  model: {
                    value: _vm.old_password,
                    callback: function ($$v) {
                      _vm.old_password = $$v
                    },
                    expression: "old_password",
                  },
                }),
                _vm._v(" "),
                _c("validation-messages", {
                  attrs: {
                    field: _vm.$v.old_password,
                    "field-name": "old_password",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-xs-12 form-group",
                class: { "has-error": _vm.$v.new_password.$error },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "input-label",
                    attrs: { for: "new_password" },
                  },
                  [_vm._v(_vm._s(_vm._f("translate")("NEW_PASSWORD")))]
                ),
                _vm._v(" "),
                _c("vue-password", {
                  attrs: {
                    name: "new_password",
                    id: "new_password",
                    classes: "form-control",
                    disableStrength: "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.new_password.$touch()
                    },
                  },
                  model: {
                    value: _vm.new_password,
                    callback: function ($$v) {
                      _vm.new_password = $$v
                    },
                    expression: "new_password",
                  },
                }),
                _vm._v(" "),
                _c("validation-messages", {
                  attrs: {
                    field: _vm.$v.new_password,
                    "field-name": "new_password",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-12 submit-btn-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "btn brand-secondary-btn",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm._f("translate")("CHANGE_PASS_SUBMIT")) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }