var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "battles" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row main-title-row" }, [
          _c("div", { staticClass: "bg-img img-responsive" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-sm-6 col-sm-offset-6 col-md-5 col-md-offset-7 main-title-col",
            },
            [
              _c("h1", [
                _vm._v(
                  _vm._s(_vm.currentAreaDetails.battles_main_title_first_row)
                ),
              ]),
              _vm._v(" "),
              _c("h1", [
                _vm._v(
                  _vm._s(_vm.currentAreaDetails.battles_main_title_second_row)
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "main-subtitle" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.currentAreaDetails.battles_main_subtitle) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn primary-btn cta-btn",
                  on: {
                    click: function ($event) {
                      return _vm.ctaButtonClick()
                    },
                  },
                },
                [
                  _vm.battles.currentState === _vm.battles.states.NO_BATTLE
                    ? _c("span", [
                        _vm._v(
                          "\n                           " +
                            _vm._s(
                              _vm.currentAreaDetails.battles_ctoa_button_title
                            ) +
                            "\n                        "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("translate")("BATTLES_CONTINUE_BATTLE")
                            ) +
                            "\n                        "
                        ),
                      ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", [
          _vm._v(_vm._s(_vm.currentAreaDetails.battles_instructions_title)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 col-md-4 instruction first-instruction" },
            [
              _c("div", { staticClass: "bg-img img-responsive" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "instruction-wrapper",
                  attrs: { "data-key": "sameHeightInstructions" },
                },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.currentAreaDetails.battles_instructions_first_title
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.currentAreaDetails.battles_instructions_first_text
                      )
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6 col-md-4 instruction second-instruction" },
            [
              _c("div", { staticClass: "bg-img img-responsive" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "instruction-wrapper",
                  attrs: { "data-key": "sameHeightInstructions" },
                },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.currentAreaDetails.battles_instructions_second_title
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.currentAreaDetails.battles_instructions_second_text
                      )
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6 col-md-4 instruction third-instruction" },
            [
              _c("div", { staticClass: "bg-img img-responsive" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "instruction-wrapper",
                  attrs: { "data-key": "sameHeightInstructions" },
                },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.currentAreaDetails.battles_instructions_third_title
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.currentAreaDetails.battles_instructions_third_text
                      )
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-3", attrs: { id: "arena-area" } }, [
      _c("h2", [_vm._v(_vm._s(_vm._f("translate")("BATTLES_ARENA")))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "part-4" },
      [
        _c(
          "transition",
          { attrs: { name: "component-fade", mode: "out-in" } },
          [_c(_vm.currentArenaComponent, { tag: "component" })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }