<template>
    <div id="leaderboard" class="page">
        <div class="part-1">
            <div class="container">

                <h1>{{ 'LEADERBOARD_MAIN_TITLE' | translate }}</h1>
                <!--<h3>{{ 'LEADERBOARD_MAIN_SUBTITLE' | translate }}:</h3>-->

                <div class="row under-title">
                    <div class="col-xs-12 col-sm-5 col-lg-4 rank-section">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <div class="bg-img img-responsive"></div>
                                <h2>{{ 'LEADERBOARD_YOUR_RANK' | translate }}:</h2>
                                <h2 class="rank" v-if="currentProgress">
                                    #{{ currentProgress.rank }}
                                </h2>

                                <p class="note">{{ 'LEADERBOARD_EXPLANATION_TEXT' | translate }}</p>
                                <a href="/#/item-dashboard" class="btn primary-btn">
                                    {{ 'CONTINUE_PLAYING' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-7 col-lg-8 ladder-section">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <h2>{{ 'LEADERBOARD_OF_TEAM_PLAYERS' | translate }}</h2>
                                <div class="table-responsive">
                                    <table class="table leaderboard-table table-striped"
                                           v-if="currentTeamUsersLeaderboard"
                                    >
                                        <tbody>
                                            <tr v-for="player in currentTeamUsersLeaderboard"
                                                :class="user.id === player.id ? 'user-row' : ''"
                                            >
                                                <td>{{ player.position }}</td>
                                                <td>{{ player.name }}</td>
                                                <td>{{ player.score }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                <h2>{{ 'LEADERBOARD_OF_AREA_TEAMS' | translate }}</h2>
                                <div class="table-responsive">
                                    <table class="table leaderboard-table table-striped"
                                           v-if="currentAreaTeamsLeaderboard"
                                    >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th class="text-right">{{ 'LEADERBOARD_TEAMS_SCORE' | translate }}</th>
                                                <th class="text-right">{{ 'LEADERBOARD_TEAMS_SCORE_PER_USER' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="team in currentAreaTeamsLeaderboard"
                                                :class="user.team.id === team.id ? 'user-row' : ''"
                                            >
                                                <td>{{ team.position }}</td>
                                                <td>{{ team.name }}</td>
                                                <td class="text-right">{{ team.score }}</td>
                                                <td>{{ team.score_per_user }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                <h2>{{ 'LEADERBOARD_OF_ALL_PLAYERS' | translate }}</h2>
                                <div class="table-responsive">
                                    <table class="table leaderboard-table table-striped"
                                           v-if="currentAllUsersLeaderboard"
                                    >
                                        <tbody>
                                            <tr v-if="currentProgress && currentProgress.rank != 1">
                                                <td></td>
                                                <td>...</td>
                                                <td></td>
                                            </tr>
                                            <tr v-for="player in currentAllUsersLeaderboard"
                                                :class="player.id === user.id ? 'user-row' : ''"
                                            >
                                                <td>{{ player.position }}</td>
                                                <td>{{ player.name }}</td>
                                                <td>{{ player.score }}</td>
                                            </tr>

                                            <tr v-if="currentProgress && currentProgress.score != 0">
                                                <td></td>
                                                <td>...</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                <h2>{{ 'LEADERBOARD_OF_BATTLES_IN_AREA' | translate }}</h2>
                                <div class="table-responsive">
                                    <table class="table leaderboard-table table-striped"
                                           v-if="currentAreaBattlesLeaderboard"
                                    >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th class="text-center">{{ 'LEADERBOARD_WINS' | translate }}</th>
                                                <th class="text-center">{{ 'LEADERBOARD_TIES' | translate }}</th>
                                                <th class="text-center">{{ 'LEADERBOARD_LOSSES' | translate }}</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr v-for="player in currentAreaBattlesLeaderboard"
                                                :class="user.id === player.id ? 'user-row' : ''"
                                            >
                                                <td>{{ player.position }}</td>
                                                <td>{{ player.name }}</td>
                                                <td class="text-center">{{ player.wins }}</td>
                                                <td class="text-center">{{ player.ties }}</td>
                                                <td class="text-center">{{ player.losses }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                <h2>{{ 'LEADERBOARD_OF_BATTLES_IN_TEAM' | translate }}</h2>
                                <div class="table-responsive">
                                    <table class="table leaderboard-table table-striped"
                                           v-if="currentTeamBattlesLeaderboard"
                                    >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th class="text-center">{{ 'LEADERBOARD_WINS' | translate }}</th>
                                                <th class="text-center">{{ 'LEADERBOARD_TIES' | translate }}</th>
                                                <th class="text-center">{{ 'LEADERBOARD_LOSSES' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="player in currentTeamBattlesLeaderboard"
                                            :class="user.id === player.id ? 'user-row' : ''"
                                        >
                                            <td>{{ player.position }}</td>
                                            <td>{{ player.name }}</td>
                                            <td class="text-center">{{ player.wins }}</td>
                                            <td class="text-center">{{ player.ties }}</td>
                                            <td class="text-center">{{ player.losses }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        created : function() {
            // @note: these don't have to be reported to Sentry as we handle that in httpInterceptors, so
            // we can use dummy catch
            this.$store.dispatch("downloadTeamUsersLeaderboard").catch(() => {});
            this.$store.dispatch("downloadAreaTeamsLeaderboard").catch(() => {});
            this.$store.dispatch("downloadAllUsersLeaderboard").catch(() => {});
            this.$store.dispatch("downloadAreaBattlesLeaderboard").catch(() => {});
            this.$store.dispatch("downloadTeamBattlesLeaderboard").catch(() => {});
        },

        computed : mapGetters([
            "user", "currentTeamUsersLeaderboard", "currentAreaTeamsLeaderboard", "currentAllUsersLeaderboard",
            "currentAreaBattlesLeaderboard", "currentTeamBattlesLeaderboard", "currentProgress"
        ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>