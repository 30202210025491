var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-progress" }, [
    _c("div", { staticClass: "row metric-row" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-3" }, [
        _c("div", { staticClass: "row vertical-align" }, [
          _c("div", { staticClass: "metric progress-level" }, [
            _c("div", { staticClass: "col-xs-4 img-col" }, [
              _vm.stats.level
                ? _c("div", {
                    staticClass: "bg-img img-responsive",
                    style: {
                      backgroundImage:
                        "url('" + _vm.stats.level.image_path + "')",
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-8" }, [
              _c("div", { staticClass: "row metric-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("PROGRESS_YOUR_LEVEL"))),
              ]),
              _vm._v(" "),
              _vm.stats.level
                ? _c("div", { staticClass: "row metric-value" }, [
                    _vm._v(_vm._s(_vm.stats.level.name)),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-3" }, [
        _c("div", { staticClass: "row vertical-align" }, [
          _c("div", { staticClass: "metric progress-items" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-8" }, [
              _c("div", { staticClass: "row metric-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("PROGRESS_ANSWERED_ITEMS"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row metric-value" }, [
                _vm._v(_vm._s(_vm.stats.answered_items_count)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-3" }, [
        _c("div", { staticClass: "row vertical-align" }, [
          _c("div", { staticClass: "metric progress-rank" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-8" }, [
              _c("div", { staticClass: "row metric-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("PROGRESS_YOUR_RANK"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row metric-value" }, [
                _vm._v(
                  "#" +
                    _vm._s(_vm.stats.rank) +
                    "\n                            "
                ),
                _vm.showLeaderboardLink
                  ? _c(
                      "span",
                      { staticClass: "leaderboard-link-wrapper" },
                      [
                        _vm._v("\n                                ("),
                        _c(
                          "router-link",
                          {
                            staticClass: "leaderboard-link",
                            attrs: { tag: "a", to: "/leaderboard" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("translate")("TO_LEADERBOARD"))
                            ),
                          ]
                        ),
                        _vm._v(")\n                            "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-3" }, [
        _c("div", { staticClass: "row vertical-align" }, [
          _c("div", { staticClass: "metric progress-battles" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-8" }, [
              _c("div", { staticClass: "row metric-title" }, [
                _vm._v(_vm._s(_vm._f("translate")("PROGRESS_YOUR_BATTLES"))),
              ]),
              _vm._v(" "),
              _vm.stats.battles
                ? _c("div", { staticClass: "row metric-value" }, [
                    _c("span", { staticClass: "battles-won" }, [
                      _vm._v(_vm._s(_vm.stats.battles.won)),
                    ]),
                    _vm._v("\n                            / "),
                    _c("span", { staticClass: "battles-lost" }, [
                      _vm._v(_vm._s(_vm.stats.battles.lost)),
                    ]),
                    _vm._v("\n                            / "),
                    _c("span", { staticClass: "battles-tie" }, [
                      _vm._v(_vm._s(_vm.stats.battles.tie)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.showProgressbar
      ? _c("div", { staticClass: "progressbar-section" }, [
          _c("p", { staticClass: "progressbar-title" }, [
            _vm._v(_vm._s(_vm._f("translate")("PROGRESSBAR_TITLE"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row level-names" }, [
            _vm.stats.level
              ? _c("div", { staticClass: "col-xs-6 level-current" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.stats.level.name) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.level
              ? _c("div", { staticClass: "col-xs-6 level-next" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.stats.level.next_name) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "progress",
              class: { "progress-full": _vm.progressbarValue >= 100 },
            },
            [
              _c("progressbar", {
                attrs: {
                  now: _vm.progressbarValue,
                  type: "success",
                  striped: "",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-4 img-col" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-4 img-col" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-4 img-col" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }