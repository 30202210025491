import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";

import * as types from "./mutationTypes";

// import all other store modules
import auth from "./authStore";
import user from "./userStore";
import data from "./dataStore";
import battles from "./battlesStore";
import knowledgeTest from "./knowledgeTestStore";

import authenticationService from "src/services/api/authenticationService";
import notificationService from "src/services/notificationService";
import log from "src/services/logger";
const logTag = "store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const storeState = {
    isLoaderShown           : false,

    errorMessage            : "",
    successMessage          : "",

    errorPageContent        : "",

    currentLoginComponent   : "LoginForm"
};

const storeGetters = {
    route                   : state => state.route,
    isLoaderShown           : state => state.isLoaderShown,

    emailParam              : state => state.route.params.email,
    tokenParam              : state => state.route.params.token,
    componentParam          : state => state.route.params.component,

    errorMessage            : state => state.errorMessage,
    isErrorMessageShown     : state => state.errorMessage.length > 0,
    successMessage          : state => state.successMessage,
    isSuccessMessageShown   : state => state.successMessage.length > 0,

    errorPageContent        : state => state.errorPageContent,

    currentLoginComponent   : state => state.currentLoginComponent
};

const actions = {
    // all the init stuff of services etc
    initialize() {
        log.log(logTag, "Initialize");

        // get the permission to show "desktop" notifications
        notificationService.acquirePermission()
            .catch(() => console.error("Please check the permissions for Notifications and allow them."));

        // log in automatically, get user's data
        // @note: as of Area Chooser feature 3/23, we wait for init and afterLogin to finish
        return authenticationService.init().then(token => store.dispatch("afterLogin", token))
            // ¯\_(ツ)_/¯
            .catch(() => {});
    },

    // showing the loader (spinner) when loading
    toggleLoader({ commit }, showLoader) {
        commit(types.TOGGLE_IS_LOADER_SHOWN, showLoader);
    },

    // setting the global success and error messages/alerts
    showSuccessMessage({ commit }, message) {
        commit(types.SET_SUCCESS_MESSAGE, message);
        setTimeout(function() { commit(types.SET_SUCCESS_MESSAGE, ""); }, 4000);
    },

    showErrorMessage({ commit }, message) {
        commit(types.SET_ERROR_MESSAGE, message);
        setTimeout(function() { commit(types.SET_ERROR_MESSAGE, ""); }, 4000);
    },

    // error page content - a separate page, NOT the same thing as error message
    setErrorPageContent({ commit }, content) {
        commit(types.SET_ERROR_PAGE_CONTENT, content);
    },

    // polling server for new notifications
    startNotificationPolling() {
        return notificationService.startNotificationPolling();
    },
    stopNotificationPolling() {
        return notificationService.stopNotificationPolling();
    },

    // switch login components on Login page (first page)
    changeLoginComponent({ commit }, newComponent) {
        commit(types.SET_CURRENT_LOGIN_COMPONENT, newComponent);
    }
};

const mutations = {
    // loader
    [types.TOGGLE_IS_LOADER_SHOWN](state, showLoader) {
        state.isLoaderShown = showLoader;
    },
    // success and error message (notification, alert)
    [types.SET_ERROR_MESSAGE](state, message) {
        state.errorMessage = message;
    },
    [types.SET_SUCCESS_MESSAGE](state, message) {
        state.successMessage = message;
    },
    // error page content - a separate page, NOT the same thing as error message
    [types.SET_ERROR_PAGE_CONTENT](state, content) {
        state.errorPageContent = content;
    },
    // switch login components on Login page (first page)
    [types.SET_CURRENT_LOGIN_COMPONENT](state, newComponent) {
        state.currentLoginComponent = newComponent;
    }
};

const store = new Vuex.Store({
    state : storeState,
    getters : storeGetters,
    actions,
    mutations,
    modules : { auth, user, data, battles, knowledgeTest },
    strict : debug
});

export default store;
