import cloneDeep from "lodash/cloneDeep";

let testItemId = 1;
let testItems = [];
let testTimeRemaining = 1150;
let testItemsCount = 5;
let currentAttemptId = null;

const randomInteger = function(min, max, previousValues) {
    let newValue = Math.floor(Math.random() * (max + 1 - min)) + min;

    if (previousValues && previousValues.indexOf(newValue) !== -1) {
        return randomInteger(min, max, previousValues);
    } else {
        return newValue;
    }
};

const generateItem = function() {
    // console.log("Generating new item");
    // return generateTextItem();

    const random = randomInteger(0, 2);
    if (random === 0) {
        return generateTextItem();
    } else if (random === 1) {
        return generateChoiceItem();
    }
    
    // random === 2
    return generateAnytextItem();

    // let textVsChoice = randomInteger(0, 1);
    // return textVsChoice === 0 ? generateTextItem() : generateChoiceItem();
};

const generateChoiceItem = function() {
    let numberOfChoices = randomInteger(2, 4);
    let choices = [];
    let choice = 1;
    for (choice; choice <= numberOfChoices; choice++) {
        choices.push({
            id      : choice,
            text    : `Item ${testItemId} Option ${choice} text asd asd sad asd asd sad gsd sgsddssddf sdf sdfdsds fds  sdfdsf fdsfds sdffdssdf`
        });
    }

    let numberOfCorrectAnswers = randomInteger(1, numberOfChoices);
    let correctAnswer = [];
    for (let answer = 1; answer <= numberOfCorrectAnswers; answer++) {
        correctAnswer.push(randomInteger(1, numberOfChoices, correctAnswer));
    }

    let newItem = generateItemBody(testItemId, "choice", cloneDeep(correctAnswer), { choices });
    testItemId++;
    return newItem;
};

const generateTextItem = function() {
    let answerLength = randomInteger(2, 8);
    let correctAnswer = "";
    for (let i = 0; i < answerLength; i++) {
        correctAnswer += "a";
    }

    let newItem = generateItemBody(testItemId, "text", cloneDeep(correctAnswer), undefined);
    testItemId++;
    return newItem;
};

const generateAnytextItem = function() {
    let answerLength = randomInteger(2, 8);
    let correctAnswer = "";
    for (let i = 0; i < answerLength; i++) {
        correctAnswer += "a";
    }

    let newItem = generateItemBody(testItemId, "any_text", cloneDeep(correctAnswer), undefined);
    testItemId++;
    return newItem;
};

const generateItemBody = function(itemId, type, correctAnswer, options) {
    const random = randomInteger(0, 2);
    let isCorrect = false;
    let image_path = null;
    let audio_path = null;
    if (random === 2) {
        isCorrect = null;

        // different image sizes
        image_path = "https://picsum.photos/785/502";
        // image_path = "https://picsum.photos/752/247";
        // image_path = "https://picsum.photos/110/64";
        // image_path = "https://picsum.photos/179/91";
        // image_path = "https://picsum.photos/3500/1900";

        // this doesn't work because of CORS:
        audio_path = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";

    } else if (random === 1) {
        isCorrect = true;
    }

    const newItem = {
        id              : itemId,
        name            : `Item ${itemId} Lorem Ipsum: ${JSON.stringify(correctAnswer)}`,
        description     : `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
            et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
            voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
            ratione ${itemId}`,
        type            : type,
        options         : options,
        points          : 100,
        image_path      : image_path,
        audio_path      : audio_path,

        // this is only relevant after the attempt has been finished
        answer          : correctAnswer,
        // answer          : "",
        is_correct      : isCorrect,
        correct_answer  : correctAnswer
    };
    testItems.push(newItem);
    return newItem;
};


const generateTestItems = function(numberOfItems) {
    // rest previous attempt
    testItems = [];
    testTimeRemaining = 1150;
    currentAttemptId = null;

    let items = [];
    for (let i = 0; i < numberOfItems; i++) {
        items.push(generateItem());
    }
    return items;
};


const getTestAttemptInfo = function() {
    return Promise.resolve({
        status : 200,
        data : {
            items           : generateTestItems(testItemsCount),
            time_remaining  : testTimeRemaining
        }
    });
    // return Promise.reject({ status : 403 });
    // return Promise.reject({ status : 404 });
    // return Promise.reject({ status : 500 });
};

const getKnowledgeTests = function() {
    console.log(currentAttemptId);

    return Promise.resolve({
        status : 200,
        data : {
            current_tests            : [
                {
                    id                  : 5,
                    title               : "Knowledge Test 1",
                    description         : "Just do it!",
                    items_count         : testItemsCount,
                    open_from           : "1601382880",
                    open_from_formatted : "29.09.",
                    open_to             : "1606780800",
                    open_to_formatted   : "01.12.",
                    closing_soon        : false,
                    time_limit_seconds  : 1200,
                    attempts_allowance  : 3,
                    success_points_threshold    : 500,
                    is_video_active     : true,
                    is_video_watched    : false,
                    video_name          : "Omnis modi id.",
                    video_description   : "Nihil laboriosam atque voluptas repellat voluptas id doloribus. Eum eum ut illum ut velit. Veniam odit laboriosam dolor enim. Qui quas expedita voluptatem.",
                    video_mp4           : "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4",
                    video_webm          : "http://www.innogy-fo.local/test-files/test.webm",
                    // if this is present, video is ignored and attachment is used instead
                    // attachment_exe      : "http://www.innogy-fo.local/test-files/test.webm",
                    attempts       : [
                        { id : 3, result : false, percentage : 80, attempt_points : 800, test_points : 1000 },
                        { id : 2, result : true, percentage : 80, attempt_points : 800, test_points : 1000 },
                    ]
                },
                {
                    id                  : 6,
                    title               : "Knowledge Test 2",
                    description         : "Just do it again!",
                    items_count         : testItemsCount,
                    open_from           : "1572945422",
                    open_from_formatted : "05.11.",
                    open_to             : "1606680800",
                    open_to_formatted   : "29.11.",
                    closing_soon        : false,
                    time_limit_seconds  : 1200,
                    attempts_allowance  : 3,
                    success_points_threshold    : 500,
                    attempts       : [
                        { id : 9, result : false, percentage : 80, attempt_points : 800, test_points : 1000 },
                        { id : 8, result : true, percentage : 80, attempt_points : 800, test_points : 1000 },
                    ]
                },
            ],

            // current_attempt     : currentAttemptId,
            // current_attempt     : null,
            current_attempt     : {
                test_id     : 5,
                attempt_id  : 10
                // attempt_id  : currentAttemptId
            }
        }
    });
    // return Promise.resolve({ status : 200, data : { current_tests : null } });
    // return Promise.reject({ status : 500 });
};

const startTestAttempt = function(testId, attemptId) {
    currentAttemptId = attemptId ? attemptId : 5;
    return Promise.resolve({ status : 200, data : { attempt_id : attemptId ? attemptId : 5 } });
    // return Promise.reject({ status : 403 });
    // return Promise.reject({ status : 404 });
    // return Promise.reject({ status : 409 });
    // return Promise.reject({ status : 410 });
    // return Promise.reject({ status : 412 });
    // return Promise.reject({ status : 424 });
    // return Promise.reject({ status : 500 });
};

const getTestSyncTime = function() {
    testTimeRemaining -= 10;
    return Promise.resolve({ status : 200, data : { time_remaining : testTimeRemaining } });
    // return Promise.reject({ status : 403 });
    // return Promise.reject({ status : 404 });
    // return Promise.reject({ status : 410 });
    // return Promise.reject({ status : 500 });
};

const finishTestAttempt = function() {
    // @todo: change testItems
    return Promise.resolve({ status : 200, data : { items : cloneDeep(testItems) } });
    // return Promise.reject({ status : 403 });
    // return Promise.reject({ status : 404 });
    // return Promise.reject({ status : 410 });
    // return Promise.reject({ status : 424 });
    // return Promise.reject({ status : 500 });
};

const postTestAnswer = function() {
    return Promise.resolve({ status : 200 });
    // return Promise.reject({ status : 404 });
    // return Promise.reject({ status : 410 });
    // return Promise.reject({ status : 422 });
    // return Promise.reject({ status : 424 });
    // return Promise.reject({ status : 500 });
};

const postTestVideoWatched = function() {
    return Promise.resolve({ status : 200 });
    // return Promise.reject({ status : 404 });
    // return Promise.reject({ status : 410 });
    // return Promise.reject({ status : 422 });
    // return Promise.reject({ status : 424 });
    // return Promise.reject({ status : 500 });
};


export default {
    getKnowledgeTests,
    startTestAttempt,
    getTestAttemptInfo,
    getTestSyncTime,
    finishTestAttempt,
    postTestAnswer,
    postTestVideoWatched
};
