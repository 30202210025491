<template>
    <div id="waiting-for-opponent">
        <div class="bg-img img-responsive"></div>

        <div class="container">
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                    <h2>
                        {{ $t("BATTLES_WAITING_TITLE", { variable : battles.opponent }) }}
                    </h2>

                    <p>{{ 'BATTLES_WAITING_TEXT_1' | translate }}</p>
                    <p>
                        {{ 'BATTLES_WAITING_TEXT_2' | translate }}
                        <strong>{{ 'BATTLES_WAITING_24H' | translate }}.</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "battles" ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>