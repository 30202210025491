var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "battle-in-progress" } },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm._f("translate")("BATTLES_IN_PROGRESS_TITLE"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", attrs: { id: "header-row" } }, [
        _c("div", { staticClass: "col-sm-4 col-sm-push-4" }, [
          _c("p", [
            _vm._v(_vm._s(_vm._f("translate")("BATTLES_IN_PROGRESS_SUBTITLE"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4 col-sm-pull-4 name-user" }, [
          _c("h4", [_vm._v(_vm._s(_vm.user.name))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4 name-opponent" }, [
          _c("h4", [_vm._v(_vm._s(_vm.battles.opponent))]),
        ]),
      ]),
      _vm._v(" "),
      _c("battle-progress"),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [
          _c(
            _vm.allBattleQuestionsAnswered
              ? "BattleWaitingForFinish"
              : "BattleQuestion",
            { tag: "component" }
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }