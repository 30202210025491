var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "battle-waiting-for-finish" } }, [
    _c("div", { staticClass: "bg-img img-responsive" }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 col-md-offset-3" }, [
        _c("h2", [
          _vm._v(
            _vm._s(_vm.currentAreaDetails.battles_waiting_for_finish_title)
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            _vm._s(_vm.currentAreaDetails.battles_waiting_for_finish_text)
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }