<template>
    <div id="login" class="page">
        <div class="part-1">
            <div class="container illustration">
                <h1>{{ 'LOGIN_MAIN_TITLE' | translate }}</h1>
                <h2>{{ 'LOGIN_MAIN_SUBTITLE' | translate }}</h2>
                <!--<div class="bg-img"></div>-->
            </div>
        </div>

        <div class="part-2">
            <div class="container">
                <h2>{{ 'LOGIN_SECONDARY_TITLE' | translate }}</h2>
                <div class="subheading">{{ 'LOGIN_SECONDARY_SUBTITLE' | translate }}</div>
                <div class="row">
                    <div class="col-xs-12 col-md-4">
                        <div class="thumbnail">
                            <img src="~variantImages/login_explanation_first.png"
                                 class="img img-responsive"
                            >
                            <div class="caption">
                                <h3>{{ 'LOGIN_EXPLANATION_FIRST_TITLE' | translate }}</h3>
                                <p>{{ 'LOGIN_EXPLANATION_FIRST_TEXT' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                        <div class="thumbnail">
                            <img src="~variantImages/login_explanation_second.png"
                                 class="img img-responsive"
                            >
                            <div class="caption">
                                <h3>{{ 'LOGIN_EXPLANATION_SECOND_TITLE' | translate }}</h3>
                                <p>{{ 'LOGIN_EXPLANATION_SECOND_TEXT' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                        <div class="thumbnail">
                            <img src="~variantImages/login_explanation_third.png"
                                 class="img img-responsive"
                            >
                            <div class="caption">
                                <h3>{{ 'LOGIN_EXPLANATION_THIRD_TITLE' | translate }}</h3>
                                <p>{{ 'LOGIN_EXPLANATION_THIRD_TEXT' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-3" id="user-auth-area">
            <div class="container">
                <div class="row login-part">
                    <h2>{{ 'LOGIN_SECTION_TITLE' | translate }}</h2>
                    <h3>{{ 'LOGIN_SECTION_SUBTITLE' | translate }}</h3>

                    <div v-if="ssoEnabled" class="sso-btn-wrapper">
                        <button class="btn brand-primary-btn"
                                @click="ssoLogin"
                        >
                            {{ 'LOGIN' | translate }}
                        </button>
                    </div>

                    <div v-else
                         class="col-xs-12 col-md-6 col-md-offset-3"
                    >
                        <div class="panel panel-default">
                            <div class="panel-heading">{{ panelTitle }}</div>

                            <div class="panel-body">

                                <!-- switching between Login, Registration and ForgotenPass forms -->
                                <keep-alive>
                                    <component :is="currentLoginComponent"
                                               @created="changePanelTitle"
                                    ></component>
                                </keep-alive>

                                <div class="forgotten-pass-link-wrapper"
                                     v-if="currentLoginComponent === 'LoginForm'"
                                >
                                    <a @click="changeLoginComponent('ForgottenPassForm')">
                                        {{ 'FORGOTTEN_PASSWORD' | translate }}
                                    </a>
                                </div>
                            </div>

                            <div class="panel-footer">
                                <a @click="changeLoginComponent('RegistrationForm')"
                                   v-if="currentLoginComponent === 'LoginForm'"
                                >
                                    {{ "REGISTER" | translate }}
                                </a>

                                <button class="btn brand-secondary-btn"
                                        @click="changeLoginComponent('LoginForm')"
                                        v-if="!(currentLoginComponent === 'LoginForm')"
                                >
                                    {{ "BACK" | translate }}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    import LoginForm from "./LoginForm";
    import RegistrationForm from "./RegistrationForm";
    import ForgottenPassForm from "./ForgottenPassForm";
    import log from 'src/services/logger';
    const logTag = "Login";

    export default {
        components : { LoginForm, RegistrationForm, ForgottenPassForm },


        mounted() {
            // If the navbar's Login or Register links are pressed while user is on this page, the mounted()
            // will not be called and the navbar will change the component and scroll
            // If the user is not on this page, navbar's links will redirect here and we'll change the component
            // and scroll here

            if (this.componentParam) {
                switch (this.componentParam) {
                    case "login" :
                    default :
                        this.$store.dispatch("changeLoginComponent", "LoginForm");
                        break;
                    case "forgotten" :
                        this.$store.dispatch("changeLoginComponent", "ForgottenPassForm");
                        break;
                    case "registration" :
                        this.$store.dispatch("changeLoginComponent", "RegistrationForm");
                        break;
                }

                setTimeout(() => {
                    this.$scrollTo("#user-auth-area", 400, {});
                }, 500);
            }
        },

        // we get currentLoginComponent from store so that even links in navbar can switch login/registration forms
        computed : mapGetters([ "currentLoginComponent", "componentParam" ]),

        data() {
            return {
                panelTitle  : this.$t("LOGIN_TITLE"),
                ssoEnabled  : config.features.ssoLogin
            };
        },

        created : function() {},

        methods : {
            changePanelTitle(title) { this.panelTitle = title; },

            changeLoginComponent(newComponent) {
                this.$store.dispatch("changeLoginComponent", newComponent);
            },

            ssoLogin() {
                log.log(logTag, "SSO login button pressed");
                // We just redirect the user to SSO url, which will handle logging in and will return to
                // this app by calling /token/:token router. App's router then handles the rest.
                window.location.href = config.api.base + config.sso.url;
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
