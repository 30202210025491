import apiService from 'src/services/api/apiService';
import log from 'src/services/logger';
const logTag = "authenticationService";

const init = function() {
    let token = localStorage.getItem('authorizationToken');
    return apiService.setAuthTokenToHeaders(token);
};

const login = function({ email, password }) {
    return apiService.login({ email, password }).then(handleReceivedToken);
};
const ssoLogin = function({ token }) {
    return apiService.ssoLogin({ token }).then(handleReceivedToken);
};
const register = function(registrationData) {
    // @note: a useless wrapper?
    return apiService.register(registrationData);
};
const activate = function(activationData) {
    return apiService.activate(activationData).then(handleReceivedToken);
};
const resetForgottenPass = function({ email, password, token }) {
    return apiService.resetForgottenPass({ email, password, token }).then(handleReceivedToken);
};
const changePass = function(passwordData) {
    return apiService.changePass(passwordData).then(handleReceivedToken);
};

// just a DRY helper
const handleReceivedToken = function(response) {
    localStorage.setItem('authorizationToken', response.data.token);
    apiService.setAuthTokenToHeaders(response.data.token);
    return response;
};

const handleTokenRemoval = function() {
    apiService.unsetAuthTokenFromHeaders();
    localStorage.removeItem('authorizationToken');
};

const logout = function() {
    // @note we don't propagate the error
    return apiService.logout()
    .catch((reason) => {
        log.log(logTag, "Logout failed");
        log.log(logTag, reason);
        return reason;
    });
};

// hash function from here:
// https://stackoverflow.com/a/52171480
const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const checkIfUserWasOnboardedAndStore = function(email) {
    // we're using hashes instead of naked emails
    const emailHash = cyrb53(email);

    let onboardedUsers = JSON.parse(localStorage.getItem("onboardedUsers")) || {};

    if (!onboardedUsers || !onboardedUsers[emailHash]) {
        onboardedUsers[emailHash] = true;
        localStorage.setItem("onboardedUsers", JSON.stringify(onboardedUsers));
        return false;

    } else {
        return true;
    }
};

export default {
    init,

    login,
    ssoLogin,
    logout,

    handleTokenRemoval,

    register,
    activate,
    resetForgottenPass,
    changePass,

    checkIfUserWasOnboardedAndStore

};
