var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "waiting-for-opponent" } }, [
    _c("div", { staticClass: "bg-img img-responsive" }),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 col-md-offset-3" }, [
          _c("h2", [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.$t("BATTLES_WAITING_TITLE", {
                    variable: _vm.battles.opponent,
                  })
                ) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm._f("translate")("BATTLES_WAITING_TEXT_1"))),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    " +
                _vm._s(_vm._f("translate")("BATTLES_WAITING_TEXT_2")) +
                "\n                    "
            ),
            _c("strong", [
              _vm._v(_vm._s(_vm._f("translate")("BATTLES_WAITING_24H")) + "."),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }