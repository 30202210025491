import get from "lodash/get";
import has from "lodash/has";

import * as types from "./mutationTypes";
import apiService from "src/services/api/apiService";

import log from "src/services/logger";
const logTag = "userStore";

const moduleState = {
    id                  : 0,
    name                : "",
    progress            : {},

    current_area_id     : 0,
};

const moduleGetters = {
    user                    : state => state,
    team                    : state => state.team,

    currentAreaId           : state => state.current_area_id,

    allProgress             : state => state.progress,
    currentProgress         : (state, getters) => state.progress[getters.currentAreaId],
    // calculates the percentage progress to next level, shown in progressbar in user progress
    areaLevelProgress       : (_, getters) => areaId => {
        let stats = getters.allProgress[areaId];
        if (!has(stats, "level.min_score")) {
            return 0;
        } else if (stats.level.next_score === null) {
            return 100;
        } else {
            return Math.round((stats.score - stats.level.min_score) / (stats.level.next_score - stats.level.min_score) * 100);
        }
    },
    currentAreaLevelProgress : (_, getters) => getters.areaLevelProgress(getters.currentAreaId),

    currentUnlockedAchievementsIds : (state, getters) => get(getters.currentProgress, "achievements.achieved_ids", [])
};

const actions = {
    downloadUserData({ commit }) {
        return apiService.getUserData().then((userData) => {
            commit(types.SET_USER_DATA, userData.data);

            // originally, we used user.team.area_id as the area_id. After Multiple areas feature (3/23), we 
            // now use user.preferred_area_id
            log.log(logTag, `Using preferred area of the user: ${userData.data.preferred_area_id}`);
            commit(types.SET_CURRENT_AREA, userData.data.preferred_area_id);
            apiService.setAreaIdToHeaders(userData.data.preferred_area_id);
        });
    },

    changePreferredArea({ commit }, areaId) {
        return apiService.postPreferredArea(areaId).then(() => {
            commit(types.SET_CURRENT_AREA, areaId);
            apiService.setAreaIdToHeaders(areaId);
        });
    }
};

const mutations = {
    [types.SET_USER_DATA](state, { id, name, progress, team }) {
        state.id = id;
        state.name = name;
        state.progress = progress;
        state.team = team;
    },

    [types.SET_CURRENT_AREA](state, areaId) {
        state.current_area_id = areaId;
    }
};

export default {
    state : moduleState,
    getters : moduleGetters,
    actions,
    mutations
};
