var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isSuccessMessageShown
      ? _c(
          "div",
          { staticClass: "alert alert-success", attrs: { role: "alert" } },
          [_vm._v(_vm._s(_vm.successMessage))]
        )
      : _c(
          "form",
          {
            staticClass: "login-form",
            attrs: { novalidate: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.sendRequest.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 form-group",
                  class: { "has-error": _vm.$v.formData.email.$error },
                },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "email" } },
                    [_vm._v(_vm._s(_vm._f("translate")("EMAIL")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.email,
                        expression: "formData.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email", name: "email", id: "email" },
                    domProps: { value: _vm.formData.email },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formData, "email", $event.target.value)
                        },
                        function ($event) {
                          _vm.errorMessage = ""
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-messages", {
                    attrs: {
                      field: _vm.$v.formData.email,
                      "field-name": "email",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isErrorMessageShown,
                          expression: "isErrorMessageShown",
                        },
                      ],
                      staticClass: "validation-message has-error",
                    },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-12 submit-btn-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn brand-primary-btn",
                    attrs: { type: "submit" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("translate")("FORGOTTEN_PASS_SUBMIT")) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }