var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row answer-btn-row" }, [
    _c("div", { staticClass: "col-xs-12 col-sm-6 btn-col" }, [
      _c(
        "button",
        {
          staticClass: "btn primary-btn",
          attrs: { type: "submit", disabled: _vm.isLoaderShown },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("translate")("ANSWER_BUTTON")) +
              "\n        "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-12 col-sm-6 btn-col" }, [
      _c(
        "button",
        {
          staticClass: "btn secondary-btn",
          attrs: { type: "button", disabled: _vm.isLoaderShown },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.postpone()
            },
          },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("translate")("ANOTHER_QUESTION_BUTTON")) +
              "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }