<template>
    <div id="profile" class="page">
        <div class="part-1">
            <div class="container">
                <h1>{{ 'PROFILE_MAIN_TITLE' | translate }}</h1>
                <h2>{{ 'PROFILE_MAIN_SUBTITLE' | translate }}</h2>
            </div>
        </div>

        <div class="area-specific-part" v-for="(areaProgress, areaId) in allProgress" :key="areaId"
             :class="'part-' + area(areaId).css_class_name"
             style=""
        >
            <div class="container">
                <div class="row area-description-row">
                    <div class="bg-img img-responsive"></div>

                    <div class="col-xs-12 col-sm-5 col-sm-offset-7 area-description-col">
                        <h2>{{ area(areaId).name }}</h2>
                        <p>{{ area(areaId).description }}</p>

                        <button @click="continueButton" class="btn primary-btn"
                           :disabled="(areaId == currentAreaId) ? false : true"
                        >
                            {{ 'PROFILE_CONTINUE' | translate }}
                        </button>
                        <div class="area-finished-text"
                             v-if="areaId != currentAreaId"
                        >
                            {{ 'PROFILE_AREA_FINISHED' | translate }}
                        </div>
                    </div>

                </div>

                <user-progress :stats="areaProgress"
                               :showProgressbar="true"
                               :progressbarValue="areaLevelProgress(areaId)"
                               :showLeaderboardLink="(areaId == currentAreaId) ? true : false"
                ></user-progress>
            </div>
        </div>

        <div class="part-2"
             v-if="!ssoEnabled"
        >
            <div class="container">

                <h2>{{ 'PROFILE_EDIT_MAIN_TITLE' | translate }}</h2>

                <!--Change Password Form-->
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-md-offset-3">
                        <change-password></change-password>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import UserProgress from "Components/common/UserProgress";
    import ChangePassword from "./ChangePassword";

    export default {
        components : { UserProgress, ChangePassword },

        data() {
            return {
                ssoEnabled  : config.features.ssoLogin
            };
        },

        computed : mapGetters([ "allProgress", "currentAreaId", "area", "areaLevelProgress" ]),

        methods : {
            continueButton() { this.$router.push("/item-dashboard"); }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
