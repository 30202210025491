import findIndex from "lodash/findIndex";
import get from "lodash/get";
import values from "lodash/values";

import store from 'src/store/store';
import apiService from "src/services/api/apiService";
import * as types from "./mutationTypes";

const moduleState = {
    currentState    : "NoBattle",

    currentBattleId : null,

    opponent        : "",

    questions       : [],

    states          : {
        NO_BATTLE               : "NoBattle",
        WAITING_FOR_OPPONENT    : "WaitingForOpponent",
        WAITING_FOR_USER        : "WaitingForUser",
        BATTLE_IN_PROGRESS      : "BattleInProgress"
    }
};

const moduleGetters = {
    battles                     : state => state,

    // switching components in Battles page parent component. Not a good practice to do it here, probably
    // @note that the battle state names are the same as component names, that's why we can do this.
    // Otherwise, we would need a switch here to select the component corresponding to a state
    currentArenaComponent       : state =>
        values(state.states).indexOf(state.currentState) !== -1 ? state.currentState : state.states.NO_BATTLE,

    currentBattleQuestionIndex  : state => findIndex(state.questions, { "user_answer" : null }),
    currentBattleQuestion       : (state, getters) => state.questions[getters.currentBattleQuestionIndex],
    allBattleQuestionsAnswered  : (_, getters) => getters.currentBattleQuestionIndex === -1
};

const actions = {
    downloadBattles({ commit }) {
        return apiService.getBattles().then(result => {
            commit(types.REPLACE_BATTLES, result.data);
            return result.data;
        });
    },

    sendBattleChallenge({ commit }, opponentId) {
        return apiService.postBattleChallenge(opponentId).then(result => {
            // battle info is already in the response
            commit(types.REPLACE_BATTLES, result.data);
            return result;
        });
    },

    acceptBattle({ commit, state }) {
        return apiService.postAcceptBattle(state.currentBattleId).then(result => {
            // battle info is already in the response
            commit(types.REPLACE_BATTLES, result.data);
            return result;
        });
    },

    declineBattle({ commit, state }) {
        return apiService.postDeclineBattle(state.currentBattleId).then(result => {
            // battle info is already in the response
            commit(types.REPLACE_BATTLES, result.data);
            // user's points have changed
            store.dispatch("downloadUserData");
            return result;
        });
    },

    sendBattleQuestionAnswer({ commit, state, getters }, { itemId, answer }) {
        return apiService.postBattleQuestionAnswer(state.currentBattleId, itemId, answer).then(result => {
            // a special case - if we answered last question and the battle has been already answered,
            // it would seem weird to immediately show NoBattle. Better show "battle waiting for finish"
            // and wait for won/lost modal to arrive
            if (getters.currentBattleQuestionIndex === state.questions.length - 1
                && get(result.data, "status") === state.states.NO_BATTLE
            ) {
                result.data.status = state.states.BATTLE_IN_PROGRESS;
            }

            // battle info is already in the response
            commit(types.REPLACE_BATTLES, result.data);
            return result;
        });
    }
};

const mutations = {
    [types.REPLACE_BATTLES](state, newBattleInfo) {
        state.currentState = get(newBattleInfo, "status", "NoBattle");
        state.currentBattleId = get(newBattleInfo, "battle.id", null);
        state.opponent = get(newBattleInfo, "battle.opponent_name", null);
        state.questions = get(newBattleInfo, "questions", []);
    },
};

export default {
    state : moduleState,
    getters : moduleGetters,
    actions,
    mutations
};
