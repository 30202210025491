var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "reset-password" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm._f("translate")("RESET_PASS_MAIN_TITLE"))),
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(_vm._s(_vm._f("translate")("RESET_PASS_MAIN_SUBTITLE"))),
        ]),
        _vm._v(" "),
        _c("h2", [
          _vm._v(_vm._s(_vm._f("translate")("RESET_PASS_SECONDARY_TITLE"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-6 col-md-offset-3" }, [
            _c("div", { staticClass: "panel panel-default" }, [
              _c("div", { staticClass: "panel-heading" }, [
                _vm._v(_vm._s(_vm._f("translate")("RESET_PASS_TITLE"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel-body" }, [
                _c(
                  "form",
                  {
                    attrs: { novalidate: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.reset.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 form-group",
                          class: { "has-error": _vm.$v.password.$error },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "password" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("translate")("NEW_PASSWORD"))
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("vue-password", {
                            attrs: {
                              name: "password",
                              id: "password",
                              classes: "form-control",
                              disableStrength: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.password.$touch()
                              },
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                          _vm._v(" "),
                          _c("validation-messages", {
                            attrs: {
                              field: _vm.$v.password,
                              "field-name": "password",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn-row" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn brand-primary-btn",
                          attrs: { type: "submit" },
                        },
                        [
                          _vm._v(
                            "\n                                        " +
                              _vm._s(_vm._f("translate")("RESET_PASS_BUTTON")) +
                              "\n                                    "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }