var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "list-group-item list-item" }, [
    _c("div", { staticClass: "row list-item-row" }, [
      _c("div", { staticClass: "col-xs-3 col-sm-4 col-lg-3 img-wrapper" }, [
        _c("img", {
          staticClass: "img img-responsive",
          attrs: {
            src: _vm.imgPath,
            alt: _vm._f("translate")("ALT_IMAGE_BADGE"),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-9 col-sm-8 col-lg-9" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 title-wrapper",
              class: { "col-sm-7": _vm.tag },
            },
            [_c("h4", [_vm._v(_vm._s(_vm.title))])]
          ),
          _vm._v(" "),
          _vm.tag
            ? _c("div", { staticClass: "col-xs-12 col-sm-5 tag" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.tag) +
                    "\n                "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.desc))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }