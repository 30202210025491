class Logger {
    constructor() { }

    log(logTag, thingToLog, verbose = false) {
        if (!config.log.enabled || (verbose && !config.log.verbose)) {
            return;
        }

        // only one parameter was provided. Treat it as the message, not only as a tag
        if (typeof thingToLog === "undefined") {
            console.log(logTag);
            return;
        }

        console.log(`[${logTag}]`, thingToLog);
    }
}

const log = new Logger();

export default log;
