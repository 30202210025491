<template>
    <div class="answer-response-view incorrect-answer">
        <h3>{{ 'INCORRECT_ANSWER_TITLE' | translate }}</h3>

        <div class="bg-img img-responsive"></div>

        <p>{{ 'INCORRECT_ANSWER_DESCRIPTION' | translate }}</p>

        <p v-if="lastAnswerData.points < 0" class="points">
            {{ $t("POINTS_GAINED", { points : lastAnswerData.points }) }}
        </p>

        <!--Showing correct answers (feature 11/2019)-->
        <h4 class="answer-title">{{ 'CORRECT_ANSWER_TITLE' | translate }}:</h4>
        <template v-if="currentItem.type === 'choice'">
            <div v-for="(choice, order) in currentItem.options.choices"
                 v-if="lastAnswerData.correct_answer.indexOf(choice.id) !== -1"
                 class="custom-checkbox checked"
            >
                <div class="row">
                    <div class="col-xs-3 col-sm-2 checkbox-col">
                        <input type="checkbox" :id="'checkbox-' + choice.id"
                               :value="choice.id" disabled
                               class="regular-checkbox big-checkbox"
                        /><label :for="'checkbox-' + choice.id">{{ orderToChar(order) }}</label>
                    </div>
                    <div class="col-xs-9 col-sm-10 tag-col">
                        <div class="tag">{{ choice.text }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <p>{{ lastAnswerData.correct_answer }}</p>
        </template>

        <button @click="next" class="btn secondary-btn">
            {{ 'ANOTHER_QUESTION_BUTTON' | translate }}
        </button>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "lastAnswerData", "currentItem" ]),

        methods : {
            next() { this.$emit("next", { action : "nextItem" }); },

            orderToChar(order) {
                const map = [
                    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
                    'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
                ];
                return map[order];
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>