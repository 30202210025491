var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row achievements-row" }, [
    _vm.areThereAnyAchievements
      ? _c(
          "ul",
          { staticClass: "list-group achievements-list" },
          _vm._l(_vm.limitedAchievements(), function (achievement) {
            return _c(
              "div",
              { key: achievement.id, staticClass: "col-xs-12 col-sm-6" },
              [
                _c("list-item", {
                  attrs: {
                    "img-path": achievement.image_path,
                    title: achievement.name,
                    desc: achievement.description,
                    tag: achievement.category_name,
                    "data-key": "sameHeightAchievements",
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _c("p", { staticClass: "empty-achievements" }, [
          _vm._v(_vm._s(_vm._f("translate")("NO_ACHIEVEMENTS_YET"))),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }