<template>
    <div id="battle-progress">

        <div class="question-progress-wrapper"
             v-for="(question, index) in battles.questions" :key="index"
             :style="`width:${100/battles.questions.length}%`"
        >
            <div class="question-progress">
                <h5>
                    {{ $t("BATTLES_PROGRESS_QUESTION_NO", { variable : (index + 1) }) }}
                </h5>

                <div class="question-progress-images">
                    <div v-if="question.user_answer !== null">
                        <div class="question-user"
                             :class="{ 'question-correct' : question.user_answer === true,
                                       'question-incorrect' : question.user_answer === false }"
                        >
                            <div class="bg-img img-responsive"></div>
                        </div>

                        <div class="question-result"
                             :class="{ 'question-both-correct' : question.user_answer === true && question.opponent_answer === true,
                                       'question-both-incorrect' : question.user_answer === false && question.opponent_answer === false,
                                       'question-won' : question.user_answer === true && question.opponent_answer === false,
                                       'question-lost' : question.user_answer === false && question.opponent_answer === true,
                                       'question-wait' : question.opponent_answer === null }"
                        >
                            <div class="bg-img img-responsive"></div>
                        </div>

                        <div class="question-opponent"
                             :class="{ 'question-correct' : question.opponent_answer === true,
                                       'question-incorrect' : question.opponent_answer === false,
                                       'question-unknown' : question.opponent_answer === null }"
                        >
                            <div class="bg-img img-responsive"></div>
                        </div>
                    </div>
                </div>
                <p>
                    <template v-if="question.user_answer === true">
                        <span v-if="question.opponent_answer === true">
                            {{ 'BATTLES_QUESTION_BOTH_CORRECT' | translate }}
                        </span>
                        <span v-else-if="question.opponent_answer === false">
                            {{ 'BATTLES_QUESTION_WON' | translate }}
                        </span>
                        <span v-else-if="question.opponent_answer === null">
                            {{ 'BATTLES_QUESTION_WAIT' | translate }}
                        </span>
                    </template>
                    <template v-if="question.user_answer === false">
                         <span v-if="question.opponent_answer === true">
                            {{ 'BATTLES_QUESTION_LOST' | translate }}
                        </span>
                        <span v-else-if="question.opponent_answer === false">
                            {{ 'BATTLES_QUESTION_BOTH_INCORRECT' | translate }}
                        </span>
                        <span v-else-if="question.opponent_answer === null">
                            {{ 'BATTLES_QUESTION_WAIT' | translate }}
                        </span>
                    </template>
                </p>
            </div>
        </div>

    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([ "battles" ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>