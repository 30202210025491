var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "battle-progress" } },
    _vm._l(_vm.battles.questions, function (question, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "question-progress-wrapper",
          style: `width:${100 / _vm.battles.questions.length}%`,
        },
        [
          _c("div", { staticClass: "question-progress" }, [
            _c("h5", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t("BATTLES_PROGRESS_QUESTION_NO", {
                      variable: index + 1,
                    })
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "question-progress-images" }, [
              question.user_answer !== null
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "question-user",
                        class: {
                          "question-correct": question.user_answer === true,
                          "question-incorrect": question.user_answer === false,
                        },
                      },
                      [_c("div", { staticClass: "bg-img img-responsive" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "question-result",
                        class: {
                          "question-both-correct":
                            question.user_answer === true &&
                            question.opponent_answer === true,
                          "question-both-incorrect":
                            question.user_answer === false &&
                            question.opponent_answer === false,
                          "question-won":
                            question.user_answer === true &&
                            question.opponent_answer === false,
                          "question-lost":
                            question.user_answer === false &&
                            question.opponent_answer === true,
                          "question-wait": question.opponent_answer === null,
                        },
                      },
                      [_c("div", { staticClass: "bg-img img-responsive" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "question-opponent",
                        class: {
                          "question-correct": question.opponent_answer === true,
                          "question-incorrect":
                            question.opponent_answer === false,
                          "question-unknown": question.opponent_answer === null,
                        },
                      },
                      [_c("div", { staticClass: "bg-img img-responsive" })]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                question.user_answer === true
                  ? [
                      question.opponent_answer === true
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "BATTLES_QUESTION_BOTH_CORRECT"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : question.opponent_answer === false
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")("BATTLES_QUESTION_WON")
                                ) +
                                "\n                    "
                            ),
                          ])
                        : question.opponent_answer === null
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")("BATTLES_QUESTION_WAIT")
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                question.user_answer === false
                  ? [
                      question.opponent_answer === true
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")("BATTLES_QUESTION_LOST")
                                ) +
                                "\n                    "
                            ),
                          ])
                        : question.opponent_answer === false
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "BATTLES_QUESTION_BOTH_INCORRECT"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : question.opponent_answer === null
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")("BATTLES_QUESTION_WAIT")
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }