var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "battle-question" } }, [
    _c("div", { staticClass: "panel panel-default item-view-wrapper" }, [
      _c("div", { staticClass: "panel-body" }, [
        _c("h4", [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.$t("BATTLES_PROGRESS_QUESTION_NO", {
                  variable: _vm.currentBattleQuestionIndex + 1,
                })
              ) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v(_vm._s(_vm.currentBattleQuestion.name))]),
        _vm._v(" "),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.currentBattleQuestion.description),
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3",
            },
            [
              _c(
                _vm.currentBattleQuestion.type === "text"
                  ? "TextAnswer"
                  : "ChoiceAnswer",
                {
                  ref: "answerComponent",
                  tag: "component",
                  attrs: { item: _vm.currentBattleQuestion },
                  on: { answer: _vm.answer },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn primary-btn answer-btn",
                      attrs: { slot: "buttons", type: "submit" },
                      slot: "buttons",
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm._f("translate")("BATTLES_QUESTION_SEND_ANSWER")
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }