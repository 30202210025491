var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "validation-message-wrapper" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.field.$error,
            expression: "field.$error",
          },
        ],
        staticClass: "validation-message has-error",
      },
      _vm._l(_vm.field.$params, function (params, key) {
        return !_vm.field[key]
          ? _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t("VALIDATION_" + params.type, {
                      field: _vm.$t("VALIDATION_FIELDNAME_" + _vm.fieldName),
                      min: params.min,
                      length: params.length,
                    })
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e()
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }