<template>
    <div class="panel panel-default category-info">
        <div class="panel-body">
            <div class="row category-info-title-row">
                <div class="col-xs-3 col-sm-2 col-md-2">
                    <img :src="currentItemCategory.image_path"
                         class="img img-responsive"
                         :alt="'ALT_IMAGE_CATEGORY' | translate"
                    >
                </div>
                <div class="col-xs-9 col-sm-7 col-md-8">
                    <h3>{{ 'CATEGORY_INFO_SECTION_TITLE' | translate }}</h3>
                    <h2>{{ currentItemCategory.name }}</h2>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-2 category-info-btn-col">
                    <a v-if="!infoDetailVisible"
                       @click="infoDetailVisible = true"
                    >
                        {{ 'CATEGORY_INFO_SECTION_BTN_MORE' | translate }}
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <a v-else
                       @click="closeDetail"
                    >
                        {{ 'CATEGORY_INFO_SECTION_BTN_LESS' | translate }}
                        <i class="fa fa-chevron-up"></i>
                    </a>
                </div>
            </div>

            <div v-show="infoDetailVisible"
                 class="category-info-detail"
            >
                <h3 v-show="currentItemCategory.info_title">
                    {{ currentItemCategory.info_title }}
                </h3>

                <p v-html="currentItemCategory.info_content"
                   class="info_content"
                >
                </p>

                <a :href="currentItemCategory.info_cta_url"
                   v-if="currentItemCategory.info_cta_url"
                   target="_blank"
                >
                    {{ currentItemCategory.info_cta_title }}
                    <i class="fa fa-external-link"></i>
                </a>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        mounted : function() {
            this.openDetailOnUnseenCategory();
        },

        watch : {
            // if an item from a previously un-closed (unseen) category shows up, we want to show the
            // category detail
            currentItemCategory() {
                this.openDetailOnUnseenCategory();
            }
        },

        computed : mapGetters(["currentItemCategory"]),

        data() {
            return {
                infoDetailVisible : false,
            };
        },

        methods : {
            openDetailOnUnseenCategory() {
                if (this.currentItemCategory.id === undefined) {
                    return;
                }

                // if this user hasn't seen the detail of this category yet, show the full details.
                // Otherwise, close the detail - this can happen on transition from open detail to a new
                // item from previously closed (seen) category
                const seenCategories = JSON.parse(localStorage.getItem("seenCategoryDetails")) || [];
                this.infoDetailVisible = seenCategories.indexOf(this.currentItemCategory.id) === -1;
            },

            closeDetail() {
                this.infoDetailVisible = false;

                // store the fact that the user has already seen this category detail so that it's closed
                // by default next time the component is mounted
                let seenCategories = JSON.parse(localStorage.getItem("seenCategoryDetails")) || [];

                if (seenCategories.indexOf(this.currentItemCategory.id) === -1) {
                    seenCategories.push(this.currentItemCategory.id);
                }

                localStorage.setItem("seenCategoryDetails", JSON.stringify(seenCategories));
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
