<template>
    <!--If there is no current item (user has drawn all of them), show "clean table"-->
    <div class="item-view no-items-view">
        <h3>{{ 'NO_ITEMS_TITLE' | translate }}</h3>

        <div class="bg-img img-responsive"></div>

        <p class="no-items-description">{{ 'NO_ITEMS_DESCRIPTION' | translate }}</p>

        <p class="no-items-description"
           v-html="$options.filters.translate('NO_ITEMS_DESCRIPTION_LONG')"
        ></p>

        <p>
            <span v-if="isAnyCategoryFocused">
                <span class="focus-title">{{ 'YOUR_FOCUS_IS' | translate }}:</span> {{ currentFocusedCategory.name }}.
            </span>
            <span v-else class="focus-title">{{ 'YOU_HAVE_NO_FOCUS' | translate }}.</span>
        </p>

        <a href="/#/focus"
           class="btn secondary-btn"
           v-if="!isAnyCategoryFocused || currentCategories.length > 1"
        >
            {{ 'CHOOSE_FOCUS_FOR_TOMORROW' | translate }}
        </a>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    export default {
        computed : mapGetters([
            "isAnyCategoryFocused", "currentFocusedCategory", "currentCategories"
        ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
