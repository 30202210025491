var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "leaderboard" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm._f("translate")("LEADERBOARD_MAIN_TITLE"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row under-title" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-5 col-lg-4 rank-section" },
            [
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("div", { staticClass: "bg-img img-responsive" }),
                  _vm._v(" "),
                  _c("h2", [
                    _vm._v(
                      _vm._s(_vm._f("translate")("LEADERBOARD_YOUR_RANK")) + ":"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.currentProgress
                    ? _c("h2", { staticClass: "rank" }, [
                        _vm._v(
                          "\n                                #" +
                            _vm._s(_vm.currentProgress.rank) +
                            "\n                            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "note" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("LEADERBOARD_EXPLANATION_TEXT")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn primary-btn",
                      attrs: { href: "/#/item-dashboard" },
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm._f("translate")("CONTINUE_PLAYING")) +
                          "\n                            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-7 col-lg-8 ladder-section" },
            [
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(_vm._f("translate")("LEADERBOARD_OF_TEAM_PLAYERS"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _vm.currentTeamUsersLeaderboard
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table leaderboard-table table-striped",
                          },
                          [
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.currentTeamUsersLeaderboard,
                                function (player) {
                                  return _c(
                                    "tr",
                                    {
                                      class:
                                        _vm.user.id === player.id
                                          ? "user-row"
                                          : "",
                                    },
                                    [
                                      _c("td", [
                                        _vm._v(_vm._s(player.position)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(player.name))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(player.score))]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(_vm._f("translate")("LEADERBOARD_OF_AREA_TEAMS"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _vm.currentAreaTeamsLeaderboard
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table leaderboard-table table-striped",
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th"),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "LEADERBOARD_TEAMS_SCORE"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "LEADERBOARD_TEAMS_SCORE_PER_USER"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.currentAreaTeamsLeaderboard,
                                function (team) {
                                  return _c(
                                    "tr",
                                    {
                                      class:
                                        _vm.user.team.id === team.id
                                          ? "user-row"
                                          : "",
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(team.position))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(team.name))]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(_vm._s(team.score)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(team.score_per_user)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(_vm._f("translate")("LEADERBOARD_OF_ALL_PLAYERS"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _vm.currentAllUsersLeaderboard
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table leaderboard-table table-striped",
                          },
                          [
                            _c(
                              "tbody",
                              [
                                _vm.currentProgress &&
                                _vm.currentProgress.rank != 1
                                  ? _c("tr", [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td", [_vm._v("...")]),
                                      _vm._v(" "),
                                      _c("td"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.currentAllUsersLeaderboard,
                                  function (player) {
                                    return _c(
                                      "tr",
                                      {
                                        class:
                                          player.id === _vm.user.id
                                            ? "user-row"
                                            : "",
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(player.position)),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [_vm._v(_vm._s(player.name))]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(player.score)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _vm.currentProgress &&
                                _vm.currentProgress.score != 0
                                  ? _c("tr", [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td", [_vm._v("...")]),
                                      _vm._v(" "),
                                      _c("td"),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("LEADERBOARD_OF_BATTLES_IN_AREA")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _vm.currentAreaBattlesLeaderboard
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table leaderboard-table table-striped",
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th"),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")("LEADERBOARD_WINS")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")("LEADERBOARD_TIES")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")("LEADERBOARD_LOSSES")
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.currentAreaBattlesLeaderboard,
                                function (player) {
                                  return _c(
                                    "tr",
                                    {
                                      class:
                                        _vm.user.id === player.id
                                          ? "user-row"
                                          : "",
                                    },
                                    [
                                      _c("td", [
                                        _vm._v(_vm._s(player.position)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(player.name))]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(player.wins)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(player.ties)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(player.losses)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("LEADERBOARD_OF_BATTLES_IN_TEAM")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _vm.currentTeamBattlesLeaderboard
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table leaderboard-table table-striped",
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th"),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")("LEADERBOARD_WINS")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")("LEADERBOARD_TIES")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")("LEADERBOARD_LOSSES")
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.currentTeamBattlesLeaderboard,
                                function (player) {
                                  return _c(
                                    "tr",
                                    {
                                      class:
                                        _vm.user.id === player.id
                                          ? "user-row"
                                          : "",
                                    },
                                    [
                                      _c("td", [
                                        _vm._v(_vm._s(player.position)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(player.name))]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(player.wins)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(player.ties)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(player.losses)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }