<template>
    <span class="validation-message-wrapper">
        <span class="validation-message has-error" v-show="field.$error">
            <!--<pre>{{ field }}</pre>-->
            <span v-for="(params, key) in field.$params"
                  v-if="!field[key]"
            >
                {{ $t("VALIDATION_" + params.type, {
                    field : $t("VALIDATION_FIELDNAME_" + fieldName),
                    min : params.min, length: params.length
                }) }}
            </span>
        </span>
    </span>
</template>
<!---------------------------------------------------------------------------->
<script>
    export default {
        props : {
            field : { type : Object, default : null },
            fieldName : { type : String, default : "" }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>