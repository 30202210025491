<template>
    <div id="test-items">
        <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-2 attempt-number">
                {{ 'KNOWLEDGE_TEST_ATTEMPT' | translate }}
                {{ currentAttemptNumber(currentTest.id) }} / {{ maxTestAttempts(currentTest.id) }}
            </div>
            <div class="col-xs-12 col-sm-9 col-md-10">
                {{ 'KNOWLEDGE_TEST_TIME_REMAINING' | translate }}
                <span class="remaining-time">
                    {{ testTimeRemaining }}
                </span>
            </div>
        </div>

        <div class="row item-btn-row">
            <div class="col-xs-12 col-sm-6">
                <button class="btn secondary-btn" @click="() => $emit('go-to-video')"
                        v-show="currentTest.is_video_active"
                >
                    < {{ 'KNOWLEDGE_TEST_VIDEO_TO_VIDEO' | translate }}
                </button>
            </div>
        </div>

        <div v-show="!showSubmitPage">
            <div class="item-desc">
                {{ 'KNOWLEDGE_TEST_QUESTION' | translate }} {{ currentTestItemIndex + 1 }} / {{ testItemsCount }}

                <h3>{{ currentTestItem.name }}</h3>
                <p v-html="currentTestItem.description"></p>

                <!-- optional item image (added Q4/2020) -->
                <img :src="currentTestItem.image_path"
                     class="img img-responsive"
                     :alt="'ALT_IMAGE_TEST_ITEM' | translate"
                     v-if="currentTestItem.image_path"
                     v-img
                >
            </div>

            <!-- optional item audio file (added Q4/2020) -->
            <test-item-audio v-if="currentTestItem.audio_path"
                             :item="currentTestItem"
            ></test-item-audio>

            <component :is="currentTestItem.type === 'choice' ? 'TestChoiceAnswer' : 'TestTextAnswer'"
                       :item="currentTestItem"
                       :current-answer="currentTestItemAnswer"
                       @answer="sendAnswer"
            ></component>
        </div>

        <div v-show="showSubmitPage">
            <hr>
            <button class="btn btn-success center-block"
                    @click="beforeFinish()"
            >
                {{ 'KNOWLEDGE_TEST_SUBMIT' | translate }}
            </button>
        </div>

        <div class="progress">
            <progressbar :now="currentTestProgress" type="success" striped></progressbar>
        </div>

        <test-pagination :items="testItems"
                         :current-item-index="currentTestItemIndex"
                         :answered-items="Object.keys(testAnswers)"
                         @on-item-select="goToItem"
        ></test-pagination>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import isEmpty from "lodash/isEmpty";
    import size from "lodash/size";
    import { mapGetters } from "vuex";
    import progressbar from 'vue-strap/src/Progressbar.vue';

    import TestTextAnswer from "./TestTextAnswer";
    import TestChoiceAnswer from "./TestChoiceAnswer";
    import TestItemAudio from "./TestItemAudio";
    import TestPagination from "./TestPagination";
    import notificationService from "src/services/notificationService";
    import apiDefinition from "src/config/apiDefinition";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "TestItems";

    export default {
        name : "TestItems",

        components : { progressbar, TestTextAnswer, TestChoiceAnswer, TestItemAudio, TestPagination },

        created : function() {},

        computed : mapGetters([
            "currentTest", "currentTestAttemptId", "testTimeRemaining", "currentAttemptNumber",
            "maxTestAttempts", "testItemsCount", "currentTestItem", "currentTestItemIndex",
            "currentTestProgress", "currentTestItemAnswer", "currentAreaCssClass", "testAnswers",
            "testItems"
        ]),

        data() {
            return {
                progressbarValue        : 100,
                showSubmitPage          : false
            };
        },

        methods : {
            isEmpty,

            // @note: itemIndex equal to testItemsCount represents submit page
            // @note: the callers should clamp the index value to <0, testItemsCount>
            goToItem(itemIndex) {
                log.log(logTag, `Go to item with index: ${itemIndex}`);
                if (itemIndex <= this.testItemsCount - 1) {
                    this.showSubmitPage = false;
                } else {
                    this.showSubmitPage = true;
                }

                this.$store.dispatch("setCurrentTestItem", itemIndex);
            },

            sendAnswer(answer) {
                this.$store.dispatch("sendTestAnswer", { id : this.currentTestItem.id, answer })
                .then(() => {
                    this.goToItem(this.currentTestItemIndex + 1);
                })
                .catch(this.handleErrors);
            },

            beforeFinish() {
                // if the user hasn't answered all of the questions, show modal
                if (size(this.testAnswers) < this.testItemsCount) {
                    notificationService.knowledgeTestGeneric(
                        this.currentTest.title,
                        this.$t("KNOWLEDGE_TEST_MODAL_FINISH_TITLE"),
                        this.$t("KNOWLEDGE_TEST_MODAL_FINISH_TEXT"),
                        this.currentAreaCssClass,
                        (result) => { if (result === "cancel") { this.finishTestAttempt(); } },
                        this.$t("KNOWLEDGE_TEST_MODAL_FINISH_CONFIRM"),
                        this.$t("KNOWLEDGE_TEST_MODAL_FINISH_REJECT")
                    );

                } else {
                    this.finishTestAttempt();
                }
            },

            finishTestAttempt() {
                log.log(logTag, "Finishing the test attempt");

                // @note that current attempt ID is lost on redownload after finish
                this.$store.dispatch("finishTestAttempt").then(() => {
                    this.$emit("finish-attempt");
                })
                .catch(this.handleErrors);
            },

            handleErrors(reason) {
                log.log(logTag, "Test item answer/finish error, reason follows: ");
                log.log(logTag, reason);

                // redownload the attempt info - the items etc
                this.$store.dispatch("downloadTestAttempt", this.currentTestAttemptId).then(() => {
                    this.$store.dispatch("downloadKnowledgeTests");
                });

                switch (reason.status) {
                    case apiDefinition.codes.gone :
                    case apiDefinition.codes.failedDependency :
                        // note that this is not the same as actual time_remaining = 0 during time sync
                        // - this probably happens only when the sync mechanism is broken somehow
                        this.$emit("timeout");
                        break;
                    case apiDefinition.codes.forbidden :
                    case apiDefinition.codes.notFound :
                        // @todo: redirect to /focus? this shouldn't really happen
                        break;
                    default :
                        // do nothing
                        break;
                }

                // @note we do not reset the answers in this case
                // @note that this is the same for "answer" and "finish attempt" request
                this.$store.dispatch("showErrorMessage", userMessages.afterTestAnswerAndFinish(reason));
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
