var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "no-battle" } }, [
    _c("div", { staticClass: "bg-img img-responsive" }),
    _vm._v(" "),
    _c("h2", [
      _vm._v(_vm._s(_vm._f("translate")("BATTLES_NO_BATTLE_TITLE_1"))),
    ]),
    _vm._v(" "),
    _c("h2", [
      _vm._v(_vm._s(_vm._f("translate")("BATTLES_NO_BATTLE_TITLE_2"))),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm._f("translate")("BATTLES_NO_BATTLE_TEXT")))]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "search-wrapper" } },
      [
        _c("autocomplete", {
          attrs: {
            url:
              _vm.apiBase +
              _vm.apiEndpoint +
              _vm.apiDefinition.battles.base +
              _vm.apiDefinition.battles.opponents,
            param: "q",
            customHeaders: _vm.customHeaders,
            anchor: "name",
            label: "id",
            min: 1,
            "on-select": _vm.selectOpponent,
            onInput: _vm.onAutocompleteInput,
            placeholder: _vm._f("translate")("BATTLES_OPPONENT_SEARCH_PH"),
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn primary-btn cta-btn",
            attrs: { disabled: !_vm.selectedOpponent },
            on: {
              click: function ($event) {
                return _vm.sendBattleRequest()
              },
            },
          },
          [
            _vm._v(
              "\n           " +
                _vm._s(_vm.currentAreaDetails.battles_ctoa_button_title) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.opponentIsInAnotherBattle
          ? _c("p", [
              _vm._v(_vm._s(_vm._f("translate")("BATTLES_OPPONENT_IS_BUSY"))),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }