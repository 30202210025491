<template>
    <div class="category-box knowledge-test-box"
         :class="{ 'knowledge-test-warning' : !didUserPassTest(testId) && isTestClosingSoon(testId) }"
    >
        <div data-key="sameHeightCategories">
            <!-- this is different than img-responsive! -->
            <div class="bg-img responsive-bg-img"></div>

            <div class="content-wrapper">
                <h3 class="test-title">
                    {{ knowledgeTests[testId].title || $t('KNOWLEDGE_TEST_FOCUS_TITLE') }}
                </h3>

                <span class="label label-important test-deadline-label">
                    {{ "KNOWLEDGE_TEST_DEADLINE" | translate }} {{ knowledgeTests[testId].open_to_formatted }}
                </span>

                <div class="box-body">
                    <p class="previous-attempt attempt-running"
                       v-if="isAttemptForTestActive(testId)"
                    >
                        {{ 'KNOWLEDGE_TEST_UNFINISHED_ATTEMPT' | translate }}
                    </p>

                    <template v-else-if="!isEmpty(bestTestAttempt(testId))">
                        <!-- test is waiting for manual evaluation -->
                        <p class="previous-attempt attempt-manual"
                           v-if="bestTestAttempt(testId).result === null"
                        >
                             <span class="label label-default">
                                {{ 'KNOWLEDGE_TEST_PREVIOUS_MANUAL' | translate }}
                            </span>
                        </p>

                        <!-- test already has a result -->
                        <p class="previous-attempt" v-else
                           :class="bestTestAttempt(testId).result === true ? 'attempt-success' : 'attempt-fail'"
                        >
                            {{ 'KNOWLEDGE_TEST_YOUR_RESULT' | translate }}
                            {{ bestTestAttempt(testId).percentage }}%
                            <span class="label label-success" v-if="bestTestAttempt(testId).result === true">
                                {{ 'KNOWLEDGE_TEST_PREVIOUS_SUCCESS' | translate }}
                            </span>
                            <span class="label label-danger" v-else>
                                {{ 'KNOWLEDGE_TEST_PREVIOUS_FAIL' | translate }}
                            </span>
                        </p>
                    </template>

                    <p class="test-attempts-count"
                       v-if="!isAttemptForTestActive(testId)"
                    >
                        <template v-if="remainingTestAttempts(testId) === 0">
                            {{ 'KNOWLEDGE_TEST_ATTEMPTS_0' | translate }}
                        </template>
                        <template v-else-if="remainingTestAttempts(testId) === 1">
                            {{ 'KNOWLEDGE_TEST_ATTEMPTS_1' | translate }}
                        </template>
                        <template v-else-if="remainingTestAttempts(testId) <= 4">
                            {{ $t('KNOWLEDGE_TEST_ATTEMPTS_2_4', { count : remainingTestAttempts(testId) }) }}
                        </template>
                        <template v-else>
                            {{ $t('KNOWLEDGE_TEST_ATTEMPTS_5_MORE', { count : remainingTestAttempts(testId) } ) }}
                        </template>
                    </p>
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="btn secondary-btn"
                    @click="continueAttempt()"
                    v-if="isAttemptForTestActive(testId)"
            >
                {{ 'KNOWLEDGE_TEST_CONTINUE_ATTEMPT' | translate }}
            </button>
            <button class="btn secondary-btn"
                    @click="showStartModal()"
                    v-else
                    :disabled="remainingTestAttempts(testId) === 0 || isAnyAttemptActive"
            >
                {{ 'KNOWLEDGE_TEST_START_ATTEMPT' | translate }}
            </button>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import isEmpty from "lodash/isEmpty";

    import notificationService from "src/services/notificationService";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "KnowledgeTestBox";

    export default {
        name : "KnowledgeTestBox",

        components : { },

        props : {
            testId : { type : Number, default : null }
        },

        created : function() { },

        data() { return {}; },

        computed : mapGetters([
            "currentAreaCssClass", "knowledgeTests", "isAttemptForTestActive", "isAnyAttemptActive",
            "remainingTestAttempts", "bestTestAttempt", "isTestClosingSoon", "didUserPassTest"
        ]),

        methods : {
            isEmpty,

            showStartModal() {
                notificationService.knowledgeTestGeneric(
                    this.knowledgeTests[this.testId].title,
                    this.$t("KNOWLEDGE_TEST_MODAL_TITLE"),
                    this.knowledgeTests[this.testId].description,
                    this.currentAreaCssClass,
                    (result) => {
                        if (result === true) {
                            // ONLY this component and TestResult can start a new test attempt!
                            this.$store.dispatch("startTestAttempt", this.testId)

                            .then(() => {
                                this.$router.push("/test");
                            })

                            .catch(reason => {
                                log.log(logTag, "Start test attempt - error, reason follows:");
                                log.log(logTag, reason);
                                this.$store.dispatch("showErrorMessage", userMessages.afterStartTestAttempt(reason));
                                // we might have had old test data - redownload
                                this.$store.dispatch("downloadKnowledgeTests");
                            });
                        }
                    }
                );
            },

            continueAttempt() {
                this.$router.push("/test");
            },
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>