<template>
    <div id="test-video">
        <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-2 attempt-number">
                {{ 'KNOWLEDGE_TEST_ATTEMPT' | translate }}
                {{ currentAttemptNumber(currentTest.id) }} / {{ maxTestAttempts(currentTest.id) }}
            </div>
            <div class="col-xs-12 col-sm-9 col-md-10">
                {{ 'KNOWLEDGE_TEST_TIME_REMAINING' | translate }}
                <span class="remaining-time">
                    {{ testTimeRemaining }}
                </span>
            </div>
        </div>

        <h3 class="video-title">{{ currentTest.video_name }}</h3>

        <p class="video-desc">{{ currentTest.video_description }}</p>

        <div class="row video-row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <a :href="currentTest.attachment_exe">{{ 'KNOWLEDGE_TEST_ATT_DOWNLOAD' | translate }}</a>
            </div>
        </div>

        <div class="btn-row">
            <button class="btn secondary-btn"
                    @click="onContinueButton"
            >
                {{ 'KNOWLEDGE_TEST_VIDEO_TO_ITEMS' | translate }}
            </button>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
import { mapGetters } from "vuex";

import apiDefinition from "src/config/apiDefinition";
import userMessages from "src/services/userMessages";
import log from 'src/services/logger';
const logTag = "TestAttachment";

export default {
    name : "TestAttachment",

    components : { },

    computed : mapGetters([
        "currentTest", "currentAttemptNumber", "maxTestAttempts", "testTimeRemaining"
    ]),

    data() {
        return {};
    },

    methods : {
        onContinueButton() {
            if (this.currentTest.is_video_watched) {
                this.$emit("go-to-items");
                return;
            }

            log.log(logTag, "Dispatching 'video watched' event to server");
            this.$store.dispatch("setVideoWatched")

            .then(() => {
                this.$emit("go-to-items");
            })

            .catch(reason => {
                this.$store.dispatch("showErrorMessage", userMessages.afterSetVideoWatched(reason));

                // give the user the chance to re-send the video-watched POST to server if the error was caused
                // by server or sth
                switch (reason.status) {
                    case apiDefinition.codes.gone :
                    case apiDefinition.codes.failedDependency :
                        this.$emit("timeout");
                        log.log(logTag, "Redirecting - attempt timed out or test is already inactive");
                        this.$router.push("/focus");
                        break;
                    default:
                        // do nothing
                        break;
                }
            });
        }
    }
};
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>