<template>
    <div class="loader-wrapper">
        <div class="loader"></div>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" rel="stylesheet/scss">
</style>