var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "error-page" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm._f("translate")("ERROR_MAIN_TITLE")) + "!"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4",
            },
            [
              _vm.errorPageContent
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-danger text-center",
                      attrs: { role: "alert" },
                    },
                    [_vm._v(_vm._s(_vm.errorPageContent))]
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "btn brand-secondary-btn", attrs: { href: "/" } },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm._f("translate")("NOT_FOUND_REDIRECT_BUTTON")) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }