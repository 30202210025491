<template>
    <form novalidate @submit.prevent="register">

        <div class="alert alert-success" role="alert" v-if="isSuccessMessageShown">{{ successMessage }}</div>

        <div v-else>
            <div class="row">
                <div class="col-xs-12 form-group"
                     :class="{'has-error' : $v.user.email.$error}"
                >
                    <label for="email" class="input-label">{{ 'EMAIL' | translate }}</label>
                    <input type="email" name="email" id="email"
                           v-model="user.email" @input="$v.user.email.$touch()"
                           class="form-control"
                    >
                    <validation-messages :field="$v.user.email" field-name="email"></validation-messages>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 form-group"
                     :class="{'has-error' : $v.user.password.$error}"
                >
                    <label for="password" class="input-label">{{ 'PASSWORD' | translate }}</label>
                    <vue-password name="password" id="password"
                                  v-model="user.password" @input="$v.user.password.$touch()"
                                  classes="form-control"
                                  disableStrength
                    >
                    </vue-password>
                    <validation-messages :field="$v.user.password" field-name="password"></validation-messages>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 submit-btn-wrapper">
                    <button class="btn brand-primary-btn" type="submit">
                        {{ "REGISTER_BUTTON" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
<!---------------------------------------------------------------------------->
<script>
    import VuePassword from 'vue-password/dist/custom';
    import { required, minLength, email } from "vuelidate/lib/validators";

    import ValidationMessages from "Components/common/ValidationMessages";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "RegistrationForm";

    export default {
        components : { VuePassword, ValidationMessages },

        activated : function() { this.$emit('created', this.$t("REGISTER")); },
        created : function() {
            if (config.debugMode) {
                this.user = {
                    email       : "user2@example.com",
                    password    : "111111"
                };
            }
        },

        data() {
            return {
                user : {
                    email       : "",
                    password    : ""
                },
                successMessage  : ""
            };
        },

        validations : {
            user : {
                email : { required, email },
                password : { required, minLength : minLength(6) }
            }
        },

        computed : {
            isSuccessMessageShown() { return this.successMessage.length > 0; }
        },

        methods : {
            register() {
                log.log(logTag, "Register button pressed");
                this.$v.$touch();

                if (!this.$v.$error) {
                    log.log(logTag, "Validation passed, registering");

                    this.$store.dispatch("sendRegistrationRequest", this.user)
                    // show the success message
                    .then((response) => {
                        this.successMessage = userMessages.afterRegistration(response);
                        this.$store.dispatch("showSuccessMessage", this.successMessage);
                    })
                    // registration unsuccessful, show the error
                    .catch(reason => {
                        this.$store.dispatch(
                            "showErrorMessage", userMessages.afterRegistration(reason)
                        );
                    });
                }
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>