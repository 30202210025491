import isNumber from "lodash/isNumber";

import store from "src/store/store";
import apiDefinition from "src/config/apiDefinition";
import log from "src/services/logger";
const logTag = "testTimeSyncService";

// this is for periodical request of current remaining time from server
let timeSyncInProgress = false;
let syncTimeoutId;

// this is for periodical update of displayed remaining time to user (by 1 second)
let testTimeRemaining = null;
let timeUpdateInProgress = false;
let timeUpdateTimeoutId;

// polling server for remaining time
const start = function(timeRemaining) {
    // if we are already polling for notifications, do nothing
    if (timeSyncInProgress) {
        log.log(logTag, "Time sync already started!");
        return;
    }
    timeSyncInProgress = true;

    // initial setup of remaining time
    testTimeRemaining = timeRemaining || null;

    log.log(logTag, "Starting time sync");
    syncTime();
    startUpdate();
};

// this is called when remaining time = 0; or on syncTime error; or on user-originated attempt finish
const stop = function() {
    log.log(logTag, "Stopping time sync, last timeout ID: " + syncTimeoutId);
    stopUpdate();
    // stop the last timeout
    clearTimeout(syncTimeoutId);
    syncTimeoutId = undefined;
    timeSyncInProgress = false;
    testTimeRemaining = null;
};

const checkForTimeout = function() {
    // time_remaining from server should always be a number, but if not, it's probably better to treat
    // it as a timeout
    if (testTimeRemaining <= 0) {
        // inform the rest of the app, show modal etc
        stop();
        store.dispatch("setTimeIsUp", true);
    }
};

const syncTime = function() {
    store.dispatch("syncTestTime").then(timeRemaining => {
        testTimeRemaining = timeRemaining;
        store.dispatch("updateTimeRemaining", format(testTimeRemaining));
        // if the time is up
        checkForTimeout();
    })
    .catch(reason => {
        switch (reason.status) {
            // 403 = this attempt belongs to another user
            // 404 = wrong attempt ID
            // 410 = the test is already inactive (past the deadline)
            case apiDefinition.codes.forbidden :
            case apiDefinition.codes.notFound :
            case apiDefinition.codes.gone :
                stop();
                break;
            // e.g. 500 server error - retry in the next recursion
            default :
                // do nothing
                break;
        }
    });

    // don't wait for the sync - it might fail and then we would become stuck here
    syncTimeoutId = setTimeout(syncTime, config.knowledgeTest.timeSyncInterval);
};

// updating the remaining time - subtracting one second
const startUpdate = function() {
    timeUpdateInProgress = true;
    timeUpdateTimeoutId = setTimeout(updateTimeRemaining, 1000);
};

const stopUpdate = function() {
    timeUpdateInProgress = false;
    clearTimeout(timeUpdateTimeoutId);
    timeUpdateTimeoutId = undefined;
};

const updateTimeRemaining = function() {
    // the time can be e.g. null - do nothing with that, as some wild casting might cause
    // testTimeRemaining to be 0 and thus close the test
    if (!isNumber(testTimeRemaining)) return;

    testTimeRemaining--;
    store.dispatch("updateTimeRemaining", format(testTimeRemaining));
    checkForTimeout();

    if (timeUpdateInProgress) {
        timeUpdateTimeoutId = setTimeout(updateTimeRemaining, 1000);
    }
};

const format = function(timeInSeconds) {
    if (!isNumber(timeInSeconds)) return "";

    let hours = Math.floor(timeInSeconds / 3600);
    let minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
    let seconds = timeInSeconds - hours * 3600 - minutes * 60;
    return (hours > 0 ? hours + "h " : "") + `${minutes}m ${seconds}s`;
};

export default {
    start,
    stop,
    format
};
