import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import extend from "lodash/extend";
import map from "lodash/map";
import forEach from "lodash/forEach";
import keyBy from "lodash/keyBy";
import clone from "lodash/clone";

import Vue from "vue";
import store from 'src/store/store';
import * as types from "./mutationTypes";
import apiService from "src/services/api/apiService";
import notificationService from "src/services/notificationService";
// import log from "src/services/logger";
// const logTag = "store.data";

// this is just a default to return before data can be get from server - to prevent whatever render
// errors Vue might throw
const areaDefault = {
    name            : "",
    description     : "",
    css_class_name  : config.defaultAreaCssClassName,
    categories      : [],
    small_image     : "",
    details         : {
        achievements_main_subtitle : "",
        focus_main_subtitle : "", focus_main_text : "", focus_main_title : "", focus_none_text : "",
        items_explanation_first_text : "", items_explanation_first_title : "",
        items_explanation_second_text : "", items_explanation_second_title : "",
        items_explanation_section_title : "", onboarding_ctoa_button_title : "",
        onboarding_explanation_first_text : "", onboarding_explanation_first_title : "",
        onboarding_explanation_second_text : "", onboarding_explanation_second_title : "",
        onboarding_explanation_section_title : "",
        onboarding_flavour_text : "", onboarding_flavour_text_tagline : "",
        onboarding_instructions_first_text : "", onboarding_instructions_first_title : "",
        onboarding_instructions_second_text : "", onboarding_instructions_second_title : "",
        onboarding_instructions_third_text : "", onboarding_instructions_third_title : "",
        onboarding_instructions_title : "", onboarding_main_subtitle : "", onboarding_main_title : "",
        primary_color : "",
        battles_main_title_first_row : "", battles_main_title_second_row : "", battles_main_subtitle : "",
        battles_ctoa_button_title : "", battles_instructions_title : "", battles_instructions_first_title : "",
        battles_instructions_first_text : "", battles_instructions_second_title : "", battles_instructions_second_text : "",
        battles_instructions_third_title : "", battles_instructions_third_text : "", battles_waiting_for_finish_title : "",
        battles_waiting_for_finish_text : "", battles_waiting_for_user_decline_points : ""
    }
};

const moduleState = {
    areas                       : {},

    currentItem                 : {},

    lastAnswerData              : {},

    achievements                : [],

    teamUsersLeaderboard        : [],

    areaTeamsLeaderboard        : [],

    allUsersLeaderboard         : [],

    areaBattlesLeaderboard      : [],

    teamBattlesLeaderboard      : []
};

const moduleGetters = {
    allAreas                    : state => state.areas,
    area                        : (_, getters) => areaId => getters.allAreas[areaId] || areaDefault,
    currentArea                 : (_, getters) => getters.area(getters.currentAreaId),
    currentAreaDetails          : (_, getters) => getters.currentArea.details,
    currentAreaCssClass         : (_, getters) => getters.currentArea.css_class_name,

    // #### categories, focus
    currentCategories           : (_, getters) => getters.currentArea.categories,
    currentFocusedCategories    : (_, getters) => getters.currentArea.focused_categories,
    // @note that we also check if we actually have the focused category data, otherwise an error might appear
    // if the currentFocusedCategories contains wrong IDs (e.g. from old areas - happened once, should be fixed
    // on server side now, but better safe than sorry)
    isAnyCategoryFocused       : (_, getters) => !isEmpty(getters.currentFocusedCategories) && find(getters.currentCategories, ["id", getters.currentFocusedCategories[0]]),
    // @note: this picks the first focused category as currently we use only one - there might be more in future
    currentFocusedCategory      : (_, getters) => {
        return getters.isAnyCategoryFocused
            ? find(getters.currentCategories, ["id", getters.currentFocusedCategories[0]]) : "";
    },

    // #### items, drawing, answering, postponing
    isThereAnyItem              : state => !isEmpty(state.currentItem),
    currentItem                 : state => state.currentItem,
    currentItemCategory         : (_, getters) => {
        const category = find(getters.currentCategories, ["id", getters.currentItem.category_id]);
        return category !== undefined ? category : {};
    },
    lastAnswerData              : state => state.lastAnswerData,

    // #### achievements
    allAchievements             : state => state.achievements,
    currentAchievements         : (_, getters) => getters.allAchievements[getters.currentAreaId],
    currentUnlockedAchievements : (_, getters) => {
        if (isEmpty(getters.currentAchievements)) {
            return [];
        } else {
            return map(getters.currentUnlockedAchievementsIds, function(achievementId) {
                return find(getters.currentAchievements, ["id", achievementId]);
            });
        }
    },
    areThereAnyUnlocked         : (_, getters) => !isEmpty(getters.currentUnlockedAchievements),
    currentLockedAchievements   : (_, getters) => {
        let lockedAchievements = [];

        forEach(getters.currentAchievements, function(achievement) {
            if (getters.currentUnlockedAchievementsIds.indexOf(achievement.id) === -1) {
                lockedAchievements.push(achievement);
            }
        });

        return lockedAchievements;
    },
    areThereAnyLocked           : (_, getters) => !isEmpty(getters.currentLockedAchievements),

    // #### leaderboard
    currentTeamUsersLeaderboard : state => state.teamUsersLeaderboard,
    // #### area leaderboard
    currentAreaTeamsLeaderboard : state => state.areaTeamsLeaderboard,

    currentAllUsersLeaderboard  : state => state.allUsersLeaderboard,

    currentAreaBattlesLeaderboard   : state => state.areaBattlesLeaderboard,

    currentTeamBattlesLeaderboard   : state => state.teamBattlesLeaderboard,
};

const actions = {
    downloadAreas({ commit }) {
        return apiService.getAreas().then((result) => {
            commit(types.REPLACE_AREAS, result.data.areas);
            return result.data.areas;
        });
    },

    // #### categories, focus
    focusCategory(_, categoryId) {
        return apiService.focusCategory(categoryId);
    },

    // #### items, drawing, answering, postponing
    drawItem({ commit }) {
        return apiService.drawItem().then((result) => {
            commit(types.REPLACE_CURRENT_ITEM, result.data);
        });
    },

    postponeItem(_, itemId) {
        return apiService.postponeItem(itemId);
    },

    answerItem({ commit, getters }, { itemId, answer }) {
        commit(types.REPLACE_LAST_ANSWER_DATA, {});

        return apiService.answerItem(itemId, answer).then((result) => {
            // these points will be used in e.g. CorrectAnswer and IncorrectAnswer components
            commit(types.REPLACE_LAST_ANSWER_DATA, result.data);

            // show modal about new level
            if (result.data.level !== null) {
                notificationService.newLevel(
                    result.data.level, result.data.points >= 0, getters.currentAreaCssClass
                );
            }

            // show modal about new achievements
            if (!isEmpty(result.data.achievements)) {
                notificationService.newAchievements(result.data.achievements, getters.currentAreaCssClass);
            }

            // refresh user data + progress, don't wait for it
            store.dispatch('downloadUserData');
            return result;
        });
    },

    // #### achievements
    downloadAchievements({ commit }) {
        return apiService.getAchievements().then((result) => {
            commit(types.REPLACE_ACHIEVEMENTS, result.data);
            return result.data;
        });
    },

    // #### team users leaderboard
    downloadTeamUsersLeaderboard({ commit }) {
        return apiService.getTeamUsersLeaderboard().then((result) => {
            commit(types.REPLACE_TEAM_USERS_LEADERBOARD, result.data);
            return result.data;
        });
    },

    // #### area teams leaderboard
    downloadAreaTeamsLeaderboard({ commit }) {
        return apiService.getAreaTeamsLeaderboard().then((result) => {
            commit(types.REPLACE_AREA_TEAMS_LEADERBOARD, result.data);
            return result.data;
        });
    },

    downloadAllUsersLeaderboard({ commit }) {
        return apiService.getAllUsersLeaderboard().then((result) => {
            commit(types.REPLACE_ALL_USERS_LEADERBOARD, result.data);
            return result.data;
        });
    },

    // #### area + team battles leaderboards
    downloadAreaBattlesLeaderboard({ commit }) {
        return apiService.getAreaBattlesLeaderboard().then((result) => {
            commit(types.REPLACE_AREA_BATTLES_LEADERBOARD, result.data);
            return result.data;
        });
    },

    downloadTeamBattlesLeaderboard({ commit }) {
        return apiService.getTeamBattlesLeaderboard().then((result) => {
            commit(types.REPLACE_TEAM_BATTLES_LEADERBOARD, result.data);
            return result.data;
        });
    },

    testSwal({ getters }) {
        notificationService.notify({ title : "test", body : "testBody" }, () => {});

        // level up
        notificationService.newLevel({
            name : "Mrtekovboj",
            image_path : "http://pipsum.com/75x75.jpg",
        }, true, getters.currentAreaCssClass);

        // level down
        notificationService.newLevel({
            name : "Menekovboj",
            image_path : "http://pipsum.com/450x450.jpg",
        }, false, getters.currentAreaCssClass);

        // battle - user being challenged
        notificationService.battleUserChallenged(
            "Anezka Otakarova", 200, getters.currentAreaCssClass, (result) => {
                console.log("CALLBACK battleUserChallenged ###########################");
                console.log(result);
                // if (result === true) {
                //     store.dispatch("acceptBattle");
                //     // router.push("/battles");
                // } else if (result === "cancel") {
                //     store.dispatch("declineBattle");
                // }
            }
        );
        notificationService.battleOpponentDeclined(
            "Premysl Otakar", "Anezka Otakarova", getters.currentAreaCssClass, (result) => {
                console.log("CALLBACK battleOpponentDeclined ###########################");
                console.log(result);
            }
        );
        notificationService.battleOpponentAccepted(
            "Premysl Otakar", "Anezka Otakarova", getters.currentAreaCssClass, (result) => {
                console.log("CALLBACK battleOpponentAccepted ###########################");
                console.log(result);
                // router.push("/battles");
            }
        );
        notificationService.battleWon(
            "Premysl Otakar", "Anezka Otakarova", 200, getters.currentAreaCssClass, (result) => {
                console.log("CALLBACK battleWon ###########################");
                console.log(result);
            }
        );
        notificationService.battleLost(
            "Premysl Otakar", "Anezka Otakarova", 200, getters.currentAreaCssClass, (result) => {
                console.log("CALLBACK battleLost ###########################");
                console.log(result);
            }
        );
        notificationService.battleTie(
            "Premysl Otakar", "Anezka Otakarova", 200, getters.currentAreaCssClass, (result) => {
                console.log("CALLBACK battleTie ###########################");
                console.log(result);
            }
        );

        // new achievements
        notificationService.newAchievements([
            {
                name : "achievement one",
                description : "description description description description description description ",
                image_path : "http://pipsum.com/110x75.jpg",
                category_name : "asd asd sad sad "
            },
            {
                name : "achievement two with a quite long name",
                description : "description description description description description description description description description description description description description description",
                image_path : "http://pipsum.com/75x91.jpg",
                category_name : null
            },
            {
                name : "achievement three asd sad ",
                description : "description ",
                image_path : "http://pipsum.com/101x80.jpg",
                category_name : " vereu veru veu veru ve vue vev e "
            }
        ], getters.currentAreaCssClass);

        // Knowledge tests
        notificationService.knowledgeTestGeneric(
            "Test 1",
            "Chystáš se spustit znalostní test.",
            "Knowledge test description",
            getters.currentAreaCssClass,
            () => {}
        );
        notificationService.knowledgeTestTimeout("Test 1", getters.currentAreaCssClass, () => {});
    },
};

const mutations = {
    [types.REPLACE_AREAS](state, newAreas) {
        state.areas = keyBy(newAreas, "id");
    },

    [types.REPLACE_CURRENT_ITEM](state, newItem) {
        state.currentItem = isEmpty(newItem) ? {} : extend({}, state.currentItem, newItem);
    },

    [types.REPLACE_LAST_ANSWER_DATA](state, answerData) {
        Vue.set(state, "lastAnswerData", clone(answerData));
    },

    [types.REPLACE_ACHIEVEMENTS](state, achievements) {
        state.achievements = achievements;
    },

    [types.REPLACE_TEAM_USERS_LEADERBOARD](state, teamUsersLeaderboard) {
        state.teamUsersLeaderboard = teamUsersLeaderboard;
    },

    [types.REPLACE_AREA_TEAMS_LEADERBOARD](state, areaTeamsLeaderboard) {
        state.areaTeamsLeaderboard = areaTeamsLeaderboard;
    },

    [types.REPLACE_ALL_USERS_LEADERBOARD](state, allUsersLeaderboard) {
        state.allUsersLeaderboard = allUsersLeaderboard;
    },

    [types.REPLACE_AREA_BATTLES_LEADERBOARD](state, areaBattlesLeaderboard) {
        state.areaBattlesLeaderboard = areaBattlesLeaderboard;
    },

    [types.REPLACE_TEAM_BATTLES_LEADERBOARD](state, teamBattlesLeaderboard) {
        state.teamBattlesLeaderboard = teamBattlesLeaderboard;
    },
};

export default {
    state : moduleState,
    getters : moduleGetters,
    actions,
    mutations
};
