import Vue from "vue";
import forEach from "lodash/forEach";
import isFunction from "lodash/isFunction";
import swal from "sweetalert2";

import notificationImage from 'variantImages/notification.png';
import router from "src/router/router";
import apiService from "src/services/api/apiService";
import store from "src/store/store";
import userMessages from "src/services/userMessages";
import log from "src/services/logger";
const logTag = "notificationService";

// holding the notification polling interval handle so that we can clear it etc.
let notificationPollingInProgress = false;
let notificationTimeoutId;

// holding info for custom modal queue as swal is not great in this
let modalQueue = [];
let modalIsVisible = false;

// try to access the Notification API and get the necessary permission to show desktop-like notifications
const acquirePermission = function() {
    if (typeof Notification !== "undefined") {
        return requestPermission();

    // in IE, 'Notification' object is undefined at first - probably while the system plugin is being
    // loaded. We need to wait for it
    } else {
        log.log(logTag, "'Notification' is undefined, waiting");
        return new Promise((resolve) => setTimeout(resolve, 5000)).then(acquirePermission);
    }
};

const requestPermission = function() {
    return new Promise((resolve, reject) => {

        if (Notification.permission === "granted") {
            log.log(logTag, "Notification permission already granted, doing nothing");
            resolve(true);

        } else {
            log.log(logTag, "Requesting Notification permission");
            Notification.requestPermission((response) => {
                if (response === "granted") {
                    log.log(logTag, "Notification permission granted!");
                    resolve(true);
                } else {
                    log.log(logTag, "Notification permission blocked!");
                    reject(false);
                }
            });
        }
    });
};

// desktop notifications - Notification API
const notify = function(
    { title = "", body = "" },
    onClickCallback,
    timeout = config.notifications.standardNotificationDuration
) {

    acquirePermission()

    // show the actual notification
    .then(() => {
        log.log(logTag, "Showing a notification. Title: " + title + ", body: " + body + ", time: " + timeout);

        // @note we're using one notificationImage for all the notification types and areas.
        // If we want customizable images for areas, we need to get the link from server
        let n = new Notification(title, {
            body : body,
            icon : notificationImage,
            requireInteraction : timeout === 0
        });

        // we need to hide the notification manually as not every browser does this
        if (timeout !== 0) {
            setTimeout(function() {
                // there should be "n.close.bind(n)" according to MDN but it doesn"t work
                // in IE11 (says bind is undefined)
                n.close();
            }, timeout);
        }

        // call a callback on click
        n.onclick = () => { onClickCallback(n); };
    })

    // we can't do anything, this means the user has blocked the permission. He needs to allow it manually
    .catch(() => console.error("Please check the permissions for Notifications and allow them."));
};

// polling server for new notifications
const startNotificationPolling = function() {
    // if we are already polling for notifications, do nothing
    if (notificationPollingInProgress) {
        log.log(logTag, "Notifications polling already started!");
        return;
    }
    notificationPollingInProgress = true;

    log.log(logTag, "Starting notifications polling.");
    getNotificationsPeriodically();
};

// stop the last timeout + stop the whole polling
const stopNotificationPolling = function() {
    if (notificationPollingInProgress) {
        log.log(logTag, "Stopping notifications polling, last timeout ID: " + notificationTimeoutId);
        // stop the last timeout
        clearTimeout(notificationTimeoutId);
        notificationTimeoutId = undefined;

        notificationPollingInProgress = false;
    }
};

// instead of setInterval, we use "recursion" - the function sets timeout when it finishes. This prevents
// the polls to stack in case of long server responses etc
const getNotificationsPeriodically = function() {
    getAndProcessNotifications()
    .then(() => {
        if (notificationPollingInProgress) {
            notificationTimeoutId = setTimeout(
                getNotificationsPeriodically, config.notifications.notificationPollingInterval
            );
        }
    });
};

const getAndProcessNotifications = function() {
    log.log(logTag, "Getting and processing notifications...", true);
    return apiService.getNotifications().then(result => {
        // @note: we take only first three notifications (browser won't show more anyway). They are
        // sorted by their "time sent" by server. Then we reverse them so that the newest one is on top
        // of the three shown notifications
        let slicedNotifications = result.data.slice(0, 3).reverse();

        forEach(slicedNotifications, function(dataToShow) {
            // determine where to redirect after the user clicks on notification or whether to
            // show a modal
            switch (dataToShow.type) {
                // #### web notifications ####
                case "items" :
                    notify(dataToShow, (notification) => {
                        log.log(logTag, "Item notification clicked!");
                        window.focus();         // does not work in IE
                        notification.close();   // in IE, this is done automatically anyway
                        router.push("/item-dashboard");
                    }, 0);
                    break;

                case "message" :
                default :
                    notify(dataToShow, (notification) => {
                        log.log(logTag, "Message notification clicked!");
                        notification.close();
                    }, 0);
                    break;

                // #### modals ####
                case "challengedForBattle" :
                    store.dispatch("downloadBattles");
                    battleUserChallenged(
                        dataToShow.opponentName, dataToShow.declinePoints, store.getters.currentAreaCssClass,
                        (modalResult) => {
                            if (modalResult === true) {
                                store.dispatch("acceptBattle").then(response => {
                                    store.dispatch("showSuccessMessage", userMessages.afterBattleAccept(response));
                                })
                                .catch(reason => {
                                    store.dispatch("showErrorMessage", userMessages.afterBattleAccept(reason));
                                });
                                router.push("/battles").catch(() => {});

                            } else if (modalResult === "cancel") {
                                store.dispatch("declineBattle").then(response => {
                                    store.dispatch("showSuccessMessage", userMessages.afterBattleDecline(response));
                                })
                                .catch(reason => {
                                    store.dispatch("showErrorMessage", userMessages.afterBattleDecline(reason));
                                });
                            }
                            // a click outside the modal just closes it, no decline
                        }
                    );
                    break;

                // the opponent has accepted user's challenge
                case "battleAccepted" :
                    store.dispatch("downloadBattles");
                    battleOpponentAccepted(
                        store.getters.user.name, dataToShow.opponentName, store.getters.currentAreaCssClass,
                        () => { router.push("/battles").catch(() => {}); }
                    );
                    break;

                // the opponent has declined user's challenge
                case "battleDeclined" :
                    store.dispatch("downloadBattles");
                    battleOpponentDeclined(
                        store.getters.user.name, dataToShow.opponentName, store.getters.currentAreaCssClass
                    );
                    break;

                case "battleWon" :
                    store.dispatch("downloadBattles");
                    store.dispatch("downloadUserData");
                    battleWon(
                        store.getters.user.name, dataToShow.opponentName, dataToShow.points,
                        store.getters.currentAreaCssClass
                    );
                    break;

                case "battleLost" :
                    store.dispatch("downloadBattles");
                    store.dispatch("downloadUserData");
                    battleLost(
                        store.getters.user.name, dataToShow.opponentName, dataToShow.points,
                        store.getters.currentAreaCssClass
                    );
                    break;

                case "battleTie" :
                    store.dispatch("downloadBattles");
                    store.dispatch("downloadUserData");
                    battleTie(
                        store.getters.user.name, dataToShow.opponentName, dataToShow.points,
                        store.getters.currentAreaCssClass
                    );
                    break;

            }
        });
    })

    // "unauthorized" shouldn't happen here as that will log the user out
    .catch((reason) => {
        log.log(logTag, "Notification polling error!");
        log.log(logTag, reason);
    });
};

// If there is no modal in queue, show it immediately. If there is, add the next modal to the end of
// the queue
// @note: queue() returns a promise but insertQueueStep doesn't
// const showModal = function(modalObject) {
//     if (swal.getQueueStep() === null) {
//         return swal.queue([modalObject]);
//     } else {
//         return swal.insertQueueStep(modalObject);
//     }
// };

const queueModal = function(modalObject, callback) {
    modalQueue.push({ modalObject, callback });
    showNextModal();
};

const showNextModal = function() {
    // @note we can't use swal.isVisible() as that is still true in then(), unbeknowst to me why
    if (!modalIsVisible) {
        showModalWithCallback(modalQueue.shift()).then(() => {
            if (modalQueue.length > 0) {
                showNextModal();
            }
        });
    }
};

const showModalWithCallback = function({ modalObject, callback }) {
    modalIsVisible = true;

    return swal(modalObject).then(result => {
        if (isFunction(callback)) {
            callback(result);
        }
        modalIsVisible = false;
        return result;
    })

    .catch(reason => {
        if (isFunction(callback)) {
            callback(reason);
        }
        modalIsVisible = false;
        return reason;
    });
};


const newLevel = function(level, isThisHigherLevel, areaCssClass) {
    log.log(logTag, "Showing notification for new level: " + level.name + ", higher: " + isThisHigherLevel);

    let title, text = "";

    if (isThisHigherLevel) {
        title = Vue.i18n.translate("MODAL_LEVEL_UP_TITLE");
        text = Vue.i18n.translate("MODAL_LEVEL_UP_TEXT");

    } else {
        title = Vue.i18n.translate("MODAL_LEVEL_DOWN_TITLE");
        text = Vue.i18n.translate("MODAL_LEVEL_DOWN_TEXT");
    }

    let html = `<div class="inner-content">
                    <h2>${title}</h2>
                    <img src="${level.image_path}"
                         alt="Obrázek levelu" class="img img-responsive">
                    <p>${text}</p>
                    <p>
                        ${Vue.i18n.translate("MODAL_LEVEL_YOUR_NEW")}
                        <span class="level-name">${level.name}.</span>
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("MODAL_LEVEL_BUTTON"),
        confirmButtonClass  : "btn brand-secondary-btn",
        customClass         : "ifo-modal level " + " area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject);
};


const newAchievements = function(achievements, areaCssClass) {
    log.log(logTag, "Showing notification for new achievements (" + achievements.length + ")");

    let title = Vue.i18n.translate("MODAL_ACHIEVEMENT_TITLE");
    if (achievements.length > 1) {
        title = Vue.i18n.translate("MODAL_ACHIEVEMENT_TITLE_PLURAL");
    }

    let html = `<div class="inner-content">
                    <h2>${title}</h2>
                    <div class="bg-img img-responsive"></div>
                    <p>${Vue.i18n.translate("MODAL_ACHIEVEMENT_TEXT")}</p>
                        <ul class="list-group achievements-list">`;

    // ES6 string literals for teh win
    forEach(achievements, function(achievement) {
        html += `<li class="list-group-item list-item">
                    <div class="row list-item-row">
                        <div class="col-xs-3 col-sm-4 col-lg-3 img-wrapper">
                            <img src="${achievement.image_path}"
                                 class="img img-responsive" alt="Obrázek trofeje">
                        </div>
                        <div class="col-xs-9 col-sm-8 col-lg-9">
                            <div class="row">
                                <div class="col-xs-12 col-sm-7 title-wrapper">
                                    <h4>${achievement.name}</h4>
                                </div>
                                <div v-if="tag" class="col-xs-12 col-sm-5 tag">
                                    ${achievement.category_name || ''}
                                </div>
                            </div>
                            <p>${achievement.description}</p>
                        </div>
                    </div>
                </li>`;
    });

    html += `</ul></div>`;

    let swalObject = {
        // title               : null,
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("MODAL_LEVEL_BUTTON"),
        confirmButtonClass  : "btn brand-secondary-btn",
        customClass         : "ifo-modal achievements " + " area-" + areaCssClass,
    };

    // show or enqueue this modal
    queueModal(swalObject);
};


const battleUserChallenged = function(opponent, points, areaCssClass, callback) {
    log.log(logTag, "Showing notification for new battle challenge, area " + areaCssClass);

    let html = `<div class="inner-content">
                    <div class="bg-img img-responsive"></div>
                    <h3>
                        <span class="opponent-name">${opponent}</span>
                        ${Vue.i18n.translate("BATTLES_CHALLENGED_TITLE")}
                    </h3>
                    <p>
                        ${Vue.i18n.translate("BATTLES_CHALLENGED_TEXT")}
                        ${Vue.i18n.translate("BATTLES_CHALLENGED_POINTS", { variable : points })}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : true,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("BATTLES_ACCEPT"),
        confirmButtonClass  : "btn primary-btn",
        cancelButtonText    : Vue.i18n.translate("BATTLES_REJECT"),
        cancelButtonClass   : "btn secondary-btn",
        customClass         : "ifo-modal battles battles-challenged area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};

const battleOpponentAccepted = function(user, opponent, areaCssClass, callback) {
    log.log(logTag, "Showing notification for opponent accepting battle, area " + areaCssClass);

    let html = `<div class="inner-content">
                    <h3>
                        ${user} ${Vue.i18n.translate("BATTLES_VS")} 
                        <span class="opponent-name">${opponent}</span>
                    </h3>
                    <div class="bg-img img-responsive"></div>
                    <h3>
                        <span class="opponent-name">${opponent}</span>
                        ${Vue.i18n.translate("BATTLES_OPPONENT_ACCEPTED_TITLE")}
                    </h3>
                    <p>
                        ${Vue.i18n.translate("BATTLES_OPPONENT_ACCEPTED_TEXT")}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("BATTLES_OK"),
        confirmButtonClass  : "btn primary-btn",
        customClass         : "ifo-modal battles battles-opponent-accepted area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};

const battleOpponentDeclined = function(user, opponent, areaCssClass, callback) {
    log.log(logTag, "Showing notification for opponent declining battle, area " + areaCssClass);

    let html = `<div class="inner-content">
                    <h3>
                        ${user} ${Vue.i18n.translate("BATTLES_VS")} 
                        <span class="opponent-name">${opponent}</span>
                    </h3>
                    <div class="bg-img img-responsive"></div>
                    <h3>
                        ${Vue.i18n.translate("BATTLES_OPPONENT_DECLINED_TITLE")}
                    </h3>
                    <p>
                        ${Vue.i18n.translate("BATTLES_OPPONENT_DECLINED_TEXT")}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("BATTLES_OK"),
        confirmButtonClass  : "btn primary-btn",
        customClass         : "ifo-modal battles battles-opponent-declined area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};

const battleWon = function(user, opponent, points, areaCssClass, callback) {
    log.log(logTag, "Showing notification for winning battle, area " + areaCssClass);

    let html = `<div class="inner-content">
                    <h3>
                        ${user} ${Vue.i18n.translate("BATTLES_VS")} 
                        ${opponent}
                    </h3>
                    <div class="bg-img img-responsive"></div>
                    <h2>
                        ${Vue.i18n.translate("BATTLES_WON_TITLE")}
                        +${Vue.i18n.translate("POINTS_GAINED", { points })}
                    </h2>
                    <p>
                        ${Vue.i18n.translate("BATTLES_WON_TEXT")}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("BATTLES_OK"),
        confirmButtonClass  : "btn primary-btn",
        customClass         : "ifo-modal battles battles-won area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};

const battleLost = function(user, opponent, points, areaCssClass, callback) {
    log.log(logTag, "Showing notification for losing battle, area " + areaCssClass);

    let html = `<div class="inner-content">
                    <h3>
                        ${user} ${Vue.i18n.translate("BATTLES_VS")} 
                        ${opponent}
                    </h3>
                    <div class="bg-img img-responsive"></div>
                    <h2>
                        ${Vue.i18n.translate("BATTLES_LOST_TITLE")}
                        ${Vue.i18n.translate("POINTS_GAINED", { points })}
                    </h2>
                    <p>
                        ${Vue.i18n.translate("BATTLES_LOST_TEXT")}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("BATTLES_OK"),
        confirmButtonClass  : "btn primary-btn",
        customClass         : "ifo-modal battles battles-lost area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};

const battleTie = function(user, opponent, points, areaCssClass, callback) {
    log.log(logTag, "Showing notification for battle tie, area " + areaCssClass);

    let html = `<div class="inner-content">
                    <h3>
                        ${user} ${Vue.i18n.translate("BATTLES_VS")} 
                        ${opponent}
                    </h3>
                    <div class="bg-img img-responsive"></div>
                    <h2>
                        ${Vue.i18n.translate("BATTLES_TIE_TITLE")}
                        ${Vue.i18n.translate("POINTS_GAINED", { points })}
                    </h2>
                    <p>
                        ${Vue.i18n.translate("BATTLES_TIE_TEXT")}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("BATTLES_OK"),
        confirmButtonClass  : "btn primary-btn",
        customClass         : "ifo-modal battles battles-tie area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};


const knowledgeTestGeneric = function(
    testTitle, titleText, descriptionText, areaCssClass, callback, confirmBtnText, cancelBtnText
) {
    log.log(logTag, "Showing notification before start of Knowledge Test attempt, area " + areaCssClass);

    const confirmText = confirmBtnText || Vue.i18n.translate("KNOWLEDGE_TEST_MODAL_ENTER");
    const cancelText = cancelBtnText || Vue.i18n.translate("KNOWLEDGE_TEST_MODAL_CANCEL");

    let html = `<div class="inner-content">
                    <h4>
                        ${testTitle}
                    </h4>
                    <h3>
                       ${titleText} 
                    </h3>
                    <p>
                        ${descriptionText}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : true,
        buttonsStyling      : false,
        confirmButtonText   : confirmText,
        confirmButtonClass  : "btn primary-btn",
        cancelButtonText    : cancelText,
        cancelButtonClass   : "btn secondary-btn",
        customClass         : "ifo-modal knowledge-test knowledge-test-generic area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};

const knowledgeTestTimeout = function(testTitle, areaCssClass, callback) {
    log.log(logTag, "Showing Knowledge Test timeout notification; area " + areaCssClass);

    let html = `<div class="inner-content">
                    <h4>
                        ${testTitle}
                    </h4>
                    <h3>
                        ${Vue.i18n.translate("KNOWLEDGE_TEST_TIMEOUT_TITLE")} 
                    </h3>
                    <p>
                        ${Vue.i18n.translate("KNOWLEDGE_TEST_TIMEOUT_DESC")}
                    </p>
                </div>`;

    let swalObject = {
        html                : html,
        showCancelButton    : false,
        buttonsStyling      : false,
        confirmButtonText   : Vue.i18n.translate("KNOWLEDGE_TEST_TIMEOUT_BTN"),
        confirmButtonClass  : "btn primary-btn",
        customClass         : "ifo-modal knowledge-test knowledge-test-timeout area-" + areaCssClass
    };

    // show or enqueue this modal
    queueModal(swalObject, callback);
};


export default {
    acquirePermission,

    notify,
    startNotificationPolling,
    stopNotificationPolling,

    // modals
    newLevel,
    newAchievements,

    // battles - modals
    battleUserChallenged,
    battleOpponentAccepted,
    battleOpponentDeclined,
    battleWon,
    battleLost,
    battleTie,

    // knowledge tests - modals
    knowledgeTestGeneric,
    knowledgeTestTimeout
};
