var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.knowledgeTestsArray, function (test) {
        return _c(
          "div",
          { key: test.id, staticClass: "col-sm-6 col-md-4" },
          [_c("knowledge-test-box", { attrs: { "test-id": test.id } })],
          1
        )
      }),
      _vm._v(" "),
      !_vm.areThereAnyTests
        ? _c("div", { staticClass: "col-sm-6 col-md-4" }, [
            _c("div", { staticClass: "category-box knowledge-test-box" }, [
              _c("div", { attrs: { "data-key": "sameHeightCategories" } }, [
                _c("div", { staticClass: "bg-img responsive-bg-img" }),
                _vm._v(" "),
                _c("div", { staticClass: "content-wrapper" }, [
                  _c("h3", { staticClass: "test-title" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("translate")("KNOWLEDGE_TEST_FOCUS_TITLE")
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "category-description" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_NO_TEST")) +
                        "\n                    "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "button-wrapper" }),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }