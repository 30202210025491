const translationsCs = {
    // Navigation
    "LOGIN"                             : "Přihlásit",
    "LOGOUT"                            : "Odhlásit",
    "POINTS_COUNT"                      : "Body",
    "REGISTRATION"                      : "Registrace",

    // Footer
    "CREATED_BY"                        : "Created by",
    "FOR_MORE_INFO_CONTACT"             : "Pro více informací kontaktujte",
    "FOOTER_RIGHT_TEXT"                 : "Tato stránka používá cookies",

    // page Login - texts above the form
    "LOGIN_MAIN_TITLE"                  : "Staň se hrdinou innogy",
    "LOGIN_MAIN_SUBTITLE"               : "Vstup do příběhů, díky kterým se z tebe stane innogy hrdina.",
    "LOGIN_SECONDARY_TITLE"             : "CO JSOU iHrdinové?",
    "LOGIN_SECONDARY_SUBTITLE"          : "",
    "LOGIN_EXPLANATION_FIRST_TITLE"     : "Přidej se k revoluci ve vzdělávání",
    "LOGIN_EXPLANATION_FIRST_TEXT"      : "Kdo říkal, že učení musí být šprtání a nuda? U nás to děláme jinak - hravě!",
    "LOGIN_EXPLANATION_SECOND_TITLE"    : "Časově efektivní",
    "LOGIN_EXPLANATION_SECOND_TEXT"     : "Neboj se, nechceme tě okrádat o čas. Hry ti nezaberou víc jak pár minut denně a výsledky brzy uvidíš i na své vlastní práci.",
    "LOGIN_EXPLANATION_THIRD_TITLE"     : "Hravá forma",
    "LOGIN_EXPLANATION_THIRD_TEXT"      : "Čeká tě několik příběhů, ve kterých se ujmeš role hlavního hrdiny. Probuď svůj potenciál a ukaž ostatním, co v tobě je!",
    "LOGIN_SECTION_TITLE"               : "Staň se taky hrdinou",
    "LOGIN_SECTION_SUBTITLE"            : "Rozvíjej své schopnosti a bojuj se svými kolegy za lepší svět pod vlajkou innogy.",

    // page Login -> Login form component
    "LOGIN_TITLE"                       : "Kdo našel odvahu vstoupit na tuhle stezku?",
    "LOGIN_BUTTON"                      : "Přihlásit",
    "EMAIL"                             : "Email",
    "PASSWORD"                          : "Heslo",
    "LOGIN_FAILED_CREDENTIALS"          : "Nesprávné přihlasovací údaje!",
    "LOGIN_FAILED_ERROR"                : "Nastala chyba. Zkus to ještě jednou",
    "LOGIN_FAILED_NOT_FOUND"            : "Nastala chyba. Zkus to ještě jednou",
    "LOGIN_SUCCESSFUL"                  : "Jsi přihlášen!",

    // page Login -> Register form component
    "REGISTER"                          : "Ještě nemáš konto? Zaregistruj se",
    "REGISTER_BUTTON"                   : "Odeslat",
    "NAME"                              : "Jméno",
    "REGISTRATION_SUCCESSFUL"           : "Registrace proběhla úspěšně. Na tvůj email jsme odeslali aktivační link.",
    "REGISTRATION_FAILED_ERROR"         : "Nastala chyba. Zkus ještě jednou",
    "REGISTRATION_FAILED_VALIDATION"    : "Nesprávné údaje",
    "BACK"                              : "Zpět",

    // after user clicks on activation link in email
    "ACTIVATION_SUCCESSFUL"             : "Aktivace proběhla úspěšně! Jsi přihlášen.",
    "ACTIVATION_FAILED_NOT_FOUND"       : "Aktivace selhala - užívatel neexistuje!",
    "ACTIVATION_FAILED_VERIFIED"        : "Aktivace selhala - tenhle užívatel je už aktivní!",
    "ACTIVATION_FAILED_VALIDATION"      : "Aktivace selhala - nesprávný aktivační link!",
    "ACTIVATION_FAILED_ERROR"           : "Aktivace selhala!",

    // page Login -> Forgotten password
    "FORGOTTEN_PASSWORD_TITLE"          : "Zapomenuté heslo",
    "FORGOTTEN_PASSWORD"                : "Zapomněl jsi své heslo?",
    "FORGOTTEN_PASS_SUBMIT"             : "Odeslat",
    "FORGOTTEN_PASS_SUCCESS"            : "Na tvůj email jsme odeslali aktivační link.",
    "FORGOTTEN_PASS_FAILED_ERROR"       : "Nastala chyba. Zkus ještě jednou",
    "FORGOTTEN_PASS_FAILED_VALIDATION"  : "Tento email nemáme v databázi!",

    // page Reset Password
    "RESET_PASS_MAIN_TITLE"             : "I superhrdinové občas potřebují pomoc!",
    "RESET_PASS_MAIN_SUBTITLE"          : "Zdá se že máš problém se svým heslem? Nevadí, pomůžeme ti.",
    "RESET_PASS_SECONDARY_TITLE"        : "Teď si můžeš vytvořit nové, neprůstřelné heslo.",
    "RESET_PASS_TITLE"                  : "Resetovat heslo",
    "RESET_PASS_BUTTON"                 : "Resetovat",
    "RESET_PASS_SUCCESSFUL"             : "Tvoje heslo bylo úspěšně změněno",
    "RESET_PASS_FAILED_ERROR"           : "Nastala chyba. Zkus ještě jednou",
    "NEW_PASSWORD"                      : "Nové heslo",

    // page Profile - texts on top
    "PROFILE_MAIN_TITLE"                : "Jak se ti vede, hrdino?",
    "PROFILE_MAIN_SUBTITLE"             : "Přehled tvých úspěchů",

    // page Profile - areas' progress texts
    "PROFILE_CONTINUE"                  : "Pokračovat",
    "PROFILE_AREA_FINISHED"             : "Tohle dobrodružství je u konce",

    // page Profile - texts above profile edit fields (change password etc)
    "PROFILE_EDIT_MAIN_TITLE"           : "Tvoje údaje",

    // page Profile -> Change Password
    "CHANGE_PASS_TITLE"                 : "Změna hesla",
    "CHANGE_PASS_SUBMIT"                : "Změnit",
    "CHANGE_PASS_SUCCESS"               : "Tvoje heslo bylo úspěšně změněno",
    "CHANGE_PASS_FAILED_UNAUTHORIZED"   : "Nejsi přihlášen!",
    "CHANGE_PASS_FAILED_ERROR"          : "Nastala chyba. Zkus ještě jednou",
    "OLD_PASSWORD"                      : "Současné heslo",

    // for vue-password component - strength of the password
    "PASS_VERY_WEAK"                    : "Velmi slabé",
    "PASS_WEAK"                         : "Slabé",
    "PASS_MEDIUM"                       : "Středně silné",
    "PASS_STRONG"                       : "Silné",
    "PASS_VERY_STRONG"                  : "Velmi silné",

    // page Onboarding (rules)
    "ONBOARDING_PAGE_TITLE"             : "Pravidla",

    // page Focus (categories)
    "FOCUS_PAGE_TITLE"                  : "Zaměření",
    "FOCUS_CHOOSE_CATEGORY"             : "Poper se s tím",
    "FOCUS_CHOSEN_CATEGORY"             : "Zvoleno!",
    "FOCUS_NONE_TITLE"                  : "Je mi to jedno",
    "FOCUS_HIGHLIGHTED_CATEGORY"        : "Důležité!",
    "FOCUS_CHANGE_SUCCESS"              : "Zaměření změněno!",
    "FOCUS_CHANGE_ERROR"                : "Nastala chyba. Zkus ještě jednou",

    // page ItemDashboard
    "ITEM_DASHBOARD_PAGE_TITLE"         : "Úkoly",
    "ITEM_ANSWER_FAILED_ERROR"          : "Nastala chyba. Zkus ještě jednou",
    "TO_ACHIEVEMENTS"                   : "Všechny úspěchy",
    "EXPLANATION_HELP_TITLE"            : "Nevíš si rady?",
    "EXPLANATION_HELP_TEXT"             : "Pokud si nevíš poradit s nějakou otázkou, doporučujeme podívat se do naší znalostní báze.",
    "EXPLANATION_HELP_LINK"             : "Link na nápovědu",
    "EXPLANATION_HELP_URL"              : "http://jzb.innogy.cz/display/metodika/Home",

    // page ItemDashboard - Item component
    "ITEM_CATEGORY"                     : "Zaměření",
    "TO_ONBOARDING"                     : "Pravidla",
    "YOUR_FOCUS_IS"                     : "Máš zaměření na",
    "YOU_HAVE_NO_FOCUS"                 : "Nemáš zvolené žádné zaměření",
    "TO_FOCUS_CHANGE"                   : "Změnit",

    // page ItemDashboard - component Text Answer
    "YOUR_ANSWER"                       : "Tvoje odpověď",

    // page ItemDashboard - component Choice Answer
    "SELECT_ALL_CORRECT_ANSWERS"        : "Označ všechny správné odpovědi",
    "SELECT_ONE_CORRECT_ANSWER"         : "Označ jednu správnou odpověď",
    "SELECT_2_TO_4_CORRECT_ANSWERS"     : "Označ {count} správné odpovědi",
    "SELECT_5_AND_MORE_CORRECT_ANSWERS" : "Označ {count} správných odpovědí",
    "INCORRECT_ANSWER_COUNT"            : "Nesprávný počet odpovědí.",
    "SELECT_SOME_ANSWERS"               : "Označ všechny správné odpovědi",

    // page ItemDashboard - component AnswerButtonRow (answer/postpone)
    "ANSWER_BUTTON"                     : "Odpovědět",
    "ANOTHER_QUESTION_BUTTON"           : "Jiná otázka",

    // page ItemDashboard -> Correct Answer, Incorrect Answer component
    "CORRECT_ANSWER_TITLE"              : "Správná odpověď",
    "CORRECT_ANSWER_DESCRIPTION"        : "Správná odpověď, je vidět, kdo se vyzná!",
    "INCORRECT_ANSWER_TITLE"            : "Špatná odpověď",
    "INCORRECT_ANSWER_DESCRIPTION"      : "Někdo tě převez! Nevadí, příště se mu dostaneš na udičku!",
    "POINTS_GAINED"                     : "{points} bodů",

    // page ItemDashboard - component No Items (clean table)
    "NO_ITEMS_TITLE"                    : "Máš čistý stůl",
    "NO_ITEMS_DESCRIPTION"              : "Dnes máš čistý stůl, skvělá práce!",
    "NO_ITEMS_DESCRIPTION_LONG"         : "",
    "CHOOSE_FOCUS_FOR_TOMORROW"         : "Změnit zaměření na zítra",

    // page Achievements
    "ACHIEVEMENTS"                      : "Úspěchy",
    "ACHIEVEMENTS_MAIN_TITLE"           : "Tvé úspěchy",
    "ACHIEVEMENTS_UNLOCKED_TITLE"       : "Úspěchy, kterých jsi dosáhl",
    "ACHIEVEMENTS_LOCKED_TITLE"         : "Úspěchy, které tě čekají",
    "NO_ACHIEVEMENTS_YET"               : "Zatím nemáš žádné úspěchy!",
    "ALL_ACHIEVEMENTS_UNLOCKED"         : "Získal jsi už všechny úspěchy! Jsi hvězda!",
    "CONTINUE_PLAYING"                  : "Pokračovat ve hře",

    // page Leaderboard
    "LEADERBOARD"                       : "Žebříček",
    "LEADERBOARD_MAIN_TITLE"            : "Jak si vedeš v porovnání s ostatníma?",
    "LEADERBOARD_MAIN_SUBTITLE"         : "Posuď jaká je tvoja pozice",
    "LEADERBOARD_YOUR_RANK"             : "Tvoje pozice",
    "LEADERBOARD_EXPLANATION_TEXT"      : "Řešením případů získáváš body, za které postoupíš do vyššího levelu. Všichni máte rovné šance!",
    "LEADERBOARD_OF_TEAM_PLAYERS"       : "Žebříček hráčů ve tvém týmu",
    "LEADERBOARD_OF_AREA_TEAMS"         : "Žebříček týmů",
    "LEADERBOARD_TEAMS_SCORE"           : "Body",
    "LEADERBOARD_TEAMS_SCORE_PER_USER"  : "Na hráče",
    "LEADERBOARD_OF_ALL_PLAYERS"        : "Tvoje pozice v žebříčku všech hráčů",
    "LEADERBOARD_OF_BATTLES_IN_AREA"    : "Všechny souboje v této oblasti",
    "LEADERBOARD_OF_BATTLES_IN_TEAM"    : "Souboje ve tvém týmu",
    "LEADERBOARD_WINS"                  : "Výhry",
    "LEADERBOARD_TIES"                  : "Remízy",
    "LEADERBOARD_LOSSES"                : "Prohry",

    // User progress component
    "PROGRESS_DEFAULT_TITLE"            : "Jak se ti doposud vedlo?",
    "PROGRESS_YOUR_LEVEL"               : "Tvoje úroveň:",
    "PROGRESS_ANSWERED_ITEMS"           : "Vyřešené úkoly:",
    "PROGRESS_YOUR_RANK"                : "Tvoje pozice:",
    "TO_LEADERBOARD"                    : "Žebříček",
    "PROGRESS_YOUR_ACHIEVEMENTS"        : "Úspěchy:",
    "PROGRESSBAR_TITLE"                 : "Postup v kariéře",

    // page Error - displaying serious errors
    "ERROR_MAIN_TITLE"                  : "Nastala chyba",

    // page Not Found - redirect for non-existing pages
    "NOT_FOUND_MAIN_TITLE"              : "Stránka nenalezena",
    "NOT_FOUND_MAIN_TEXT"               : "Tahle stránka neexistuje.",
    "NOT_FOUND_REDIRECT_BUTTON"         : "Chci odtud pryč",

    // new level modal
    "MODAL_LEVEL_BUTTON"                : "Ok",
    "MODAL_LEVEL_UP_TITLE"              : "Vyšší level! Tak to je jízda.",
    "MODAL_LEVEL_UP_TEXT"               : "Pro tebe neexistují žádné limity.",
    "MODAL_LEVEL_DOWN_TITLE"            : "Ztratil jsi level!",
    "MODAL_LEVEL_DOWN_TEXT"             : "Co se dá dělat.",
    "MODAL_LEVEL_YOUR_NEW"              : "Tvůj nový level je",

    // new achievement modal
    "MODAL_ACHIEVEMENT_BUTTON"          : "Ok",
    "MODAL_ACHIEVEMENT_TITLE"           : "Získáváš trofej! Bezva!",
    "MODAL_ACHIEVEMENT_TITLE_PLURAL"    : "Získáváš trofeje! Bezva!",
    "MODAL_ACHIEVEMENT_TEXT"            : "Podařilo se ti sáhnout na úspěch! Jen tak dál!",

    // page Battles (added 04/2018)
    "BATTLES_PAGE_TITLE"                : "Souboje",
    "BATTLES_ARENA"                     : "Aréna",
    "BATTLES_CONTINUE_BATTLE"           : "Pokračovat v souboji!",
    // Battles - component "No Battle" (before battle)
    "BATTLES_NO_BATTLE_TITLE_1"         : "Prozatím nejsi v žádném souboji.",
    "BATTLES_NO_BATTLE_TITLE_2"         : "Nesvrbí tě prsty?",
    "BATTLES_NO_BATTLE_TEXT"            : "Zvol si soupeře.",
    "BATTLES_OPPONENT_SEARCH_PH"        : "Zadej jméno soupeře",
    "BATTLES_OPPONENT_SEARCH_NOT_FOUND" : "Soupeř neexistuje nebo je právě v souboji!",
    "BATTLES_OPPONENT_IS_BUSY"          : "Uživatel je momentálně v jiném souboji, vyber jiného uživatele.",
    // Battles - component "Waiting for Opponent"
    "BATTLES_WAITING_TITLE"             : "Čekáme, jestli {variable} nepráskne do bot.",
    "BATTLES_WAITING_TEXT_1"            : "Tvůj protivník má 24 hodin na to, zvednout hozenou rukavici.",
    "BATTLES_WAITING_TEXT_2"            : "Když tvou výzvu přijme, budeme tě informovat v e-mailu a souboj začne. Souboj potrvá",
    "BATTLES_WAITING_24H"               : "24 hodin",
    // Battles - component "Waiting for User"
    "BATTLES_CHALLENGED_TITLE"          : "tě vyzývá na souboj!",
    "BATTLES_CHALLENGED_TEXT"           : "Potvrď svůj zájem, nebo to nech ležet. Máš 24 hodin.",
    "BATTLES_CHALLENGED_POINTS"         : "Pamatuj, že odmítnutí znamená {variable} bodů.",
    "BATTLES_ACCEPT"                    : "Příjmout",
    "BATTLES_REJECT"                    : "Odmítnout",
    // Battles - component "Battle in Progress"
    "BATTLES_IN_PROGRESS_TITLE"         : "Probíhá souboj",
    "BATTLES_IN_PROGRESS_SUBTITLE"      : "Snaž se to neprostřelit.",
    // Battles - component "Battle in Progress", component "Battle Progress"
    "BATTLES_PROGRESS_QUESTION_NO"      : "Otázka č. {variable}",
    "BATTLES_QUESTION_BOTH_CORRECT"     : "Remíza",
    "BATTLES_QUESTION_BOTH_INCORRECT"   : "To šlo vedle",
    "BATTLES_QUESTION_WON"              : "Zásah!",
    "BATTLES_QUESTION_LOST"             : "Zranění!",
    "BATTLES_QUESTION_WAIT"             : "Čekáme na soupeře",
    // Battles - component "Battle in Progress", component "Battle Question"
    "BATTLES_QUESTION_SEND_ANSWER"      : "Odeslat řešení",
    // Battles - user messages
    "BATTLE_CHALLENGE_ERROR"            : "Nastala chyba. Zkus ještě jednou",
    "BATTLE_ACCEPT_SUCCESS"             : "Souboj zahájen!",
    "BATTLE_ACCEPT_ERROR"               : "Nastala chyba. Zkus ještě jednou",
    "BATTLE_DECLINE_SUCCESS"            : "Souboj odmítnut.",
    "BATTLE_DECLINE_ERROR"              : "Nastala chyba. Zkus ještě jednou",
    "BATTLE_ANSWER_ERROR"               : "Nastala chyba. Zkus ještě jednou",
    // Battles - modal Opponent Accepted
    "BATTLES_OPPONENT_ACCEPTED_TITLE"   : "přijímá tvoji výzvu na souboj!",
    "BATTLES_OPPONENT_ACCEPTED_TEXT"    : "Jdi na stránku Souboje a můžeš začít s otázkami.",
    // Battles - modal Opponent Declined
    "BATTLES_OPPONENT_DECLINED_TITLE"   : "Tvůj protivník práskl do kalhot.",
    "BATTLES_OPPONENT_DECLINED_TEXT"    : "Tady z toho už nic nebude. Radši vyvolej další souboj nebo zde vystojíš díru.",
    "BATTLES_OK"                        : "OK",
    "BATTLES_VS"                        : "vs.",
    // Battles - modal Battle Won
    "BATTLES_WON_TITLE"                 : "Vyhrál jsi!",
    "BATTLES_WON_TEXT"                  : "Tvé jméno se teď vyslovuje s respektem a možná tě někdo pozve na skleničku.",
    // Battles - modal Battle Lost
    "BATTLES_LOST_TITLE"                : "Prohra!",
    "BATTLES_LOST_TEXT"                 : "Nevadí, příště to určitě dáš! A krom toho, jizvy jsou sexy. Se říká.",
    // Battles - modal Battle Tie
    "BATTLES_TIE_TITLE"                 : "Remíza!",
    "BATTLES_TIE_TEXT"                  : "Otázkou je, jestli jste oba tak dobří, nebo mizerní.",
    // Battles - User Progress component
    "PROGRESS_YOUR_BATTLES"             : "Souboje",

    // Knowledge Tests (added 06/2019)
    // Knowledge Test box - tile on "Focus" page
    "KNOWLEDGE_TEST_FOCUS_TITLE"        : "Znalostní test",
    "KNOWLEDGE_TEST_DEADLINE"           : "Deadline",
    "KNOWLEDGE_TEST_ATTEMPTS_0"         : "Nemáš už žádné pokusy.",
    "KNOWLEDGE_TEST_ATTEMPTS_1"         : "Máš ještě 1 pokus.",
    "KNOWLEDGE_TEST_ATTEMPTS_2_4"       : "Máš ještě {count} pokusy.",
    "KNOWLEDGE_TEST_ATTEMPTS_5_MORE"    : "Máš ještě {count} pokusů.",
    "KNOWLEDGE_TEST_NO_TEST"            : "V současnosti není otevřený žádný znalostní test.",
    "KNOWLEDGE_TEST_UNFINISHED_ATTEMPT" : "Máš neukončený pokus! Čas běží!",
    "KNOWLEDGE_TEST_YOUR_RESULT"        : "Tvoje skóre:",
    "KNOWLEDGE_TEST_PREVIOUS_SUCCESS"   : "Splněno",
    "KNOWLEDGE_TEST_PREVIOUS_FAIL"      : "Nesplněno",
    "KNOWLEDGE_TEST_PREVIOUS_MANUAL"    : "Vyhodnocuje se",
    "KNOWLEDGE_TEST_START_ATTEMPT"      : "Spusť test",
    "KNOWLEDGE_TEST_CONTINUE_ATTEMPT"   : "Pokračuj v testu",
    "KNOWLEDGE_TEST_LOADING"            : "Test se načítá...",
    // error messages shown when user clicks on "Start test"
    "KNOWLEDGE_TEST_INACTIVE"           : "Tento test je již po deadlinu.",
    "KNOWLEDGE_TEST_TIMEOUT"            : "Čas na tento pokus vypršel!",
    "KNOWLEDGE_TEST_NO_ATTEMPTS"        : "Nemáš už žádné pokusy.",
    "KNOWLEDGE_TEST_START_ERROR"        : "Nastala chyba. Zkus ještě jednou",
    "KNOWLEDGE_TEST_ATTEMPT_ERROR"      : "Nastala chyba. Zkus ještě jednou",
    // Main Test page - test video
    "KNOWLEDGE_TEST_VIDEO_TO_ITEMS"     : "Pokračovat na otázky",
    "KNOWLEDGE_TEST_VIDEO_TO_VIDEO"     : "Zpět na video",
    "KNOWLEDGE_TEST_ATT_DOWNLOAD"       : "Stáhnout prezentaci",
    // Main Test page - questions & user answers
    "KNOWLEDGE_TEST_MAIN_TITLE"         : "Znalostní test",
    "KNOWLEDGE_TEST_ATTEMPT"            : "Pokus",
    "KNOWLEDGE_TEST_TIME_REMAINING"     : "Čas do konce:",
    "KNOWLEDGE_TEST_NEXT_BTN"           : "Další otázka",
    "KNOWLEDGE_TEST_PREV_BTN"           : "Předchozí otázka",
    "KNOWLEDGE_TEST_QUESTION"           : "Otázka",
    "KNOWLEDGE_TEST_SUBMIT"             : "Vyhodnotit test",
    "KNOWLEDGE_TEST_SUBMIT_TEXT_PH"     : "Začni psát zde",
    "KNOWLEDGE_TEST_ANSWER"             : "Odpovědět",
    // Test results page
    "KNOWLEDGE_TEST_FINISH_ERROR"       : "Nastala chyba!",
    "KNOWLEDGE_TEST_SUCCESS"            : "Prošel jsi testem! Gratulujeme!",
    "KNOWLEDGE_TEST_FAIL"               : "Neprošel jsi testem, škoda!",
    "KNOWLEDGE_TEST_MANUAL_RESULT"      : "Výsledky testu",
    "KNOWLEDGE_TEST_CORRECT_ANSWERS"    : "Správné odpovědi:",
    "KNOWLEDGE_TEST_RESULT_POINTS"      : "Získané body:",
    "KNOWLEDGE_TEST_RESULT_CORRECT"     : "Správně",
    "KNOWLEDGE_TEST_RESULT_INCORRECT"   : "Nesprávně",
    "KNOWLEDGE_TEST_RESULT_NO_ANSWER"   : "Bez odpovědi",
    "KNOWLEDGE_TEST_RESULT_MANUAL"      : "Čeká na ruční vyhodnocení",
    "KNOWLEDGE_TEST_YOUR_ANSWER"        : "Tvoje odpověď",
    "KNOWLEDGE_TEST_CORRECT_ANSWER"     : "Správná odpověď",
    "KNOWLEDGE_TEST_POINTS"             : "+{variable} Bodů",
    "KNOWLEDGE_TEST_EXIT"               : "Ok",
    "KNOWLEDGE_TEST_NEW_ATTEMPT"        : "Nový pokus",
    "KNOWLEDGE_TEST_MANUAL_ITEM"        : "Tento úkol ještě vyhodnocujeme.",
    "KNOWLEDGE_TEST_MANUAL_INFO"        : "Tento test ještě vyhodnocujeme, celkové hodnocení ti zašleme po jeho uzavření.",
    // modal shown when user clicks on "Start test"
    "KNOWLEDGE_TEST_MODAL_TITLE"        : "Chystáš se spustit znalostní test.",
    "KNOWLEDGE_TEST_MODAL_ENTER"        : "Rozumím, vstoupit",
    "KNOWLEDGE_TEST_MODAL_CANCEL"       : "Rozmyslel jsem si to",
    // Modal shown in "Test results page" before repeating the test
    "KNOWLEDGE_TEST_REPEAT_SUCCESS_TITLE" : "Chystáš se opakovat znalostní test, který jsi již úspěšně zvládl",
    "KNOWLEDGE_TEST_REPEAT_SUCCESS_TEXT"  : "Započteme ti skóre lepšího testu. Hodně štěstí!",
    "KNOWLEDGE_TEST_REPEAT_FAIL_TITLE"    : "Chystáš se opakovat znalostní test",
    "KNOWLEDGE_TEST_REPEAT_FAIL_TEXT"     : "Do hry se ti počítá skóre lepšího testu, ale abys test úspěšně absolvoval, musíš mít alespoň 80%. Hodně štěstí!",
    // modal shown when user leaves some questions unanswered and wants to finish the attempt
    "KNOWLEDGE_TEST_MODAL_FINISH_TITLE"   : "Chystáš se uzavřít test, ale neodpověděl jsi na všechny otázky.",
    "KNOWLEDGE_TEST_MODAL_FINISH_TEXT"    : "Nezodpovězené otázky se započítají jako nesprávné.",
    "KNOWLEDGE_TEST_MODAL_FINISH_CONFIRM" : "Vrátit se na test",
    "KNOWLEDGE_TEST_MODAL_FINISH_REJECT"  : "Rozumím, přesto uzavřít",
    // Modal shown when the time runs out
    "KNOWLEDGE_TEST_TIMEOUT_TITLE"       : "Čas vypršel!",
    "KNOWLEDGE_TEST_TIMEOUT_DESC"        : "Pokud máš alespoň 80% otázek zodpovězených správně, test jsi úspěšně absolvoval!",
    "KNOWLEDGE_TEST_TIMEOUT_BTN"         : "Zobrazit výsledky",

    // validation messages
    "VALIDATION_required"               : "Pole {field} je povinné.",
    "VALIDATION_email"                  : "Pole {field} musí být platnou emailovou adresou.",
    "VALIDATION_minLength"              : "Pole {field} musí být dlouhé alespoň {min} znaků.",
    "VALIDATION_answerLength"           : "Tvoje odpověď musí mít přesně {length} znaků",

    // names of form fields for validation purposes
    "VALIDATION_FIELDNAME_email"        : "Email",
    "VALIDATION_FIELDNAME_password"     : "Heslo",
    "VALIDATION_FIELDNAME_answer"       : "Odpověď",
    "VALIDATION_FIELDNAME_name"         : "Jméno",
    "VALIDATION_FIELDNAME_first_name"   : "Rodné jméno",
    "VALIDATION_FIELDNAME_last_name"    : "Příjmění",
    "VALIDATION_FIELDNAME_new_password" : "Nové heslo",
    "VALIDATION_FIELDNAME_old_password" : "Současné heslo",

    // Image alts
    "ALT_IMAGE_CATEGORY"                : "Obrázek zaměření",
    "ALT_IMAGE_BADGE"                   : "Obrázek trofeje",
    "ALT_IMAGE_ITEM"                    : "Ikona úkolu",
    "ALT_DESCRIPTION_IMAGE_ITEM"        : "Obrázek úkolu",
    "ALT_IMAGE_TEST_ITEM"               : "Obrázek otázky",
};

export default translationsCs;
