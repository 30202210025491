var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "category-box knowledge-test-box",
      class: {
        "knowledge-test-warning":
          !_vm.didUserPassTest(_vm.testId) && _vm.isTestClosingSoon(_vm.testId),
      },
    },
    [
      _c("div", { attrs: { "data-key": "sameHeightCategories" } }, [
        _c("div", { staticClass: "bg-img responsive-bg-img" }),
        _vm._v(" "),
        _c("div", { staticClass: "content-wrapper" }, [
          _c("h3", { staticClass: "test-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.knowledgeTests[_vm.testId].title ||
                    _vm.$t("KNOWLEDGE_TEST_FOCUS_TITLE")
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "label label-important test-deadline-label" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_DEADLINE")) +
                  " " +
                  _vm._s(_vm.knowledgeTests[_vm.testId].open_to_formatted) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box-body" },
            [
              _vm.isAttemptForTestActive(_vm.testId)
                ? _c("p", { staticClass: "previous-attempt attempt-running" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("translate")(
                            "KNOWLEDGE_TEST_UNFINISHED_ATTEMPT"
                          )
                        ) +
                        "\n                "
                    ),
                  ])
                : !_vm.isEmpty(_vm.bestTestAttempt(_vm.testId))
                ? [
                    _vm.bestTestAttempt(_vm.testId).result === null
                      ? _c(
                          "p",
                          { staticClass: "previous-attempt attempt-manual" },
                          [
                            _c("span", { staticClass: "label label-default" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "KNOWLEDGE_TEST_PREVIOUS_MANUAL"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass: "previous-attempt",
                            class:
                              _vm.bestTestAttempt(_vm.testId).result === true
                                ? "attempt-success"
                                : "attempt-fail",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "KNOWLEDGE_TEST_YOUR_RESULT"
                                  )
                                ) +
                                "\n                        " +
                                _vm._s(
                                  _vm.bestTestAttempt(_vm.testId).percentage
                                ) +
                                "%\n                        "
                            ),
                            _vm.bestTestAttempt(_vm.testId).result === true
                              ? _c(
                                  "span",
                                  { staticClass: "label label-success" },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "KNOWLEDGE_TEST_PREVIOUS_SUCCESS"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "label label-danger" },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "KNOWLEDGE_TEST_PREVIOUS_FAIL"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                          ]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAttemptForTestActive(_vm.testId)
                ? _c(
                    "p",
                    { staticClass: "test-attempts-count" },
                    [
                      _vm.remainingTestAttempts(_vm.testId) === 0
                        ? [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "KNOWLEDGE_TEST_ATTEMPTS_0"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]
                        : _vm.remainingTestAttempts(_vm.testId) === 1
                        ? [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "KNOWLEDGE_TEST_ATTEMPTS_1"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]
                        : _vm.remainingTestAttempts(_vm.testId) <= 4
                        ? [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t("KNOWLEDGE_TEST_ATTEMPTS_2_4", {
                                    count: _vm.remainingTestAttempts(
                                      _vm.testId
                                    ),
                                  })
                                ) +
                                "\n                    "
                            ),
                          ]
                        : [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t("KNOWLEDGE_TEST_ATTEMPTS_5_MORE", {
                                    count: _vm.remainingTestAttempts(
                                      _vm.testId
                                    ),
                                  })
                                ) +
                                "\n                    "
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "button-wrapper" }, [
        _vm.isAttemptForTestActive(_vm.testId)
          ? _c(
              "button",
              {
                staticClass: "btn secondary-btn",
                on: {
                  click: function ($event) {
                    return _vm.continueAttempt()
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("translate")("KNOWLEDGE_TEST_CONTINUE_ATTEMPT")
                    ) +
                    "\n        "
                ),
              ]
            )
          : _c(
              "button",
              {
                staticClass: "btn secondary-btn",
                attrs: {
                  disabled:
                    _vm.remainingTestAttempts(_vm.testId) === 0 ||
                    _vm.isAnyAttemptActive,
                },
                on: {
                  click: function ($event) {
                    return _vm.showStartModal()
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("translate")("KNOWLEDGE_TEST_START_ATTEMPT")
                    ) +
                    "\n        "
                ),
              ]
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }