var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "test-video" } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-2 attempt-number" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ATTEMPT")) +
            "\n            " +
            _vm._s(_vm.currentAttemptNumber(_vm.currentTest.id)) +
            " / " +
            _vm._s(_vm.maxTestAttempts(_vm.currentTest.id)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-9 col-md-10" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_TIME_REMAINING")) +
            "\n            "
        ),
        _c("span", { staticClass: "remaining-time" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.testTimeRemaining) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "video-title" }, [
      _vm._v(_vm._s(_vm.currentTest.video_name)),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "video-desc" }, [
      _vm._v(_vm._s(_vm.currentTest.video_description)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row video-row" }, [
      _c(
        "div",
        {
          staticClass: "col-xs-12 col-sm-12 col-md-12",
          class: { "video-not-watched": !_vm.videoHasBeenWatched },
        },
        [
          _c("video", {
            ref: "video",
            attrs: {
              id: "video",
              playsinline: "",
              controls: "",
              crossorigin: "",
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "btn-row" }, [
      _c(
        "button",
        {
          staticClass: "btn secondary-btn",
          attrs: { disabled: !_vm.nextButtonEnabled },
          on: { click: _vm.onContinueButton },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_VIDEO_TO_ITEMS")) +
              "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }