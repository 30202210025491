var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "waiting-for-user" } }, [
    _c("div", { staticClass: "bg-img img-responsive" }),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 col-md-offset-3" }, [
          _c("h2", [
            _vm._v(
              _vm._s(_vm.battles.opponent) +
                " " +
                _vm._s(_vm._f("translate")("BATTLES_CHALLENGED_TITLE"))
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    " +
                _vm._s(_vm._f("translate")("BATTLES_CHALLENGED_TEXT")) +
                "\n                    " +
                _vm._s(
                  _vm.currentAreaDetails.battles_waiting_for_user_decline_points
                ) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn primary-btn",
              on: {
                click: function ($event) {
                  return _vm.acceptBattle()
                },
              },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("translate")("BATTLES_ACCEPT")) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn secondary-btn reject-button",
              on: {
                click: function ($event) {
                  return _vm.rejectBattle()
                },
              },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("translate")("BATTLES_REJECT")) +
                  "\n                "
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }