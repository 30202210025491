var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "test-items" } },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-3 col-md-2 attempt-number" },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ATTEMPT")) +
                "\n            " +
                _vm._s(_vm.currentAttemptNumber(_vm.currentTest.id)) +
                " / " +
                _vm._s(_vm.maxTestAttempts(_vm.currentTest.id)) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-9 col-md-10" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_TIME_REMAINING")) +
              "\n            "
          ),
          _c("span", { staticClass: "remaining-time" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.testTimeRemaining) +
                "\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row item-btn-row" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTest.is_video_active,
                  expression: "currentTest.is_video_active",
                },
              ],
              staticClass: "btn secondary-btn",
              on: { click: () => _vm.$emit("go-to-video") },
            },
            [
              _vm._v(
                "\n                < " +
                  _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_VIDEO_TO_VIDEO")) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showSubmitPage,
              expression: "!showSubmitPage",
            },
          ],
        },
        [
          _c("div", { staticClass: "item-desc" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_QUESTION")) +
                " " +
                _vm._s(_vm.currentTestItemIndex + 1) +
                " / " +
                _vm._s(_vm.testItemsCount) +
                "\n\n            "
            ),
            _c("h3", [_vm._v(_vm._s(_vm.currentTestItem.name))]),
            _vm._v(" "),
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.currentTestItem.description) },
            }),
            _vm._v(" "),
            _vm.currentTestItem.image_path
              ? _c("img", {
                  directives: [{ name: "img", rawName: "v-img" }],
                  staticClass: "img img-responsive",
                  attrs: {
                    src: _vm.currentTestItem.image_path,
                    alt: _vm._f("translate")("ALT_IMAGE_TEST_ITEM"),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.currentTestItem.audio_path
            ? _c("test-item-audio", { attrs: { item: _vm.currentTestItem } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            _vm.currentTestItem.type === "choice"
              ? "TestChoiceAnswer"
              : "TestTextAnswer",
            {
              tag: "component",
              attrs: {
                item: _vm.currentTestItem,
                "current-answer": _vm.currentTestItemAnswer,
              },
              on: { answer: _vm.sendAnswer },
            }
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSubmitPage,
              expression: "showSubmitPage",
            },
          ],
        },
        [
          _c("hr"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success center-block",
              on: {
                click: function ($event) {
                  return _vm.beforeFinish()
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_SUBMIT")) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "progress" },
        [
          _c("progressbar", {
            attrs: {
              now: _vm.currentTestProgress,
              type: "success",
              striped: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("test-pagination", {
        attrs: {
          items: _vm.testItems,
          "current-item-index": _vm.currentTestItemIndex,
          "answered-items": Object.keys(_vm.testAnswers),
        },
        on: { "on-item-select": _vm.goToItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }