<template>
    <div id="battle-in-progress" class="container">

        <!-- Header -->
        <h2>{{ 'BATTLES_IN_PROGRESS_TITLE' | translate }}</h2>
        <div class="row" id="header-row">
            <div class="col-sm-4 col-sm-push-4">
                <p>{{ 'BATTLES_IN_PROGRESS_SUBTITLE' | translate }}</p>
            </div>
            <div class="col-sm-4 col-sm-pull-4 name-user">
                <h4>{{ user.name }}</h4>
            </div>
            <div class="col-sm-4 name-opponent">
                <h4>{{ battles.opponent }}</h4>
            </div>
        </div>

        <!-- Battle Progress -->
        <battle-progress></battle-progress>

        <!-- Question Area -->
        <transition name="component-fade" mode="out-in">
            <component :is="allBattleQuestionsAnswered ? 'BattleWaitingForFinish' : 'BattleQuestion'"
            ></component>
        </transition>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    import BattleProgress from "./BattleProgress";
    import BattleQuestion from "./BattleQuestion";
    import BattleWaitingForFinish from "./BattleWaitingForFinish";

    export default {
        components : { BattleProgress, BattleQuestion, BattleWaitingForFinish },

        computed : mapGetters([ "user", "battles", "allBattleQuestionsAnswered" ]),
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>