<template>
    <li class="list-group-item list-item">
        <div class="row list-item-row">
            <div class="col-xs-3 col-sm-4 col-lg-3 img-wrapper">
                <img :src="imgPath" class="img img-responsive" :alt="'ALT_IMAGE_BADGE' | translate">
            </div>
            <div class="col-xs-9 col-sm-8 col-lg-9">
                <div class="row">
                    <div class="col-xs-12 title-wrapper" :class="{ 'col-sm-7' : tag }">
                        <h4>{{ title }}</h4>
                    </div>
                    <div v-if="tag" class="col-xs-12 col-sm-5 tag">
                        {{ tag }}
                    </div>
                </div>
                <p>{{ desc }}</p>
            </div>
        </div>
    </li>
</template>
<!---------------------------------------------------------------------------->
<script>
    export default {
        props : {
            imgPath     : { type : String, default : "" },
            title       : { type : String, default : "" },
            desc        : { type : String, default : "" },
            tag         : { type : String, default : "" }
        },
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
