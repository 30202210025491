var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "login" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container illustration" }, [
        _c("h1", [_vm._v(_vm._s(_vm._f("translate")("LOGIN_MAIN_TITLE")))]),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm._f("translate")("LOGIN_MAIN_SUBTITLE")))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", [
          _vm._v(_vm._s(_vm._f("translate")("LOGIN_SECONDARY_TITLE"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "subheading" }, [
          _vm._v(_vm._s(_vm._f("translate")("LOGIN_SECONDARY_SUBTITLE"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c("div", { staticClass: "thumbnail" }, [
              _c("img", {
                staticClass: "img img-responsive",
                attrs: {
                  src: require("variantImages/login_explanation_first.png"),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "caption" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm._f("translate")("LOGIN_EXPLANATION_FIRST_TITLE"))
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm._f("translate")("LOGIN_EXPLANATION_FIRST_TEXT"))
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c("div", { staticClass: "thumbnail" }, [
              _c("img", {
                staticClass: "img img-responsive",
                attrs: {
                  src: require("variantImages/login_explanation_second.png"),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "caption" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")("LOGIN_EXPLANATION_SECOND_TITLE")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm._f("translate")("LOGIN_EXPLANATION_SECOND_TEXT"))
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c("div", { staticClass: "thumbnail" }, [
              _c("img", {
                staticClass: "img img-responsive",
                attrs: {
                  src: require("variantImages/login_explanation_third.png"),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "caption" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm._f("translate")("LOGIN_EXPLANATION_THIRD_TITLE"))
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm._f("translate")("LOGIN_EXPLANATION_THIRD_TEXT"))
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-3", attrs: { id: "user-auth-area" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row login-part" }, [
          _c("h2", [
            _vm._v(_vm._s(_vm._f("translate")("LOGIN_SECTION_TITLE"))),
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v(_vm._s(_vm._f("translate")("LOGIN_SECTION_SUBTITLE"))),
          ]),
          _vm._v(" "),
          _vm.ssoEnabled
            ? _c("div", { staticClass: "sso-btn-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn brand-primary-btn",
                    on: { click: _vm.ssoLogin },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("translate")("LOGIN")) +
                        "\n                    "
                    ),
                  ]
                ),
              ])
            : _c("div", { staticClass: "col-xs-12 col-md-6 col-md-offset-3" }, [
                _c("div", { staticClass: "panel panel-default" }, [
                  _c("div", { staticClass: "panel-heading" }, [
                    _vm._v(_vm._s(_vm.panelTitle)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "panel-body" },
                    [
                      _c(
                        "keep-alive",
                        [
                          _c(_vm.currentLoginComponent, {
                            tag: "component",
                            on: { created: _vm.changePanelTitle },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.currentLoginComponent === "LoginForm"
                        ? _c(
                            "div",
                            { staticClass: "forgotten-pass-link-wrapper" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLoginComponent(
                                        "ForgottenPassForm"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "FORGOTTEN_PASSWORD"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "panel-footer" }, [
                    _vm.currentLoginComponent === "LoginForm"
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.changeLoginComponent(
                                  "RegistrationForm"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm._f("translate")("REGISTER")) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.currentLoginComponent === "LoginForm")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn brand-secondary-btn",
                            on: {
                              click: function ($event) {
                                return _vm.changeLoginComponent("LoginForm")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm._f("translate")("BACK")) +
                                "\n                            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }