// loader
export const TOGGLE_IS_LOADER_SHOWN = "TOGGLE_IS_LOADER_SHOWN";

// auth
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const UNSET_AUTH_DATA = "UNSET_AUTH_DATA";

// user data
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_CURRENT_AREA = "SET_CURRENT_AREA";

// success and error message (notification, alert)
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";

// error page content - a separate page, NOT the same thing as error message
export const SET_ERROR_PAGE_CONTENT = "SET_ERROR_PAGE_CONTENT";

// login components on Login page (first page)
export const SET_CURRENT_LOGIN_COMPONENT = "SET_CURRENT_LOGIN_COMPONENT";

// data
export const REPLACE_AREAS = "REPLACE_AREAS";
export const REPLACE_CURRENT_ITEM = "REPLACE_CURRENT_ITEM";
export const REPLACE_LAST_ANSWER_DATA = "REPLACE_LAST_ANSWER_DATA";
export const REPLACE_ACHIEVEMENTS = "REPLACE_ACHIEVEMENTS";
export const REPLACE_TEAM_USERS_LEADERBOARD = "REPLACE_TEAM_USERS_LEADERBOARD";
export const REPLACE_AREA_TEAMS_LEADERBOARD = "REPLACE_AREA_TEAMS_LEADERBOARD";
export const REPLACE_ALL_USERS_LEADERBOARD = "REPLACE_ALL_USERS_LEADERBOARD";

// battles
export const REPLACE_BATTLES = "REPLACE_BATTLES";
export const REPLACE_AREA_BATTLES_LEADERBOARD = "REPLACE_AREA_BATTLES_LEADERBOARD";
export const REPLACE_TEAM_BATTLES_LEADERBOARD = "REPLACE_TEAM_BATTLES_LEADERBOARD";

// knowledge tests
export const REPLACE_KNOWLEDGE_TEST_INFO = "REPLACE_KNOWLEDGE_TEST_INFO";
export const REPLACE_TEST_ATTEMPT_IDS = "REPLACE_TEST_ATTEMPT_IDS";
export const REPLACE_TEST_ATTEMPT_INFO = "REPLACE_TEST_ATTEMPT_INFO";
export const REPLACE_TEST_TIME_REMAINING = "REPLACE_TEST_TIME_REMAINING";
export const REPLACE_CURRENT_TEST_ITEM_INDEX = "REPLACE_CURRENT_TEST_ITEM_INDEX";
export const REPLACE_TEST_ANSWER = "REPLACE_TEST_ANSWER";
export const REPLACE_TEST_ANSWERS = "REPLACE_TEST_ANSWERS";
export const REPLACE_TEST_TIME_IS_UP = "REPLACE_TEST_TIME_IS_UP";
export const SET_TEST_VIDEO_WATCHED = "SET_TEST_VIDEO_WATCHED";
