/**
 * Custom validator function created for Vuelidate https://monterail.github.io/vuelidate/#sub-custom-validators
 * Very specific - checks if the length of the answer is precisely "length" characters long
 */
import withParams from "vuelidate/lib/withParams";

export default (length) => withParams(
    { type : "answerLength", length },
    value => !(value === undefined) && !(value === null) && String(value).trim().length === length
);
