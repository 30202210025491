<template>
    <div class="row achievements-row">
        <ul class="list-group achievements-list" v-if="areThereAnyAchievements">
            <div class="col-xs-12 col-sm-6"
                 v-for="achievement in limitedAchievements()" :key="achievement.id"
            >
                <list-item :img-path="achievement.image_path"
                           :title="achievement.name" :desc="achievement.description"
                           :tag="achievement.category_name"
                           data-key="sameHeightAchievements"
                ></list-item>
            </div>
        </ul>
        <p v-else class="empty-achievements">{{ 'NO_ACHIEVEMENTS_YET' | translate }}</p>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import isEmpty from "lodash/isEmpty";
    import sameHeight from "src/services/sameHeight";

    import ListItem from "Components/common/ListItem";

    export default {
        components : { ListItem },

        props : {
            achievements : { type : Array, default : [] },
        },

        mounted : function() {
            setTimeout(this.equalizeHeights, 500);
            setTimeout(this.equalizeHeights, 1200);
            window.addEventListener('resize', this.equalizeHeights);
        },

        destroyed : function() {
            window.removeEventListener('resize', this.equalizeHeights);
        },

        computed : {
            areThereAnyAchievements() { return !isEmpty(this.achievements); }
        },

        methods : {
            limitedAchievements() {
                // @note: achievements list size - maybe it should be configurable?
                return this.achievements.slice(0, 4);
            },

            equalizeHeights() {
                sameHeight.equalize("[data-key='sameHeightAchievements']");
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>