<template>
    <div id="achievements" class="page">
        <div class="part-1">
            <div class="container">
                <h1>{{ 'ACHIEVEMENTS_MAIN_TITLE' | translate }}</h1>
                <h3>{{ currentAreaDetails.achievements_main_subtitle }}</h3>

                <div class="row achievements-title">
                    <div class="col-xs-12">
                        <h2>{{ 'ACHIEVEMENTS_UNLOCKED_TITLE' | translate }}</h2>
                    </div>
                </div>

                <div class="row achievements-row">
                    <ul class="list-group achievements-list" v-if="areThereAnyUnlocked">
                        <div class="col-xs-12 col-sm-6"
                             v-for="achievement in currentUnlockedAchievements" :key="achievement.id"
                        >
                            <list-item :img-path="achievement.image_path"
                                       :title="achievement.name" :desc="achievement.description"
                                       :tag="achievement.category_name"
                                       data-key="unlockedAchievements"
                            ></list-item>
                        </div>
                    </ul>
                    <p v-else class="empty-achievements">{{ 'NO_ACHIEVEMENTS_YET' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="part-2">
            <div class="container">

                <div class="row achievements-title">
                    <div class="col-xs-12">
                        <h2>{{ 'ACHIEVEMENTS_LOCKED_TITLE' | translate }}</h2>
                    </div>
                </div>

                <div class="row achievements-row">
                    <ul class="list-group achievements-list" v-if="areThereAnyLocked">
                        <div class="col-xs-12 col-sm-6"
                             v-for="achievement in currentLockedAchievements" :key="achievement.id"
                        >
                            <list-item :img-path="achievement.image_path"
                                       :title="achievement.name" :desc="achievement.description"
                                       :tag="achievement.category_name"
                                       data-key="lockedAchievements"
                            ></list-item>
                        </div>
                    </ul>
                    <p v-else class="empty-achievements">{{ 'ALL_ACHIEVEMENTS_UNLOCKED' | translate }}</p>
                </div>
            </div>

            <div class="continue-btn-row">
                <a href="/#/item-dashboard" class="btn primary-btn">
                    {{ 'CONTINUE_PLAYING' | translate }}
                </a>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";
    import sameHeight from "src/services/sameHeight";

    import ListItem from "Components/common/ListItem";

    export default {
        components : { ListItem },

        created : function() {
            // @note errors are handled in httpInterceptors
            this.$store.dispatch("downloadAchievements").catch(() => {});
        },

        mounted : function() {
            setTimeout(this.equalizeHeights, 500);
            setTimeout(this.equalizeHeights, 1200);
            window.addEventListener('resize', this.equalizeHeights);
        },

        destroyed : function() {
            window.removeEventListener('resize', this.equalizeHeights);
        },

        computed : mapGetters([
            "currentUnlockedAchievements", "currentLockedAchievements",
            "areThereAnyUnlocked", "areThereAnyLocked", "currentAreaDetails"
        ]),

        methods : {
            equalizeHeights() {
                sameHeight.equalize("[data-key='unlockedAchievements']");
                sameHeight.equalize("[data-key='lockedAchievements']");
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
