var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-answer" }, [
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "has-error": _vm.$v.answer.$error },
      },
      [
        _c("div", { staticClass: "text-answer-wrapper vertical-align" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.answer,
                expression: "answer",
              },
            ],
            staticClass: "form-control",
            class: { answered: _vm.answer.length > 0 },
            attrs: {
              name: "answer",
              id: "answer",
              rows: "3",
              placeholder: _vm._f("translate")("KNOWLEDGE_TEST_SUBMIT_TEXT_PH"),
            },
            domProps: { value: _vm.answer },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.answer = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("validation-messages", {
          attrs: { field: _vm.$v.answer, "field-name": "answer" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-success center-block",
        attrs: { disabled: _vm.answer.length === 0 },
        on: {
          click: function ($event) {
            return _vm.submit()
          },
        },
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ANSWER")) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }