var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "test-result" } },
    [
      _vm.previousTestAttempt(_vm.testId).result === true
        ? _c("h3", { staticClass: "result-title text-correct" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_SUCCESS")) +
                "\n    "
            ),
          ])
        : _vm.previousTestAttempt(_vm.testId).result === false
        ? _c("h3", { staticClass: "result-title text-incorrect" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_FAIL")) +
                "\n    "
            ),
          ])
        : _c("h3", { staticClass: "result-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_MANUAL_RESULT")) +
                "\n    "
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 attempt-number" },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ATTEMPT")) +
                "\n            "
            ),
            _vm._v(
              "\n            " +
                _vm._s(_vm.currentAttemptNumber(_vm.testId) - 1) +
                " / " +
                _vm._s(_vm.maxTestAttempts(_vm.testId)) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6 col-md-4 correct-answers" },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_CORRECT_ANSWERS")) +
                "\n            " +
                _vm._s(_vm.correctAnswerCount) +
                "\n            "
            ),
            !_vm.testHasManualEvaluation
              ? [_vm._v("/ " + _vm._s(_vm.testItemsCount))]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        !_vm.testHasManualEvaluation
          ? _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-5 result-points" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("translate")("KNOWLEDGE_TEST_RESULT_POINTS")
                    ) +
                    "\n            " +
                    _vm._s(_vm.previousTestAttempt(_vm.testId).attempt_points) +
                    " / " +
                    _vm._s(_vm.previousTestAttempt(_vm.testId).test_points) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.previousTestAttempt(_vm.testId).result === true ||
      _vm.remainingTestAttempts(_vm.testId) === 0
        ? _vm._l(_vm.testItems, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "item-result",
                class: _vm.calculateItemClass(item),
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xs-12 col-sm-8" }, [
                    _c("span", { staticClass: "item-number" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("translate")("KNOWLEDGE_TEST_QUESTION")
                          ) +
                          " " +
                          _vm._s(index + 1) +
                          " / " +
                          _vm._s(_vm.testItemsCount) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.createLabel(item)) },
                    }),
                  ]),
                  _vm._v(" "),
                  !_vm.testHasManualEvaluation || item.type !== "any_text"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 text-right item-points",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("KNOWLEDGE_TEST_POINTS", {
                                  variable:
                                    item.is_correct === true
                                      ? item.points
                                      : "0",
                                })
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "item-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                item.type === "text"
                  ? [
                      item.answer
                        ? [
                            _c("p", { staticClass: "user-answer" }, [
                              _c("span", { staticClass: "answer-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "KNOWLEDGE_TEST_YOUR_ANSWER"
                                    )
                                  ) + ":"
                                ),
                              ]),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.answer) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            item.correct_answer && item.is_correct === false
                              ? [
                                  _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "answer-title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("translate")(
                                              "KNOWLEDGE_TEST_CORRECT_ANSWER"
                                            )
                                          ) + ":"
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.correct_answer) +
                                        "\n                        "
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : item.type === "choice"
                  ? [
                      item.answer
                        ? [
                            _c(
                              "div",
                              { staticClass: "answer-title user-answer" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "KNOWLEDGE_TEST_YOUR_ANSWER"
                                    )
                                  ) + ":"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "answer-list" },
                              _vm._l(item.answer, function (answerId) {
                                return _c("li", { key: answerId }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.find(item.options.choices, {
                                          id: answerId,
                                        }).text
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              }),
                              0
                            ),
                            _vm._v(" "),
                            item.correct_answer && item.is_correct === false
                              ? [
                                  _c("div", { staticClass: "answer-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "KNOWLEDGE_TEST_CORRECT_ANSWER"
                                        )
                                      ) + ":"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "answer-list" },
                                    _vm._l(
                                      item.correct_answer,
                                      function (answerId) {
                                        return _c("li", { key: answerId }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.find(item.options.choices, {
                                                  id: answerId,
                                                }).text
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : item.type === "any_text"
                  ? [
                      _c(
                        "p",
                        { staticClass: "user-answer" },
                        [
                          _c("span", { staticClass: "answer-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "KNOWLEDGE_TEST_YOUR_ANSWER"
                                )
                              ) + ":"
                            ),
                          ]),
                          _vm._v(" "),
                          item.answer
                            ? [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.answer) +
                                    "\n                    "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "KNOWLEDGE_TEST_RESULT_NO_ANSWER"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "manual-item-info" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")("KNOWLEDGE_TEST_MANUAL_ITEM")
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.testHasManualEvaluation
        ? _c("p", { staticClass: "manual-info" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_MANUAL_INFO")) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "remaining-attempts" },
        [
          _vm.remainingTestAttempts(_vm.testId) === 0
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ATTEMPTS_0")) +
                    "\n        "
                ),
              ]
            : _vm.remainingTestAttempts(_vm.testId) === 1
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_ATTEMPTS_1")) +
                    "\n        "
                ),
              ]
            : _vm.remainingTestAttempts(_vm.testId) <= 4
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("KNOWLEDGE_TEST_ATTEMPTS_2_4", {
                        count: _vm.remainingTestAttempts(_vm.testId),
                      })
                    ) +
                    "\n        "
                ),
              ]
            : [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("KNOWLEDGE_TEST_ATTEMPTS_5_MORE", {
                        count: _vm.remainingTestAttempts(_vm.testId),
                      })
                    ) +
                    "\n        "
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "btn-row" }, [
        _c(
          "button",
          { staticClass: "btn btn-success", on: { click: _vm.exit } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_EXIT")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn secondary-btn",
            attrs: { disabled: _vm.remainingTestAttempts(_vm.testId) === 0 },
            on: { click: _vm.newAttempt },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("translate")("KNOWLEDGE_TEST_NEW_ATTEMPT")) +
                "\n        "
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }