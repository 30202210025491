var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "login-form",
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.login.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-xs-12 form-group",
            class: { "has-error": _vm.$v.loginData.email.$error },
          },
          [
            _c(
              "label",
              { staticClass: "input-label", attrs: { for: "email" } },
              [_vm._v(_vm._s(_vm._f("translate")("EMAIL")) + ":")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.loginData.email,
                  expression: "loginData.email",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "email", name: "email", id: "email" },
              domProps: { value: _vm.loginData.email },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.loginData, "email", $event.target.value)
                  },
                  function ($event) {
                    return _vm.$v.loginData.email.$touch()
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("validation-messages", {
              attrs: { field: _vm.$v.loginData.email, "field-name": "email" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-xs-12 form-group",
            class: { "has-error": _vm.$v.loginData.password.$error },
          },
          [
            _c(
              "label",
              { staticClass: "input-label", attrs: { for: "password" } },
              [_vm._v(_vm._s(_vm._f("translate")("PASSWORD")) + ":")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.loginData.password,
                  expression: "loginData.password",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "password", name: "password", id: "password" },
              domProps: { value: _vm.loginData.password },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.loginData, "password", $event.target.value)
                  },
                  function ($event) {
                    return _vm.$v.loginData.password.$touch()
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("validation-messages", {
              attrs: {
                field: _vm.$v.loginData.password,
                "field-name": "password",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12 submit-btn-wrapper" }, [
          _c(
            "button",
            { staticClass: "btn brand-primary-btn", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("translate")("LOGIN_BUTTON")) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }