<template>
    <div class="item-view">
        <div v-if="isThereAnyItem">
            <div class="row">

                <div class="col-sm-3">
                    <div v-if="!currentItem || !currentItem.image_path"
                            class="bg-img img-responsive"
                    ></div>
                    <img v-else
                         :src="currentItem.image_path"
                         class="img img-responsive"
                         :alt="'ALT_IMAGE_ITEM' | translate"
                    >
                </div>

                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-xs-12 col-sm-8 col-lg-9">
                            <h3>{{ currentItem.name }}</h3>
                        </div>
                        <div class="col-xs-12 col-sm-4 col-lg-3 onboarding-link-wrapper">
                            <i class="fa fa-question-circle-o"></i>
                            <a href="/#/onboarding" class="">
                                {{ 'TO_ONBOARDING' | translate }}
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-4 points-wrapper">
                            {{ $t("POINTS_GAINED", { points : currentItem.correct_points }) }}
                        </div>
                        <div class="col-xs-12 col-sm-8 item-category-wrapper">
                            <span class="area-title">{{ 'ITEM_CATEGORY' | translate }}:</span> {{ currentItemCategory ? currentItemCategory.name : "" }}
                        </div>
                    </div>

                    <p class="item-description" v-html="currentItem.description"></p>

                    <img :src="currentItem.description_image_path"
                         v-if="currentItem.description_image_path"
                         class="img img-responsive description-img"
                         :alt="'ALT_DESCRIPTION_IMAGE_ITEM' | translate"
                         v-img
                    />

                    <!-- Component for item answer - either a Text input or Choice (radios/checkboxes) -->
                    <component :is="currentItem.type === 'text' ? 'TextAnswer' : 'ChoiceAnswer'"
                               :item="currentItem" @answer="answer" ref="answerComponent"
                    >
                        <answer-button-row slot="buttons" @postpone="postpone"></answer-button-row>
                    </component>


                    <div class="row focus-row">
                        <div class="col-xs-12">
                            <span v-if="isAnyCategoryFocused">
                                <span class="focus-title">{{ 'YOUR_FOCUS_IS' | translate }}:</span> {{ currentFocusedCategory.name }}
                            </span>
                            <span v-else class="focus-title">{{ 'YOU_HAVE_NO_FOCUS' | translate }}</span>
                            <a href="/#/focus"
                               class="focus-link"
                               v-if="!isAnyCategoryFocused || currentCategories.length > 1"
                            >
                                {{ 'TO_FOCUS_CHANGE' | translate }}
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    import { mapGetters } from "vuex";

    import TextAnswer from "./TextAnswer";
    import ChoiceAnswer from "./ChoiceAnswer";
    import AnswerButtonRow from "./AnswerButtonRow";

    import apiDefinition from "src/config/apiDefinition";
    import userMessages from "src/services/userMessages";
    import log from "src/services/logger";
    const logTag = "Item";

    export default {
        components : { TextAnswer, ChoiceAnswer, AnswerButtonRow },

        computed : mapGetters([
            "isThereAnyItem", "currentItem", "currentItemCategory",
            "isAnyCategoryFocused", "currentFocusedCategory", "currentCategories"
        ]),

        methods : {
            answer(answer) {
                this.$store.dispatch("answerItem", { itemId : this.currentItem.id, answer })
                .then((result) => {
                    this.$refs.answerComponent.reset();
                    this.$emit("next", { action : "answer", correct : result.data.correct });
                })
                .catch((reason) => {
                    log.log(logTag, "Answer error, reason follows: ");
                    log.log(logTag, reason);

                    // if this is 404, we just redraw automatically. This is trying to solve the bug with
                    // multiple postpones/answers to the same ID
                    // inform the parent about the error and let it decide what next - e.g. redraw?
                    if (reason && reason.status === apiDefinition.codes.notFound) {
                        this.$refs.answerComponent.reset();
                        this.$emit("next", { action : "error" });
                    } else {
                        // @note we do not reset the answers in this case
                        this.$store.dispatch("showErrorMessage", userMessages.afterAnswer(reason));
                    }
                });
            },
            postpone() {
                log.log(logTag, "Postpone!");

                // reset the answer component so that no answer is pre-selected on next item
                this.$refs.answerComponent.reset();

                this.$store.dispatch("postponeItem", this.currentItem.id)
                .then(() => { this.$emit("next", { action : "postpone" }); })
                .catch((reason) => {
                    log.log(logTag, "Postpone error, reason follows: ");
                    log.log(logTag, reason);

                    // if this is 404, we just redraw automatically. This is trying to solve the bug with
                    // multiple postpones/answers to the same ID
                    // inform the parent about the error and let it decide what next - e.g. redraw?
                    if (reason && reason.status === apiDefinition.codes.notFound) {
                        this.$emit("next", { action : "error" });
                    } else {
                        this.$store.dispatch("showErrorMessage", userMessages.afterAnswer(reason));
                    }
                });
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>
