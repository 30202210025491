var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "area-chooser" } },
    [
      _c("div", { staticClass: "part-1" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", [
            _vm._v(_vm._s(_vm._f("translate")("AREA_CHOOSER_MAIN_TITLE"))),
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v(_vm._s(_vm._f("translate")("AREA_CHOOSER_MAIN_SUBTITLE_1"))),
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v(_vm._s(_vm._f("translate")("AREA_CHOOSER_MAIN_SUBTITLE_2"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.allAreasProgressSorted, function (areaProgress) {
        return _c(
          "div",
          {
            key: areaProgress.id,
            staticClass: "area-specific-part",
            class: "part-" + _vm.area(areaProgress.id).css_class_name,
          },
          [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("div", { staticClass: "row area-description-row" }, [
                  _c("div", { staticClass: "bg-img img-responsive" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-5 col-sm-offset-7 area-description-col",
                    },
                    [
                      _c("h2", [
                        _vm._v(_vm._s(_vm.area(areaProgress.id).name)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.area(areaProgress.id).description)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn primary-btn",
                          attrs: {
                            disabled: areaProgress.id == _vm.currentAreaId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goToArea(areaProgress.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("translate")("AREA_CHOOSER_CONTINUE")
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      areaProgress.id == _vm.currentAreaId
                        ? _c("div", { staticClass: "area-finished-text" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "AREA_CHOOSER_CURRENT_AREA"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("user-progress", {
                  attrs: {
                    stats: areaProgress,
                    showProgressbar: true,
                    progressbarValue: _vm.areaLevelProgress(areaProgress.id),
                    showLeaderboardLink:
                      areaProgress.id == _vm.currentAreaId ? true : false,
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }