var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page", attrs: { id: "onboarding" } }, [
    _c("div", { staticClass: "part-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row main-title-row" }, [
          _c("div", { staticClass: "bg-img img-responsive" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-5 col-sm-offset-7 main-title-col" },
            [
              _c("h1", [
                _vm._v(_vm._s(_vm.currentAreaDetails.onboarding_main_title)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "main-subtitle" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.currentAreaDetails.onboarding_main_subtitle) +
                    "\n                    "
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6 col-lg-7" }, [
            _c("div", { staticClass: "flavour-text-wrapper" }, [
              _c("p", { staticClass: "flavour-text" }, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.currentAreaDetails.onboarding_flavour_text) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "flavour-text-tagline" }, [
                _vm._v(
                  "\n                            " +
                    _vm._s(
                      _vm.currentAreaDetails.onboarding_flavour_text_tagline
                    ) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bg-img img-responsive" }),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-3" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm.currentAreaDetails.onboarding_instructions_title)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row instruction first-instruction" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6 instruction-col" }, [
            _c("div", { staticClass: "instruction-wrapper" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-3 col-md-2 number" }, [
                  _vm._v("1"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-9 col-md-10 instruction-text" },
                  [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          _vm.currentAreaDetails
                            .onboarding_instructions_first_title
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.currentAreaDetails
                            .onboarding_instructions_first_text
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row instruction second-instruction" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6 instruction-col" }, [
            _c("div", { staticClass: "instruction-wrapper" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-3 col-md-2 number" }, [
                  _vm._v("2"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-9 col-md-10 instruction-text" },
                  [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          _vm.currentAreaDetails
                            .onboarding_instructions_second_title
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.currentAreaDetails
                            .onboarding_instructions_second_text
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row instruction third-instruction" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6 instruction-col" }, [
            _c("div", { staticClass: "instruction-wrapper" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-3 col-md-2 number" }, [
                  _vm._v("3"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-9 col-md-10 instruction-text" },
                  [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          _vm.currentAreaDetails
                            .onboarding_instructions_third_title
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.currentAreaDetails
                            .onboarding_instructions_third_text
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-4" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", [
          _vm._v(
            _vm._s(_vm.currentAreaDetails.onboarding_explanation_section_title)
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3",
            },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.currentAreaDetails.onboarding_explanation_first_title
                  )
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.currentAreaDetails.onboarding_explanation_first_text
                  )
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.currentAreaDetails.onboarding_explanation_second_title
                  )
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.currentAreaDetails.onboarding_explanation_second_text
                  )
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "part-5" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "cta-img bg-img img-responsive" }, [
          _c("div", { staticClass: "cta-btn-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "btn primary-btn cta-btn",
                attrs: { href: "/#/focus" },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.currentAreaDetails.onboarding_ctoa_button_title
                    ) +
                    "\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 col-lg-5 flavour-img-wrapper" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 image-col" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 image-col" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 image-col" }, [
      _c("div", { staticClass: "bg-img img-responsive" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }