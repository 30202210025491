<template>
    <div id="battle-question">
        <div class="panel panel-default item-view-wrapper">
            <div class="panel-body">
                <h4>
                    {{ $t("BATTLES_PROGRESS_QUESTION_NO", { variable : (currentBattleQuestionIndex + 1) }) }}
                </h4>

                <h3>{{ currentBattleQuestion.name }}</h3>

                <p v-html="currentBattleQuestion.description"></p>

                <!-- Component for item answer - either a Text input or Choice (radios/checkboxes) -->
                <div class="row">
                    <div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                        <component :is="currentBattleQuestion.type === 'text' ? 'TextAnswer' : 'ChoiceAnswer'"
                                   :item="currentBattleQuestion" @answer="answer" ref="answerComponent"
                        >
                            <button slot="buttons" type="submit" class="btn primary-btn answer-btn">
                                {{ 'BATTLES_QUESTION_SEND_ANSWER' | translate }}
                            </button>
                        </component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!---------------------------------------------------------------------------->
<script>
    // @todo: text under questions

    import { mapGetters } from "vuex";

    import TextAnswer from "Components/ItemDashboard/Item/TextAnswer";
    import ChoiceAnswer from "Components/ItemDashboard/Item/ChoiceAnswer";
    import userMessages from "src/services/userMessages";
    import log from 'src/services/logger';
    const logTag = "BattleQuestion";

    export default {
        components : { TextAnswer, ChoiceAnswer },

        created : function() {},

        data() {
            return {};
        },

        computed : mapGetters([ "battles", "currentBattleQuestion", "currentBattleQuestionIndex" ]),

        methods : {
            answer(answer) {
                this.$store.dispatch(
                    "sendBattleQuestionAnswer", { itemId : this.currentBattleQuestion.id, answer }
                )
                .then(() => {
                    if (this.$refs.answerComponent) {
                        this.$refs.answerComponent.reset();
                    }
                })
                .catch(reason => {
                    log.log(logTag, "Answer error, reason follows: ");
                    log.log(logTag, reason);

                    // @note we do not reset the answers in this case
                    this.$store.dispatch("showErrorMessage", userMessages.afterBattleQuestionAnswer(reason));
                });
            }
        }
    };
</script>
<!---------------------------------------------------------------------------->
<style lang="scss" type="text/scss">
</style>