var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.register.apply(null, arguments)
        },
      },
    },
    [
      _vm.isSuccessMessageShown
        ? _c(
            "div",
            { staticClass: "alert alert-success", attrs: { role: "alert" } },
            [_vm._v(_vm._s(_vm.successMessage))]
          )
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 form-group",
                  class: { "has-error": _vm.$v.user.email.$error },
                },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "email" } },
                    [_vm._v(_vm._s(_vm._f("translate")("EMAIL")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email", name: "email", id: "email" },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.user, "email", $event.target.value)
                        },
                        function ($event) {
                          return _vm.$v.user.email.$touch()
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-messages", {
                    attrs: { field: _vm.$v.user.email, "field-name": "email" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 form-group",
                  class: { "has-error": _vm.$v.user.password.$error },
                },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "password" } },
                    [_vm._v(_vm._s(_vm._f("translate")("PASSWORD")))]
                  ),
                  _vm._v(" "),
                  _c("vue-password", {
                    attrs: {
                      name: "password",
                      id: "password",
                      classes: "form-control",
                      disableStrength: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.user.password.$touch()
                      },
                    },
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-messages", {
                    attrs: {
                      field: _vm.$v.user.password,
                      "field-name": "password",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-12 submit-btn-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn brand-primary-btn",
                    attrs: { type: "submit" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("translate")("REGISTER_BUTTON")) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }